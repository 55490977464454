import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Upload, Checkbox, Button, notification, Spin, Radio } from 'antd';
import '../../assets/css/convocatoria.css';

import banner from '../../assets/images/evento/banner_premiossume.jpg';

import banner_movil from '../../assets/images/evento/banner_premiossume_movil.jpg';
import anexo1 from '../../assets/documentos/ANEXO_1_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN_2024.docx';
import { Link } from 'react-router-dom';
import Firebase from '../../utils/firebaseConfig';
import { guardar_votos } from '../../utils/ConexionServidor';
import Item from 'antd/lib/list/Item';
import Vivienda from './Vivienda';
import Comerciales from './Comerciales';
import Industrial from './Industrial';
import Transito from './Transito';
import Mixtos from './Mixtos';

import logo1 from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_EdificiosComerciales_blanco.png';
import logo2 from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_EdificiosIndustriales_blanco.png';
import logo3 from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_Transito_blanco.png';
import logo4 from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_UsosMixtos_blanco.png';
import logo5 from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_Vivienda_blanco.png';
import logoprincipal from '../../assets/images/evento/votar/Sume_LogoPrincipal.png';


import logo1_c from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_EdificiosComerciales.png';
import logo2_c from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_EdificiosIndustriales.png';
import logo3_c from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_Transito.png';
import logo4_c from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_UsosMixtos.png';
import logo5_c from '../../assets/images/evento/votar/logosCategorías/Sume_Premio_Vivienda.png';


import frase from '../../assets/images/evento/votar/Sume_EligeGanadores.png';

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const Categorias = ({ history, onResponse, onResponseCerrarLogin, idioma }) => {

    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const [datos_registro, setDatosRegistro] = useState([]);
    const [categoria, setCategoria] = useState(0);
    const [value1, setValue1] = useState(1);
    const [value2, setValue2] = useState(1);
    const [value3, setValue3] = useState(1);
    const datos = JSON.parse(localStorage.getItem('usuario'));
    
      
      console.log(datos.correo);
    const ref_form = React.createRef();

    const onFinish = async values => {

        localStorage.setItem('voto1', JSON.stringify(values));

        console.log(values);
        values.tipo = categoria;
        values.correo = datos.correo;

        setCargarPantalla(true);

        guardar_votos(values).then(({ data, status }) => {
            switch (status) {
                case 0:
                    mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu voto ha sido enviado' : 'Tu voto ha sido enviado', + data[3]);
                    setDatosRegistro(data);
                    break;
                case 1:
                    mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 2:
                    mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
                    break;
                default:
                    mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
                    break;
            }
            setCargarPantalla(false);
        });
        setCategoria(categoria+1);

    };


    return (
        <>
            <Row className='contenedor_banner_votos' justify={'center'}>
                <Col xs={8} md={4}>
                    <img src={categoria === 1 ? logo1 :(categoria === 2 ? logo2 : (categoria === 3 ? logo3 : (categoria === 4 ? logo4 : (categoria === 5 ? logo5 : logoprincipal)))) } style={{ width: '100%' }} alt="logo" />
                </Col>
                {categoria === 0 &&
                <>
                <Col xs={22}>
                    <img src={frase} style={{width:'400px'}} />
                    <br /><br /><br /><br />
                    </Col>
                </>}
            </Row>
            {
                categoria === 0 ?
                <>
                <Row justify={'center'}>
                    
                    <Col xs={12}><br />
                        <p className='instrucciones_votacion'>
                        Conoce los proyectos que se han distinguido por sus aportaciones a la edificación sustentable en México y Latinoamerica.
                        <br /><br />
                        Emite tu voto en las siguientes categorías:
                        </p><br />
                    </Col>
                    <Col span={20}>
                        <Row justify={'space-around'}>
                            <Col xs={8} md={3}>
                                <img src={logo1_c} style={{width:'100%'}}  />
                            </Col>
                            <Col xs={8} md={3}>
                                <img src={logo2_c} style={{width:'100%'}}  />
                            </Col>
                            <Col xs={8} md={3}>
                                <img src={logo3_c} style={{width:'100%'}}  />
                            </Col>
                            <Col xs={8} md={3}>
                                <img src={logo4_c} style={{width:'100%'}}  />
                            </Col>
                            <Col xs={8} md={3}>
                                <img src={logo5_c} style={{width:'100%'}}  />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <br />
                        <p className='instrucciones_votacion'>
                        A continuación, selecciona un proyecto por categoría, emite tu voto y continúa con la siguiente. ¡Da clic en comenzar cuando estés listo!
                        </p>
                        <p style={{ textAlign: 'center', display: 'non' }}>
                                        <span style={{cursor: 'pointer' }} className='btn_registrate_home back_verde' onClick={()=>setCategoria(1)}>
                                            <strong>COMENZAR</strong>
                                        </span>
                                    </p>
                                    <br /><br />
                    </Col>
                </Row>
                </>
                :
                (
                    categoria === 1 ?
                        <Comerciales categoria={categoria} setCategoria={setCategoria}  />
                            :(
                                categoria === 2 ?
                                    <><Industrial categoria={categoria} setCategoria={setCategoria}  /></>
                                    :
                                    (
                                        categoria === 3 ?
                                        <><Transito categoria={categoria} setCategoria={setCategoria}  /></>
                                        :
                                        (
                                            categoria === 4 ?
                                            <><Mixtos categoria={categoria} setCategoria={setCategoria}  /></>
                                            :
                                            (
                                                categoria === 5 ?
                                                <><Vivienda categoria={categoria} setCategoria={setCategoria}  /></>
                                                :
                                                <Row justify={'center'}>
                                                    <Col span={16}><br /><br />
                                                        <p className='titulo_objeticos color_azul fuente' style={{textAlign:'center', fontSize:'40px'}}>¡Gracias por tu participación!</p>
                                                        <p className='instrucciones_votacion'>
                                                        Los proyectos ganadores, serán reconocidos en el cóctel de premiación que se llevará a cabo en la Torre Mayor el miércoles 28 de febrero de 2024.
                                                        </p><br /><br />
                                                    </Col>
                                                </Row>
                                    )
                                )
                            )
                        )
                )
            }

        </>
    )
}
export default Categorias;
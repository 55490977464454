import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Upload, Checkbox, Button, notification, Spin, Radio, Image } from 'antd';
import '../../assets/css/proyectos.css';

import { Link } from 'react-router-dom';
import Firebase from '../../utils/firebaseConfig';
import { guardar_votos } from '../../utils/ConexionServidor';
import Item from 'antd/lib/list/Item';

import proyecto_1_pdf from '../../assets/proyectos/06 Autoliv MXAG Facility/Autoliv MXAG Facility - anexo 1 descripción del proyecto-Premios Impulso SUME.pdf';
import proyecto_1_img1 from '../../assets/proyectos/06 Autoliv MXAG Facility/1.jpg';
import proyecto_1_img2 from '../../assets/proyectos/06 Autoliv MXAG Facility/2.jpg';
import proyecto_1_img3 from '../../assets/proyectos/06 Autoliv MXAG Facility/3.jpg';
import proyecto_1_img4 from '../../assets/proyectos/06 Autoliv MXAG Facility/4.jpg';
import proyecto_1_img5 from '../../assets/proyectos/06 Autoliv MXAG Facility/5.jpg';

import proyecto_2_pdf from '../../assets/proyectos/07 FMQ MTY042/PREMIOS IMPULSO SUME BY SAINT GOBAIN 2024 - MTY042 - AGREGAR FOTO HUMEDAL.pdf';
import proyecto_2_img1 from '../../assets/proyectos/07 FMQ MTY042/1.jpg';
import proyecto_2_img2 from '../../assets/proyectos/07 FMQ MTY042/2.jpg';
import proyecto_2_img3 from '../../assets/proyectos/07 FMQ MTY042/3.jpg';
import proyecto_2_img4 from '../../assets/proyectos/07 FMQ MTY042/4.jpg';
import proyecto_2_img5 from '../../assets/proyectos/07 FMQ MTY042/5.jpg';

import proyecto_3_pdf from '../../assets/proyectos/09 PROXIMITY PARKS COYOACAN/PPCO_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN.pdf';
import proyecto_3_img1 from '../../assets/proyectos/09 PROXIMITY PARKS COYOACAN/1.jpg';
import proyecto_3_img2 from '../../assets/proyectos/09 PROXIMITY PARKS COYOACAN/2.jpg';
import proyecto_3_img3 from '../../assets/proyectos/09 PROXIMITY PARKS COYOACAN/3.jpg';
import proyecto_3_img4 from '../../assets/proyectos/09 PROXIMITY PARKS COYOACAN/4.jpg';

import proyecto_4_pdf from '../../assets/proyectos/10 PROXIMITY PARKS MONTERREY CENTRO/PPMC_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN (1).pdf';
import proyecto_4_img1 from '../../assets/proyectos/10 PROXIMITY PARKS MONTERREY CENTRO/1.jpg';
import proyecto_4_img2 from '../../assets/proyectos/10 PROXIMITY PARKS MONTERREY CENTRO/2.jpg';
import proyecto_4_img3 from '../../assets/proyectos/10 PROXIMITY PARKS MONTERREY CENTRO/3.jpg';
import proyecto_4_img4 from '../../assets/proyectos/10 PROXIMITY PARKS MONTERREY CENTRO/4.jpg';
import proyecto_4_img5 from '../../assets/proyectos/10 PROXIMITY PARKS MONTERREY CENTRO/5.jpg';

import proyecto_5_pdf from '../../assets/proyectos/11 PROXIMITY PARKS IZTAPALAPA NORTE/PPIN_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN (1).pdf';
import proyecto_5_img1 from '../../assets/proyectos/11 PROXIMITY PARKS IZTAPALAPA NORTE/1.jpg';
import proyecto_5_img2 from '../../assets/proyectos/11 PROXIMITY PARKS IZTAPALAPA NORTE/2.jpg';
import proyecto_5_img3 from '../../assets/proyectos/11 PROXIMITY PARKS IZTAPALAPA NORTE/3.jpg';
import proyecto_5_img4 from '../../assets/proyectos/11 PROXIMITY PARKS IZTAPALAPA NORTE/4.jpg';
import proyecto_5_img5 from '../../assets/proyectos/11 PROXIMITY PARKS IZTAPALAPA NORTE/5.jpg';

import proyecto_6_pdf from '../../assets/proyectos/19 Safran/19SAFRAN.pdf';
import proyecto_6_img1 from '../../assets/proyectos/19 Safran/1.jpg';
import proyecto_6_img2 from '../../assets/proyectos/19 Safran/2.jpg';

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const Industrial = ({ history, onResponse, onResponseCerrarLogin, idioma, categoria, setCategoria }) => {

    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const [datos_registro, setDatosRegistro] = useState([]);
    const [value1, setValue1] = useState(1);
    const [valor,setValor] = useState(0);
    const datos = JSON.parse(localStorage.getItem('usuario'));
    const onChange1 = (e) => {
      console.log('radio checked', e.target.value);
      setValue1(e.target.value);
    };
      console.log(datos.correo);
    const ref_form = React.createRef();

    const onFinish = async values => {

        localStorage.setItem('voto2', JSON.stringify(valor));

        console.log(values);
        values.tipo = categoria;
        values.correo = datos.correo;
        values.categoria2 = valor;

        setCargarPantalla(true);

        guardar_votos(values).then(({ data, status }) => {
            switch (status) {
                case 0:
                    mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu voto ha sido enviado' : 'Tu voto ha sido enviado', + data[3]);
                    setDatosRegistro(data);
                    break;
                case 1:
                    mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 2:
                    mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
                    break;
                default:
                    mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
                    break;
            }
            setCargarPantalla(false);
        });
        setCategoria(categoria+1);

    };


    const proyectos = [
        {id:1, nombre:'Autoliv MXAG Facility', desc:[<>
        Autoliv MXAG Facility es un edificio industrial cuya actividad principal es la manufactura y producción de partes automotrices. El proyecto se encuentra ubicado en el Parque Industrial FINSA Aguascalientes en un sitio de 56,000 m2 de terreno y 30,000 m2 de construcción, con una ubicación estratégica ya que son proveedores del sector automotriz de la zona.<br /><br />
Este proyecto forma parte del portafolio de edificios de Autoliv, empresa global líder en sistemas de seguridad para automóviles. Su propósito fundamental es salvar vidas y prevenir heridas graves; extendiendo este propósito a las comunidades al implementar la sustentabilidad en la sociedad, la naturaleza y la industria. Parte de sus acciones sostenibles comprenden la búsqueda de la neutralidad en carbono para el 2030, asegurar la sustentabilidad en su cadena de suministro; así como su compromiso con el cumplimiento con los Objetivos de Desarrollo Sostenible de la UN.<br /><br />
Gracias al compromiso global de la empresa, el diseño y construcción de Autoliv MXAG Facility consideró estrategias de sostenibilidad, obteniendo la certificación LEED BD+C: New Construction en nivel Oro.</>],
            documento:proyecto_1_pdf,
        imagenes:[
            {indes:1, url:proyecto_1_img1,tipo:1},
            {indes:2, url:proyecto_1_img2,tipo:2},
            {indes:3, url:proyecto_1_img3,tipo:2},
            {indes:4, url:proyecto_1_img4,tipo:2},
            {indes:5, url:proyecto_1_img5,tipo:2},
        ],
        certificacion:'LEED v4 BD+C: New Construction v4, Gold'
    },
        {id:2, nombre:'FIBRAMQ MTY042 (Monterrey 42)', desc:[<>Ubicado en Apodaca, Nuevo León, MTY042 es un edificio industrial de clase A de 183 000 sqf siendo muestra del compromiso de FIBRA Macquarie de desarrollar parques industriales bajo los mejores estándares en los principales mercados de México que contribuyan con retornos atractivos. El edificio estará ocupado en su totalidad por un fabricante de componentes para vehículos eléctricos con sede en Estados Unidos, abriendo oportunidades a las personas de la región, demostrando así el compromiso con la expansión de operaciones en la zona.<br /><br />
            Cuenta con un alto desempeño en todas las categorías de LEED, por lo que logró ser la<br /><br />
            primera propiedad certificada LEED v4 BD+D: CS para Naves industriales y Distribución que obtuvo Nivel Platino en México y Norte América, y la de puntaje más alto en LEED en esta categoría, a mayo de 2023, con 90 puntos.<br /><br />
            La certificación LEED Platinum, obtenida el 18 de mayo de 2023, es solo el reflejo de la dedicación que ha puesto FIBRA Macquarie para tener los más altos estándares y así ofrecer a su base de inquilinos las mejores instalaciones para desarrollar sus actividades.</>],
            documento:proyecto_2_pdf,
        imagenes:[
            {indes:1, url:proyecto_2_img1,tipo:1},
            {indes:2, url:proyecto_2_img2,tipo:2},
            {indes:3, url:proyecto_2_img3,tipo:2},
            {indes:4, url:proyecto_2_img4,tipo:2},
            {indes:5, url:proyecto_2_img5,tipo:2},
        ],
        certificacion:'LEED v4 BUILDING DESIGN AND CONSTRUCTION: CORE AND SHELL, PLATINUM 90 puntos'
    },
        {id:3, nombre:'ProximityParks Coyoacán', desc:[<>ProximityParks Coyoacán es un centro logístico de última milla de 6,886 m2 de terreno y 5,010 m2 de bodega, ubicado a un costado del área centro de Coyoacán, en la esquina de la avenida Universidad y Río Churubusco.<br /><br />
            El proyecto es la primera reconversión de un espacio de retail a un espacio industrial en México; pasó de ser una agencia y taller de autos en desuso, que desaprovechaba su ubicación privilegiada, a un renovado centro logístico sustentable. Es el único centro logístico de este tipo en el área de Coyoacán, con el cual se buscó demostrar liderazgo y responsabilidad en el uso de los recursos, aprovechando la estructura y muros pre- existentes. El proyecto se compone de un edificio trasero con área de bodega con oficina. El diseño responde con versatilidad a los requerimientos de una nueva generación de empresas dedicadas al comercio electrónico, combinando además una serie de estrategias energéticas y de sustentabilidad.<br /><br />
            ProximityParks Coyoacán es el tercer proyecto de remodelación certificado de ProximityParks y obtuvo la certificación LEED BD+C C&S en nivel oro con 66 puntos.</>],
            documento:proyecto_3_pdf,
        imagenes:[
            {indes:1, url:proyecto_3_img1,tipo:1},
            {indes:2, url:proyecto_3_img2,tipo:2},
            {indes:3, url:proyecto_3_img3,tipo:2},
            {indes:4, url:proyecto_3_img4,tipo:2},
        ],
        certificacion:'LEED v4 BD+C: Core & Shell'
    },
        {id:4, nombre:'ProximityParks Monterrey Centro', desc:[<>ProximityParks Monterrey Centro es un parque industrial para logística de última milla de 40,000 m2 de terreno y 21,700 m2 de construcción, ubicado en el centro de Monterrey, en la esquina de la avenida Los Ángeles y Av. Diego Diaz de Berlanga.<br /><br />
            El proyecto nació de la rehabilitación de lo que antes era un edificio abandonado de uso industrial, buscando demostrar liderazgo y responsabilidad en el uso de los recursos, aprovechando la estructura, techos y firmes existentes para convertirlo en un centro logístico moderno. El proyecto se compone de un edificio principal de bodega, áreas de oficina, tres casetas de vigilancia y distintas áreas interiores y exteriores para todos los usuarios. El diseño responde con versatilidad a los requerimientos de una nueva generación de empresas dedicadas al comercio electrónico, combinando además una serie de estrategias energéticas y de sustentabilidad.<br /><br />
            ProximityParks Monterrey Centro es el proyecto pionero de ProximityParks y un ejemplo a nivel nacional de cómo llevar a cabo una remodelación sustentable. El proyecto obtuvo la certificación LEED BD+C C&S en nivel oro con 65 puntos.</>],
            documento:proyecto_4_pdf,
        imagenes:[
            {indes:1, url:proyecto_4_img1,tipo:1},
            {indes:2, url:proyecto_4_img2,tipo:2},
            {indes:3, url:proyecto_4_img3,tipo:2},
            {indes:4, url:proyecto_4_img4,tipo:2},
            {indes:5, url:proyecto_4_img5,tipo:2},
        ],
        certificacion:'LEED v4 BD+C: Core & Shell'
    },
        {id:5, nombre:'ProximityParks Iztapalapa Norte', desc:[<>ProximityParks Iztapalapa Norte es un parque industrial, específicamente diseñado para logística de última milla, de 10,432.72 m2 de terreno y 8,053.42 m2 de construcción. Se ubicada en la zona norte de Iztapalapa a pocas cuadras de Rio Churubusco.<br /><br />
            El proyecto nació de la reconversión de lo que antes era una fábrica abandonada, buscando demostrar los beneficios de reutilizar los materiales existentes, aprovechando la estructura, muros y firmes para convertirlo en un centro logístico moderno. El inmueble se convirtió en un centro para logística de última milla de alto volumen y sostenible, compuesto de un edificio principal de bodega, dos pisos de áreas de oficinas, dos casetas de vigilancia, distintas áreas interiores y exteriores para todos los usuarios.<br /><br />
            El desarrollo de ProximityParks Iztapalapa Norte tomó 8 meses en total y es el segundo proyecto de rehabilitación de ProximityParks, obtuvo la certificación LEED BD+C C&S en nivel oro con 63 puntos.</>],
            documento:proyecto_5_pdf,
        imagenes:[
            {indes:1, url:proyecto_5_img1,tipo:1},
            {indes:2, url:proyecto_5_img2,tipo:2},
            {indes:3, url:proyecto_5_img3,tipo:2},
            {indes:4, url:proyecto_5_img4,tipo:2},
            {indes:5, url:proyecto_5_img5,tipo:2},
        ],
        certificacion:'LEED v4 BD+C: Core & Shell'
    },
        {id:6, nombre:'SAFRAN', desc:[<>Las plantas SAFRAN I a SAFRAN V, ubicadas en el parque industrial SUPRA, en Chihuahua, Chihuahua, México, representan un conglomerado industrial dedicado principalmente a la fabricación de componentes aeronáuticos. Estas instalaciones suman un total de 64,209.47 m2 y están especializadas en diversas áreas del sector aeronáutico, desde sistemas de evacuación y asientos para aviones hasta la fabricación de "seat shells" para la clase ejecutiva, tableros eléctricos, cajas APU, arneses, tanques de agua y desecho, así como sanitarios de vacío para aeronaves.<br /><br />
        La SAFRAN I, con 18,539.36 m2, y ubicada en Ave. Ishikawa No. 1200, se certificó el 13 de noviembre de 2023. SAFRAN II, con 17,381.88 m2, y SAFRAN III, con 12,601.09 m2, comparten la misma fecha de certificación y se encuentran respectivamente en las direcciones Ave. Ishikawa No. 1001 y No. 1000. SAFRAN IV, de 8,581.36 m2, se localiza en Calle Taguchi No. 18702 y obtuvo su certificación el 20 de noviembre de 2023. Finalmente, SAFRAN V, con 7,105.78 m2 y situada en Avenida Ishikawa No. 1201, también se certificó el 13 de noviembre de 2023.<br /><br />
        La búsqueda de certificación para estos edificios industriales se inscribe en un marco más amplio de compromiso ambiental por parte de Terrafina, cuyo objetivo hacia el 2030 es la reducción significativa de sus emisiones de carbono. Este esfuerzo forma parte de la estrategia de Fibra Terrafina de potenciar la sostenibilidad en su cartera de propiedades, seleccionando para ello a inquilinos que comparten esta visión y están interesados en contribuir a dicho objetivo.<br /><br />
        Esta iniciativa es un claro ejemplo de la sinergia que puede surgir entre los inquilinos y los propietarios de las instalaciones, demostrando cómo la colaboración y el compromiso mutuo hacia objetivos ambientales pueden generar resultados positivos y sostenibles. Representa un modelo a seguir en la industria, mostrando el valor de involucrar activamente a todas las partes interesadas en la gestión ambiental y en la implementación de prácticas sostenibles.</>],
            documento:proyecto_6_pdf,
        imagenes:[
            {indes:1, url:proyecto_6_img1,tipo:1},
            {indes:2, url:proyecto_6_img2,tipo:2},
        ],
        certificacion:'LEED O+M, SAFRAN I y V: Oro y SAFRAN II, III, IV: Plata'
    }
    ];

    const ver_proyectos = proyectos.map((item) => {
        return(
            <>
            <Row className='contenedor_pryecto'>
                <Col  xs={22} lg={8}>
                <Row justify={'center'}>
                        {item.imagenes != null ?
                                <>
                                    {item.imagenes.map(image => {
                                        if (image.indes === 1) {
                                            return (
                                                <>
                                                    <Col span={24}>
                                                        <Image
                                                            src={image.url}
                                                            className='img_grande'
                                                            placeholder={
                                                                <Image
                                                                    preview={false}
                                                                    src={image.url}
                                                                    style={{ width: '80%' }}
                                                                />}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <>
                                                    <Col span={5}>
                                                        <br />
                                                        <Image
                                                            className='img_peque'
                                                            src={image.url}
                                                            placeholder={
                                                                <Image
                                                                    preview={false}
                                                                    src={image.url}
                                                                    style={{ width: '80%' }}
                                                                />}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        }
                                    })}</>
                                :
                                <></>}
                        </Row>
                </Col>
                <Col  xs={22} lg={16}>
                    <p className='titulo_proyecto'>{item.nombre}</p>
                    <p className='desc_proyecto'><strong>Certificación: </strong>{item.categoria}</p>
                    <p className='desc_proyecto'>{item.desc}</p>
                    <Row>
                    <Col xs={0} lg={0}><br />
                            <p>
                            <Link className='btn_proyecto' to={item.documento} target='_blank'>Ver proyecto completo </Link>
                        </p>
                            </Col>
                            <Col xs={24} lg={12}><br />
                        <span className={valor !== 0 ? (valor === item.id ? 'btn_votar_select' : 'btn_votar') : 'btn_noselect'} onClick={() => setValor(item.id)}>
                        SELECCIONAR
                        </span>
                            </Col>
                        </Row>
                </Col>
            </Row>
            </>
        )
    });
    return (
        <>
            <Row justify={'center'}>
                        <Col span={20}>
                            <Form className="login-form"
                                layout="vertical"
                                name="basic"
                                onFinish={onFinish}
                                ref={ref_form}
                            >
                                <Row>
                                    <Col span={24}><br /><br />
                                        <p className='titulo_objeticos color_azul fuente' style={{textAlign:'center'}}>EDIFICIOS INDUSTRIALES</p>
                                    </Col>
                                    <Col span={24}>
                                        {ver_proyectos}
                                    </Col>
                                    <Col span={24}>
                                                <Form.Item>
                                                <br /><br />
                                                    <p style={{ textAlign: 'center', display: 'non' }}>
                                                        <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType="submit">
                                                            <p className="btn_registrate_home"><strong>ENVIAR VOTO</strong></p>
                                                        </button>
                                                    </p>

                                                </Form.Item>

                                            </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
        </>
    )
}
export default Industrial;
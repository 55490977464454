import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

// Milliseconds for the calculations
const MILLISECONDS_OF_A_SECOND = 1000;
const MILLISECONDS_OF_A_MINUTE = MILLISECONDS_OF_A_SECOND * 60;
const MILLISECONDS_OF_A_HOUR = MILLISECONDS_OF_A_MINUTE * 60;
const MILLISECONDS_OF_A_DAY = MILLISECONDS_OF_A_HOUR * 24

const Contador = (props) => {

  const [mostrarMensaje, setMostrarMensaje] = useState(false);
  const { dateTarget, idioma } = props;
  const [segundos, setSegundos] = useState(Math.floor(((new Date(dateTarget) - (new Date())) % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND));
  const [minutos, setMinutos] = useState(Math.floor(((new Date(dateTarget) - (new Date())) % MILLISECONDS_OF_A_HOUR) / MILLISECONDS_OF_A_MINUTE));
  const [horas, setHoras] = useState(Math.floor(((new Date(dateTarget) - (new Date())) % MILLISECONDS_OF_A_DAY) / MILLISECONDS_OF_A_HOUR));
  const [dias, setDias] = useState(Math.floor(((new Date(dateTarget) - (new Date())) / MILLISECONDS_OF_A_DAY)));

  const updateCountdown = () => {
    // Calcs
    const NOW = new Date();
    const DURATION = new Date(dateTarget) - NOW;

    if (DURATION <= 0) {
      setMostrarMensaje(true);
    }

    setDias(Math.floor(DURATION / MILLISECONDS_OF_A_DAY));
    setHoras(Math.floor((DURATION % MILLISECONDS_OF_A_DAY) / MILLISECONDS_OF_A_HOUR));
    setMinutos(Math.floor((DURATION % MILLISECONDS_OF_A_HOUR) / MILLISECONDS_OF_A_MINUTE));
    setSegundos(Math.floor((DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND));

  }


  useEffect(() => {
    setInterval(updateCountdown, MILLISECONDS_OF_A_SECOND);
  }, []);

  return (
    <Row justify="center" align="middle" className="contenedor_contador">

      {mostrarMensaje ? (
        <Col span={20} offset={1} style={{ textAlign: 'center', display:'none' }}>
          <p className="mensaje-contador">¡Bienvenidos!</p>
        </Col>
      )
        :
        (
          <>
            <Col xs={5} md={3}>
              <p className="contador">
                <span className="numero" id="days">{dias}</span><br />
                <span className="fecha">{idioma === 'es' ? 'DÍAS' : 'DAYS'}</span>
              </p>
            </Col>
            <Col xs={1}>
              <p className='separador1'>:<br /></p>
            </Col>
            <Col xs={5} md={4}>
              <p className="contador">
                <span className="numero" id="hours">{horas}</span><br />
                <span className="fecha">{idioma === 'es' ? 'HRS' : 'HRS'}</span>
              </p>
            </Col>
            <Col xs={1}>
              <p className='separador'>:<br /></p>
            </Col>
            <Col xs={5} md={4}>
              <p className="contador">
                <span className="numero" id="minutes">{minutos}</span><br />
                <span className="fecha">{idioma === 'es' ? 'MIN' : 'MIN'}</span>
              </p>
            </Col>
            <Col xs={1}>
              <p className='separador'>:<br /></p>
            </Col>
            <Col xs={5} md={4}>
              <p className="contador">
                <span className="numero" id="seconds">{segundos}</span><br />
                <span className="fecha">{idioma === 'es' ? 'SEG' : 'SEC'}</span>
              </p>
            </Col>
          </>
        )
      }


    </Row>
  );
}

export default Contador;

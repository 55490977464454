import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CaretLeftFilled, CaretRightFilled, LeftOutlined, LinkedinFilled, RightOutlined } from '@ant-design/icons';
import '../../assets/css/ponentes.css';

import AideeOlmos from'../../assets/images/evento/ponentes_2/Sume_AideeOlmos.png';
import AliciaCarrillo from'../../assets/images/evento/ponentes_2/Sume_AliciaCarrillo.png';
import AliciaSilva from'../../assets/images/evento/ponentes_2/Sume_AliciaSilva.png';
import AndresBahamon from'../../assets/images/evento/ponentes_2/Sume_AndresBahamon.png';
import AntonioCardenas from'../../assets/images/evento/ponentes_2/Sume_AntonioCardenas.png';
import CarlosSanchez from'../../assets/images/evento/ponentes_2/Sume_CarlosSanchez.png';
import ChrisPyke from'../../assets/images/evento/ponentes_2/Sume_ChrisPyke.png';
import DanielaCastro from'../../assets/images/evento/ponentes_2/Sume_DanielaCastro.png';
import DarioIbargengoitia from'../../assets/images/evento/ponentes_2/Sume_DarioIbargengoitia.png';
import EnriqueMotola from'../../assets/images/evento/ponentes_2/Sume_EnriqueMotola.png';
import FernandaAguirre from'../../assets/images/evento/ponentes_2/Sume_FernandaAguirre.png';
import GeorgeJaksh from'../../assets/images/evento/ponentes_2/Sume_GeorgeJaksh.png';
import HeidiValdez from'../../assets/images/evento/ponentes_2/Sume_HeidiValdez.png';
import JachenSchleich from'../../assets/images/evento/ponentes_2/Sume_JachenSchleich.png';
import JaneraSoerel from'../../assets/images/evento/ponentes_2/Sume_JaneraSoerel.png';
import JavierMacias from'../../assets/images/evento/ponentes_2/Sume_JavierMacias.png';
import JerzyWojcik from'../../assets/images/evento/ponentes_2/Sume_JerzyWojcik.png';
import JessicaDiaz from'../../assets/images/evento/ponentes_2/Sume_JessicaDiaz.png';
import JesuusGalvan from'../../assets/images/evento/ponentes_2/Sume_JesusGalvan.png';
import JoaquinaNiembro from'../../assets/images/evento/ponentes_2/Sume_JoaquinaNiembro.png';
import JohanaRuiz from'../../assets/images/evento/ponentes_2/Sume_JohanaRuiz.png';
import JonathanLandry from'../../assets/images/evento/ponentes_2/Sume_JonathanLandry.png';
import MarcelaHeredia from'../../assets/images/evento/ponentes_2/Sume_MarcelaHeredia.png';
import MariaMatamoros from'../../assets/images/evento/ponentes_2/Sume_MariaMatamoros.png';
import MarionHammerl from'../../assets/images/evento/ponentes_2/Sume_MarionHammerl.png';
import MarthaDelgado from'../../assets/images/evento/ponentes_2/Sume_MarthaDelgado.png';
import MauricioRamirez from'../../assets/images/evento/ponentes_2/Sume_MauricioRamirez.png';
import MelissaBaker from'../../assets/images/evento/ponentes_2/Sume_MelissaBaker.png';
import MiguelGutierrez from'../../assets/images/evento/ponentes_2/Sume_MiguelGutierrez.png';
import OmmidSaberi from'../../assets/images/evento/ponentes_2/Sume_OmmidSaberi.png';
import PeterTempleton from'../../assets/images/evento/ponentes_2/Sume_PeterTempleton.png';
import RicardoBusey from'../../assets/images/evento/ponentes_2/Sume_RicardoBusey.png';
import RicardoLozano from'../../assets/images/evento/ponentes_2/Sume_RicardoLozano.png';
import RodolfoMorales from'../../assets/images/evento/ponentes_2/Sume_RodolfoMorales.png';
import RuthCorona from'../../assets/images/evento/ponentes_2/Sume_RuthCorona.png';
import SalvadorRivas from'../../assets/images/evento/ponentes_2/Sume_SalvadorRivas.png';
import SeveroLopez from'../../assets/images/evento/ponentes_2/Sume_SeveroLopez.png';
import UlisesTrevino from'../../assets/images/evento/ponentes_2/Sume_UlisesTrevino.png';
import ValeriaDagnino from'../../assets/images/evento/ponentes_2/Sume_ValeriaDagnino.png';
//import VatsalBhatt from'../../assets/images/evento/ponentes_2/Sume_VatsalBhatt.png';
import YvenEcheverría from'../../assets/images/evento/ponentes_2/Sume_YvenEcheverría.png';


import CarlosMonjaraz__ponentes from'../../assets/images/evento/ponentes_2/CarlosMonjaraz__ponentes.png';
import FernandaGuarro_ponentes from'../../assets/images/evento/ponentes_2/FernandaGuarro_ponentes.png';
import JuanCarlosPineda_ponentes from'../../assets/images/evento/ponentes_2/JuanCarlosPineda_ponentes.png';
import MiguelLeonSilva_ponentes from'../../assets/images/evento/ponentes_2/MiguelLeonSilva_ponentes.png';

import DanielaContreras_ponentes from'../../assets/images/evento/ponentes_2/DanielaContreras_ponentes.png';
import TamaraWeitzsacker_ponentes from'../../assets/images/evento/ponentes_2/TamaraWeitzsacker_ponentes.png';


import PaolaNaciff_ponentes from'../../assets/images/evento/ponentes_2/PaolaNaciff_ponentes.png';
import IanDelaGarza_ponentes from'../../assets/images/evento/ponentes_2/IanDelaGarza_ponentes.png';
import GabrielaGonzalez__ponentes from'../../assets/images/evento/ponentes_2/GabrielaGonzalez__ponentes.png';
import StephanieBarger__ponentes from'../../assets/images/evento/ponentes_2/StephanieBarger__ponentes.png';
import AlanMartinez_ponentes from'../../assets/images/evento/ponentes_2/AlanMartinez_ponentes.png';
import RicardoTorresHdz_ponentes from'../../assets/images/evento/ponentes_2/RicardoTorresHdz_ponentes.png';
import JerzyWojcik_ponentes from'../../assets/images/evento/ponentes_2/JerzyWojcik_ponentes.png';
import FernandaAguirre_ponentes from'../../assets/images/evento/ponentes_2/FernandaAguirre_ponentes.png';
import DavidBurckle_ponentes from'../../assets/images/evento/ponentes_2/DavidBurckle_ponentes.png';

const logos = [
    { index:1, img_src: AideeOlmos, nombre: 'Aidée', apellido: 'Olmos',
    empresa:'HSBC',
    link:'https://www.linkedin.com/in/aidee-olmos-a7070a56/',
    descripcion:[""], 
      previu:[""]
    },{ index:2, img_src: AliciaCarrillo, nombre: 'Alicia', apellido: 'Carrillo',
    empresa:'HOLCIM',
    link:'https://www.linkedin.com/in/alicia-carrillo-famoso/',
    descripcion:[""], 
      previu:[""]
    },
    { index:24, img_src: AliciaSilva, nombre: 'Alicia', apellido: 'Silva',
    empresa:'REVITALIZA CONSULTORES',
    link:'https://www.linkedin.com/in/aliciasilvasynergy/',
    descripcion:[""], 
      previu:[""]
    },
    { index:1, img_src: AndresBahamon, nombre: 'Andrés', apellido: 'Bahamon',
    empresa:'SANTANDER',
    link:'https://www.linkedin.com/in/andresbahamon/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:1, img_src: AntonioCardenas, nombre: 'Antonio', apellido: 'Cárdenas',
    empresa:'FIBRA HOTEL',
    link:'https://www.linkedin.com/in/antoniocardenassalazar/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:1, img_src: CarlosSanchez, nombre: 'Carlos', apellido: 'Sánchez',
    empresa:'MÉXICO CO2',
    link:'https://www.linkedin.com/in/carlos-alejandro-s%C3%A1nchez-tablas-6a579820b/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:5, img_src: ChrisPyke, nombre: 'Chris', apellido: 'Pyke',
    empresa:'GRESB',
    link:'https://www.linkedin.com/in/chrispyke/',
    descripcion:[""], 
      previu:[""]
    },
    { index:6, img_src: DanielaCastro, nombre: 'Daniela', apellido: 'Castro',
    empresa:'JWA',
    link:'https://www.linkedin.com/in/daniela-castro-salgado-49987118/',
    descripcion:[""], 
      previu:[""]
    },
    { index:7, img_src: DarioIbargengoitia, nombre: 'Darío', apellido: 'Ibargüengoitia',
    empresa:'IBALCA',
    link:'https://www.linkedin.com/in/dario-ibargüengoitia-2496ba9/',
    descripcion:[""], 
      previu:[""]
    },
    { index:8, img_src: EnriqueMotola, nombre: 'Enrique', apellido: 'Motolá',
    empresa:'THREE CONSULTORIA MEDIOAMBIENTAL',
    link:'https://www.linkedin.com/in/enriquemotola/',
    descripcion:[""], 
      previu:[""]
    },
    { index:9, img_src: FernandaAguirre, nombre: 'María Fernanda', apellido: 'Aguirre',
    empresa:'GBC CHILE',
    link:'https://www.linkedin.com/in/guillermo-almazo-garza-5a331284/',
    descripcion:[""], 
      previu:[""]
    },
    { index:9, img_src: GeorgeJaksh, nombre: 'George', apellido: 'Jaksch',
    empresa:'BIODIVERSITY PARTNERSHIP MESOAMÉRICA',
    link:'https://www.linkedin.com/in/george-jaksch-8952971/?originalSubdomain=be',
    descripcion:[""], 
      previu:[""]
    },
    { index:9, img_src: HeidiValdez, nombre: 'Heidi', apellido: 'Valdéz',
    empresa:'REVITALIZA CONSULTORES',
    link:'https://www.linkedin.com/in/heidi-valdez-v%C3%A9lez-bb369b104/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:10, img_src: JachenSchleich, nombre: 'Jachen', apellido: 'Schleich',
    empresa:'MINERGIE MÉXICO',
    link:'https://www.linkedin.com/in/jachen-duri-schleich-61179912/',
    descripcion:[""], 
      previu:[""]
    },
    { index:11, img_src: JaneraSoerel, nombre: 'Janera', apellido: 'Soerel',
    empresa:'IWBI',
    link:'https://www.linkedin.com/in/janera/',
    descripcion:[""], 
      previu:[""]
    },
    { index:11, img_src: JavierMacias, nombre: 'Javier', apellido: 'Macías',
    empresa:'HINES',
    link:'https://www.linkedin.com/in/javier-macias-63217543/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:11, img_src: JerzyWojcik, nombre: 'Jerzy', apellido: 'Wójcik',
    empresa:'JWA',
    link:'https://www.linkedin.com/in/jerzywojcikcee/?originalSubdomain=pl',
    descripcion:[""], 
      previu:[""]
    },
    { index:12, img_src: JessicaDiaz, nombre: 'Jessica', apellido: 'Díaz',
    empresa:'PIIMA',
    link:'https://www.linkedin.com/in/jessica-díaz-avelar-8aa6987/',
    descripcion:[""], 
      previu:[""]
    },
    { index:13, img_src: JesuusGalvan, nombre: 'Jesús', apellido: 'Galván',
    empresa:'CBRE',
    link:'https://www.linkedin.com/in/arqjesusgalvan/',
    descripcion:[""], 
      previu:[""]
    },
    { index:13, img_src: JoaquinaNiembro, nombre: 'Joaquina', apellido: 'Niembro',
    empresa:'UNIVERSIDAD PANAMERICANA',
    link:'https://www.linkedin.com/in/arqjesusgalvan/',
    descripcion:[""], 
      previu:[""]
    },
    { index:14, img_src: JohanaRuiz, nombre: 'Joanna', apellido: 'Ruiz Galindo',
    empresa:'WHAT DESIGN CAN DO',
    link:'https://www.linkedin.com/in/joanna-ruiz-galindo-ba560020/',
    descripcion:[""], 
      previu:[""]
    },
    { index:14, img_src: JonathanLandry, nombre: 'Jonathan', apellido: 'Landry',
    empresa:'CHAAC I+N+C',
    link:'https://www.linkedin.com/in/jonathanlandryleed/',
    descripcion:[""], 
      previu:[""]
    },
    { index:15, img_src: MarcelaHeredia, nombre: 'Marcela', apellido: 'Heredia',
    empresa:'CMIC CDMX',
    link:'https://www.linkedin.com/in/marcela-heredia-7227a94/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:16, img_src: MariaMatamoros, nombre: 'María', apellido: 'Matamoros',
    empresa:'ÁMBITO',
    link:'https://www.linkedin.com/in/matamorosmaria/',
    descripcion:[""], 
      previu:[""]
    },
    { index:16, img_src: MarionHammerl, nombre: 'Marion', apellido: 'Hammerl',
    empresa:'GLOBAL NATURE FUND',
    link:'https://de.linkedin.com/in/marion-hammerl-02009216',
    descripcion:[""], 
      previu:[""]
    },
    { index:17, img_src: MarthaDelgado, nombre: 'Martha', apellido: 'Delgado',
    empresa:'Subsecretaria para Asuntos Multilaterales y Derechos Humanos en la Secretaría de Relaciones Exteriores de México',
    link:'https://www.linkedin.com/in/martha-delgado-peralta-/',
    descripcion:[""], 
      previu:[""]
    },
    { index:17, img_src: MauricioRamirez, nombre: 'Mauricio', apellido: 'Ramírez',
    empresa:'BOVIS',
    link:'https://www.linkedin.com/in/mauricio-ram%C3%ADrez-ramirez-77836b5b/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:18, img_src: MelissaBaker, nombre: 'Melissa', apellido: 'Baker',
    empresa:'USGBC',
    link:'https://www.linkedin.com/in/melissacbaker/',
    descripcion:[""], 
      previu:[""]
    },
    { index:18, img_src: MiguelGutierrez, nombre: 'Juan Miguel', apellido: 'Gutiérrez',
    empresa:'CEMEX',
    link:'https://www.linkedin.com/in/juanmiguelgutierrez/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:26, img_src: OmmidSaberi, nombre: 'Ommid', apellido: 'Saberi',
    empresa:'IFC',
    link:'https://www.linkedin.com/in/ommidsaberi/',
    descripcion:[""], 
      previu:[""]
    },
    { index:19, img_src: PeterTempleton, nombre: 'Peter', apellido: 'Templeton',
    empresa:'USGBC',
    link:'https://www.linkedin.com/in/peter-templeton/',
    descripcion:[""], 
      previu:[""]
    },
    { index:20, img_src: RicardoBusey, nombre: 'Ricardo', apellido: 'Bussey',
    empresa:'Johnson Controls',
    link:'https://www.linkedin.com/in/ricardo-bussey-garcía-60231a19/',
    descripcion:[""], 
      previu:[""]
    },
    { index:20, img_src: RicardoLozano, nombre: 'Ricardo', apellido: 'Lozano',
    empresa:'WHAT DESIGN CAN DO',
    link:'https://www.linkedin.com/in/ricardo-lozano-0704091a/',
    descripcion:[""], 
      previu:[""]
    },
    { index:20, img_src: RodolfoMorales, nombre: 'Rodolfo', apellido: 'Morales',
    empresa:'FINSA',
    link:'https://www.linkedin.com/in/rodolfo-morales-90a71935/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    { index:25, img_src: RuthCorona, nombre: 'Ruth', apellido: 'Corona',
    empresa:'JLL',
    link:'https://www.linkedin.com/in/ruth-c-37480b41/',
    descripcion:[""], 
      previu:[""]
    },
    { index:21, img_src: SalvadorRivas, nombre: 'Salvador', apellido: 'Rivas',
    empresa:'S* Arc Architects',
    link:'https://www.linkedin.com/in/salvadorrivasarchitect/',
    descripcion:[""], 
      previu:[""]
    },
    { index:22, img_src: SeveroLopez, nombre: 'Severo', apellido: 'López Mestre',
    empresa:'ESPECIALISTA EN ENERGÍA',
    link:'https://www.linkedin.com/in/severolma/',
    descripcion:[""], 
      previu:[""]
    },
    { index:23, img_src: UlisesTrevino, nombre: 'Ulises', apellido: 'Treviño',
    empresa:'BIOCONSTRUCCION Y ENERGÍA ALTERNATIVA',
    link:'https://www.linkedin.com/in/cutt/',
    descripcion:[""], 
      previu:[""]
    },
    { index:23, img_src: ValeriaDagnino, nombre: 'Valeria', apellido: 'Dagnino',
    empresa:'CLIMATEBONDS',
    link:'https://www.linkedin.com/in/valeria-dagnino-contreras-51818b168/?originalSubdomain=mx',
    descripcion:[""], 
      previu:[""]
    },
    /*{ index:23, img_src: VatsalBhatt, nombre: 'Vatsal', apellido: 'Bhatt',
    empresa:'USGBC',
    link:'https://www.linkedin.com/in/vatsalbhatt/',
    descripcion:[""], 
      previu:[""]
    },*/
    { index:24, img_src: CarlosMonjaraz__ponentes, nombre: 'Carlos', apellido: 'Monjaraz',
    empresa:'KOVAL Inmobiliaria',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:25, img_src: FernandaGuarro_ponentes, nombre: 'Fernanda', apellido: 'Guarro',
    empresa:'3M',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:26, img_src: JuanCarlosPineda_ponentes, nombre: 'Juan Carlos', apellido: 'Pineda',
    empresa:'HKS',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    /*{ index:27, img_src: MiguelLeonSilva_ponentes, nombre: 'Miguel', apellido: 'Leon Silva',
    empresa:'CENTRO DE ECOLOGÍA Y CAMBIO CLIMÁTICO DEL ESTADO DE QUERÉTARO',
    link:'',
    descripcion:[""], 
      previu:[""]
    },*/
    { index:28, img_src: DanielaContreras_ponentes, nombre: 'Daniela', apellido: 'Contreras',
    empresa:'Municipio de San Pedro Garza García',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:29, img_src: TamaraWeitzsacker_ponentes, nombre: 'Tamara', apellido: 'Weitzsäcker',
    empresa:'Twin Dolphin',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: YvenEcheverría, nombre: 'Yven', apellido: 'Echeverría',
    empresa:'ESPECIALISTA EN CAMBIO CLIMÁTICO',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: PaolaNaciff_ponentes, nombre: 'Paola', apellido: 'Naciff',
    empresa:'FIBRA TERRAFINA',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: IanDelaGarza_ponentes, nombre: 'Ian', apellido: 'De la Garza',
    empresa:'FINSOLAR',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: GabrielaGonzalez__ponentes, nombre: 'Gabriela', apellido: 'Gonzalez',
    empresa:'USG',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: StephanieBarger__ponentes, nombre: 'Stephanie', apellido: 'Barger',
    empresa:'GBCI',
    link:'https://www.linkedin.com/in/stephanie-barger-8232525/',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: AlanMartinez_ponentes, nombre: 'Alan', apellido: 'Martinez',
    empresa:'Niagara Bottling',
    link:'https://www.linkedin.com/in/alan-martínez-ba10ab116/',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: RicardoTorresHdz_ponentes, nombre: 'Ricardo', apellido: 'Torres Hernández',
    empresa:'Sustainability, Decarbonization & Circular Economy',
    link:'https://www.linkedin.com/in/ricardo-javier-torres-hern%C3%A1ndez-ba063860',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: JerzyWojcik_ponentes, nombre: 'Jerzy', apellido: 'Wójcik',
    empresa:'JWA',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: FernandaAguirre_ponentes, nombre: 'María Fernanda', apellido: 'Aguirre',
    empresa:'CHILE GBC',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    { index:30, img_src: DavidBurckle_ponentes, nombre: 'David', apellido: 'Burckle',
    empresa:'Vereda & Co',
    link:'',
    descripcion:[""], 
      previu:[""]
    },
    
];




const CarouselPonentes = (props) => {

  const ref_carousel = React.createRef();
  const [activar_carouse, setActivaCarosel] = useState(0);
  const {idioma} = props;
  const [select_persona, setSelectPersona] = useState(0);
  const [ver_modal, setVerModal] = useState(false);

    const ver_personas = logos.map((item) => {
      return(
        <>
        <Col span={5}>
        <div className="img_contenedor_ponente">
        <img src={item.img_src} alt={item.nombre} className="img_ponente" />
        <div className="overlay_ponente">
              <div className="text_ponente">
                <span className='nombre_hover_ponente'>{item.nombre} {item.apellido}</span><br />
                <span className='previu_hover_ponente'>{item.previu}</span><br /><br />
                <span onClick={()=>{setVerModal(true);setSelectPersona(item.index)}} className='masinfo_hover_ponente'>Bio</span>
              </div>
            </div>
          </div>
          <br />
          </Col>
        </>
      )
    });
    const ver_personas_movil = logos.map((item) => {
      return(
        <>
        <div className="img_contenedor_ponente">
        <img src={item.img_src} alt={item.nombre} className="img_ponente" />
        <div className="overlay_ponente">
              <div className="text_ponente">
                <span className='nombre_hover_ponente'>{item.nombre} {item.apellido}</span><br />
                <span className='previu_hover_ponente'>{item.previu}</span><br />
                {item.index === 10 ?
                <></>
                :
                <span onClick={()=>{setVerModal(true);setSelectPersona(item.index)}} className='masinfo_hover_ponente'>{idioma === 'es' ? 'Más información' : 'More information'}</span>
    }
              </div>
            </div>
          </div>
        </>
      )
    });

    const ver_persona = logos.map((item) => {
      return(
        <>
        <div className="contenedor_ponente">
          <img src={item.img_src} alt={item.nombre} className="img_ponente" />
            <div className="texto_ponente">
              <span className='name_ponente'>{item.nombre} {item.apellido}</span><br />
              <span className='puesto_ponente'>{item.empresa}</span><br />
              {item.link != '' ? <span className='back_linkedin'><a href={item.link} className='color_azul' target="_blank"><LinkedinFilled className='icon_linkedin' /></a></span>
              :<></>}
              </div>
          </div>
        </>
      )
    });
    const ver_info_personas = logos.filter((personas) =>{
      if(personas.index === select_persona)
      return true;
      else
      return false;
    }).map((item) => {
      return(
        <>
          <Row justify='center'>
            <Col xs={20} md={20}style={{padding:'5px 10px'}}>
            <p className='nombre_modal_ponente'>{item.nombre} {item.apellido}</p>
            <p className='descripcion_modal_ponente'>{item.descripcion}</p>
            </Col>
          </Row>
        </>
      )
    });
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        
        <CaretRightFilled className={className} style={{ ...style, display: "block", color: '#97B623', fontSize: '32px', right: '-10px', backgroundColor: '#ffffff', border: '2px solid #97B623', borderRadius: '8px', height: '38px', width: '34px', margin: '0px', padding: '0px', boxShadow: '2px 6px 13px 0px rgba(0,0,0,0.35)' }} onClick={onClick} />
      );
    }
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'black', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
      );
    }

    const settingsPonentes = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      adaptiveHeight: false,
      arrows: false,
      centerMode: true,
      className: "center",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            adaptiveHeight: true,
          }
        }
      ]
    };

    const settingslogos = {
            infinite: true,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 10000,
            speed:2000,
            dots: false,
            adaptiveHeight: true
    };

    const cambiarCarousel = (adelante) => {
      if(adelante){
          if(activar_carouse === 22){
            setActivaCarosel(1);
          }else{
            setActivaCarosel(activar_carouse+1);
          }
          ref_carousel.current.slickNext();
      }else{
          if(activar_carouse === 0){
            setActivaCarosel(22);
          }else{
            setActivaCarosel(activar_carouse-1);
          }
          ref_carousel.current.slickPrev();
      }
      
      }

    return (
      <>
      <Row align="middle" justify="center">
      <Col span={20}>
        <br /><br />
        <p className='titulo_ponentes fuente'>{idioma ==='es' ? 'CONOCE A NUESTROS SPEAKERS' : 'MEET OUR SPEAKERS'}</p>
      </Col>
      </Row>
      <Row justify="center" align="middle" className=''>
        
          <Col span={4}>
          <CaretLeftFilled className="flecha_agenda" onClick={() => {cambiarCarousel(false);}}/>
          </Col>
          <Col span={12}>
          {idioma === 'es' ?
          <p className='texto_2_ponentes'>
            Podrás escuchar a las voces más  destacadas  de la edificación sustentable provenientes de<br />
            Latinoamerica, Estados Unidos y México, a través sesiones especializadas.
            </p>
            :
            <p className='texto_2_ponentes'>
            You will be able to listen to the most prominent voices in sustainable building from <br />Latin America, the United States and Mexico, through specialized sessions.
            </p>}
            </Col>
          <Col span={4}>
          <CaretRightFilled className="flecha_agenda" onClick={() => {cambiarCarousel(true);}}/>
          </Col>
        
        <Col xs={24}>
          <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'20px 50px', height:'100%'}} className="slide_ponente">
          {ver_persona}
          </Slider>
        </Col>
        <Col xs={24}>
        </Col>
      </Row>
      <Modal 
      visible={ver_modal} onCancel={() => { setVerModal(false); }}
      title={null}
      footer={null}
      closable={true}
      width="750px"
                className=""
                forceRender={true}>
        {ver_info_personas}
      </Modal>
    </>
    );

};

export default CarouselPonentes;
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import CarouselPatrocinadores from '../evento/CarouselPatrocinadores';

import { AiFillFacebook, AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";

import hasthag from '../../../src/assets/images/sitio/Iconos/Sinergia_Hashtag.png';
import logo_footer_1 from '../../../src/assets/images/sitio/Logos/Sinergia_LogoFooter.png';

import logo_1 from '../../../src/assets/images/evento/logos/LOGO_contruyendo_header_bco.png';

import logo_2 from '../../../src/assets/images/evento/logos/logo_cume _blanco.png';
import logo_3 from '../../../src/assets/images/evento/logos/LOGO_GBCI_b.png';
import logo_4 from '../../../src/assets/images/evento/logos/LOGO_US commercial serv.png';
import logo_5 from '../../../src/assets/images/evento/logos/logo-torre-mayor.png';
import logo_6 from '../../../src/assets/images/evento/logos/LOGO_worldgreen_bco.png';

import platino_1 from '../../../src/assets/images/evento/patrocinadores/platino/LOGO_saintgobain_color.png';

import oro_1 from '../../../src/assets/images/evento/patrocinadores/oro/LOGO_holcim.png';
import oro_2 from '../../../src/assets/images/evento/patrocinadores/oro/LOGO_edgeIFC.png';
import oro_3 from '../../../src/assets/images/evento/patrocinadores/oro/LGO_GBCI_n.png';
import oro_4 from '../../../src/assets/images/evento/patrocinadores/oro/LOGO_3M.png';

import plata_1 from '../../../src/assets/images/evento/patrocinadores/plata/LOGO_revitaliza.png';
import plata_2 from '../../../src/assets/images/evento/patrocinadores/plata/LOGO_todoaccesible.png';
import plata_3 from '../../../src/assets/images/evento/patrocinadores/plata/LOGO_JonhnsonControls.png';
import plata_4 from '../../../src/assets/images/evento/patrocinadores/plata/LOGO_JWA.png';


import bronce_1 from '../../../src/assets/images/evento/patrocinadores/bronce/LOGO_cbre.png';
import bronce_2 from '../../../src/assets/images/evento/patrocinadores/bronce/LOGO_CRDC.png';
import bronce_3 from '../../../src/assets/images/evento/patrocinadores/bronce/logowhatdesignmx.png';


import afiliados_1 from '../../../src/assets/images/evento/afiliados/LOGO_amefibra.png';
import afiliados_2 from '../../../src/assets/images/evento/afiliados/LOGO_americ.png';
import afiliados_3 from '../../../src/assets/images/evento/afiliados/LOGO_IES.png';
import afiliados_4 from '../../../src/assets/images/evento/afiliados/LOGO_inmobiliare25.png';
import afiliados_5 from '../../../src/assets/images/evento/afiliados/LOGO_NZC.png';
import afiliados_6 from '../../../src/assets/images/evento/afiliados/LOGO_ULI.png';
import afiliados_7 from '../../../src/assets/images/evento/afiliados/LOGO_ALENER.png';
import afiliados_8 from '../../../src/assets/images/evento/afiliados/LOGO_ADI.png';
import afiliados_9 from '../../../src/assets/images/evento/afiliados/LOGO_BMV.png';
import afiliados_10 from '../../../src/assets/images/evento/afiliados/IMEI_BOMA_logo.png';
import afiliados_11 from '../../../src/assets/images/evento/afiliados/LOGO_colegioarqueitectos.png';
import afiliados_12 from '../../../src/assets/images/evento/afiliados/logo-onncce.png';
import afiliados_13 from '../../../src/assets/images/evento/afiliados/cmic_logo.png';

import doc_patrocinio from '../../../src/assets/documentos/ConstruyendoElFuturo_CatalogoPatrocinios_221123.pdf'

import { LinkedinFilled } from '@ant-design/icons';

const Footer = (props) => {
  let location = useLocation();
  console.log('location',location);
  const { idioma } = props;
  const [hover, setHover] = useState('0');

  return (
    <>
      {location.pathname === '/votacion' ?
      <></>
      :
        <Row justify={'center'}>
        <Col xs={{span:20}}><br />
          <p className='titulo_objeticos color_azul fuente' style={{textAlign:'center'}}>{idioma === 'es' ? 'Nuestros Patrocinadores' : 'Our Sponsors'}</p>
          {idioma === 'es' ?
            <p className='patrocinadores_footer'>Construyendo el Futuro | Foro Ejecutivo de Acciones Climáticas<br /> no sería posible sin el apoyo de nuestros excelentes patrocinadores<br /><br />
            <span className='color_verde'><strong><Link className='color_verde' to={doc_patrocinio} target='_blank'>¿Quieres ser patrocinador?</Link></strong></span>
          </p>
          :
          <p className='patrocinadores_footer'>Building the Future | Executive Forum on Climate Action<br /> It would not be possible without the support of our excellent sponsors<br /><br />
            <span className='color_verde'><strong><Link className='color_verde' to={doc_patrocinio} target='_blank'>Do you want to be a sponsor?</Link></strong></span>
          </p>
          }
        </Col>
        
        <Col xs={{span:20}}>
          <p className='titulo_objeticos fuente'>{idioma === 'es' ? 'PATROCINADOR PLATINO' : 'PLATINUM SPONSOR'}</p>
          <Row justify={'center'}>
            <Col xs={12} md={5}>
              <img src={platino_1} className="logo_footer" />
            </Col>
          </Row>
          <br /><br />
        </Col>
        <Col xs={{span:20}}>
          <p className='titulo_objeticos fuente'>{idioma === 'es' ? 'PATROCINADORES ORO' : 'GOLD SPONSORS'}</p>
          <Row justify={'center'}>
            <Col xs={12} md={5}>
              <img src={oro_1} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={oro_2} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={oro_3} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={oro_4} className="logo_footer" />
            </Col>
          </Row>
          <br /><br />
        </Col>
        <Col xs={{span:20}}>
          <p className='titulo_objeticos fuente'>{idioma === 'es' ? 'PATROCINADORES PLATA' : 'SILVER SPONSORS'}</p>
          <Row justify={'center'}>
            <Col xs={12} md={5}>
              <img src={plata_1} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={plata_2} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={plata_3} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={plata_4} className="logo_footer" />
            </Col>
          </Row>
          <br /><br />
        </Col>
        <Col xs={{span:20}}>
          <p className='titulo_objeticos fuente'>{idioma === 'es' ? 'PATROCINADORES ESPECIALES' : 'SPECIAL SPONSORS'}</p>
          <Row justify={'center'}>
            <Col xs={12} md={5}>
              <img src={bronce_1} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={bronce_2} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={bronce_3} className="logo_footer" />
            </Col>
          </Row>
          <br /><br />
        </Col>
        <Col xs={{span:20}}>
          <p className='titulo_objeticos fuente'>{idioma === 'es' ? 'ALIADOS' : 'ALLIES'}</p>
          <Row justify={'center'}>
            <Col xs={12} md={5}>
              <img src={afiliados_1} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_2} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_3} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_4} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_5} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_6} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_7} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_8} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_9} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_10} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_11} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_12} className="logo_footer" />
            </Col>
            <Col xs={12} md={5}>
              <img src={afiliados_13} className="logo_footer" />
            </Col>
          </Row>
          <br /><br />
        </Col>
        
      </Row>
      }
      <Row justify='' align="middle" className="contenedor_footer">
        <Col xs={{span:20, offset:2}} lg={{span:5, offset:2}}>
          <img src={logo_1} style={{width:'100%'}} /><br />
          <p style={{textAlign:'left'}}>
          <a href="mailto:info@construyendoelfuturo.mx" target='_blank' className='link-footer'><strong>info@construyendoelfuturo.mx</strong></a><br /><br />
          <a href="https://www.facebook.com/SUMeSustentabilidadParaMexico" target="_blank"><AiFillFacebook className="redes" /></a>
          </p>
          <p className='texto_footer'>
          {idioma === 'es' ? 'Copyright © 2024 Todos los Derechos Reservados' : 'All rights reserved'} <br />
          <a className='link-footer'>{idioma === 'es' ? 'Aviso de Privacidad' : 'Notice of Privacy'}</a>
          </p>
        </Col>
        <Col xs={{span:20,offset:2}} lg={{span:17, offset:0}}>
          <Row justify={'end'}>
            <Col xs={22} md={22}>
            <p className='organizados'><strong>{idioma === 'es' ? 'Evento organizado por' : 'Event organized by'}</strong></p>
            </Col>
            <Col xs={24} md={24}>
            <Row justify={'center'}>
            <Col xs={8} md={5}>
              <img src={logo_2} className="logo_footer" />
            </Col>
            <Col xs={8} md={5}>
              <img src={logo_3} className="logo_footer" />
            </Col>
            <Col xs={8} md={5}>
              <img src={logo_5} className="logo_footer" />
            </Col>
            <Col xs={8} md={5}>
              <img src={logo_6} className="logo_footer" />
            </Col>
            </Row>
            </Col>
          </Row>
        </Col>
      </Row>

    </>
  );
}

export default Footer;
import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Upload, Checkbox, Button, notification, Spin, Radio, Image } from 'antd';
import '../../assets/css/proyectos.css';

import { Link } from 'react-router-dom';
import Firebase from '../../utils/firebaseConfig';
import { guardar_votos } from '../../utils/ConexionServidor';
import Item from 'antd/lib/list/Item';

import proyecto_1_pdf from '../../assets/proyectos/03 Torre Cervantes 251/Premios IMPULSO Sume- Torre Cervantes 251.pdf';
import proyecto_1_img1 from '../../assets/proyectos/03 Torre Cervantes 251/1.png';
import proyecto_1_img2 from '../../assets/proyectos/03 Torre Cervantes 251/2.jpg';
import proyecto_1_img3 from '../../assets/proyectos/03 Torre Cervantes 251/3.jpg';
import proyecto_1_img4 from '../../assets/proyectos/03 Torre Cervantes 251/4.jpg';
import proyecto_1_img5 from '../../assets/proyectos/03 Torre Cervantes 251/5.png';

import proyecto_2_pdf from '../../assets/proyectos/04 REDWOOD/Redwood_LEEDOM_Premios Impulso SUMe.pdf';
import proyecto_2_img1 from '../../assets/proyectos/04 REDWOOD/1.jpg';
import proyecto_2_img2 from '../../assets/proyectos/04 REDWOOD/2.jpg';
import proyecto_2_img3 from '../../assets/proyectos/04 REDWOOD/3.jpg';

import proyecto_3_pdf from '../../assets/proyectos/05 Corporativo Neuchatel/Corporativo Neuchatel .pdf';
import proyecto_3_img1 from '../../assets/proyectos/05 Corporativo Neuchatel/1.jpg';
import proyecto_3_img2 from '../../assets/proyectos/05 Corporativo Neuchatel/2.jpg';
import proyecto_3_img3 from '../../assets/proyectos/05 Corporativo Neuchatel/3.jpg';

import proyecto_4_pdf from '../../assets/proyectos/08 Chapultepec UNO 509/PREMIOS IMPULSO SUME BY SAINT GOBAIN 2024 - Chapultepec UNO 509.pdf';
import proyecto_4_img1 from '../../assets/proyectos/08 Chapultepec UNO 509/1.jpg';
import proyecto_4_img2 from '../../assets/proyectos/08 Chapultepec UNO 509/2.jpg';
import proyecto_4_img3 from '../../assets/proyectos/08 Chapultepec UNO 509/3.jpg';
import proyecto_4_img4 from '../../assets/proyectos/08 Chapultepec UNO 509/4.jpg';
import proyecto_4_img5 from '../../assets/proyectos/08 Chapultepec UNO 509/5.jpg';

import proyecto_5_pdf from '../../assets/proyectos/12 SONORA 46/SONORA 46 - ANEXO_1_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN.pdf';
import proyecto_5_img1 from '../../assets/proyectos/12 SONORA 46/1.jpg';
import proyecto_5_img2 from '../../assets/proyectos/12 SONORA 46/2.jpg';
import proyecto_5_img3 from '../../assets/proyectos/12 SONORA 46/3.jpg';
import proyecto_5_img4 from '../../assets/proyectos/12 SONORA 46/4.jpg';

import proyecto_6_pdf from '../../assets/proyectos/13 PUERTA POLANCO/PUERTA POLANCO ANEXO_1_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN_2024.pdf';
import proyecto_6_img1 from '../../assets/proyectos/13 PUERTA POLANCO/1.jpg';
import proyecto_6_img2 from '../../assets/proyectos/13 PUERTA POLANCO/2.jpg';

import proyecto_7_pdf from '../../assets/proyectos/17 FORUM NAUCALPAN/FORUM NAUCALPAN TORRE 2.pdf';
import proyecto_7_img1 from '../../assets/proyectos/17 FORUM NAUCALPAN/1.jpg';
import proyecto_7_img2 from '../../assets/proyectos/17 FORUM NAUCALPAN/2.jpg';

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const Mixtos = ({ history, onResponse, onResponseCerrarLogin, idioma, categoria, setCategoria }) => {

    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const [datos_registro, setDatosRegistro] = useState([]);
    const [value1, setValue1] = useState(1);
    const [valor,setValor] = useState(0);
    const datos = JSON.parse(localStorage.getItem('usuario'));
    const onChange1 = (e) => {
      console.log('radio checked', e.target.value);
      setValue1(e.target.value);
    };
      console.log(datos.correo);
    const ref_form = React.createRef();

    const onFinish = async values => {

        localStorage.setItem('voto4', JSON.stringify(valor));

        console.log(values);
        values.tipo = categoria;
        values.correo = datos.correo;
        values.categoria4 = valor;

        setCargarPantalla(true);

        guardar_votos(values).then(({ data, status }) => {
            switch (status) {
                case 0:
                    mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu voto ha sido enviado' : 'Tu voto ha sido enviado', + data[3]);
                    setDatosRegistro(data);
                    break;
                case 1:
                    mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 2:
                    mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
                    break;
                default:
                    mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
                    break;
            }
            setCargarPantalla(false);
        });
        setCategoria(categoria+1);

    };


    const proyectos = [
        {id:1, nombre:'Torre Cervantes 251', desc:[<>La Torre Cervantes 251, que abarca un área de construcción de 14,951 m2, está situada en la calle Miguel de Cervantes Saavedra, número 251, entre las calles Moliere y Privada Cervantes. Limita al fondo con la Calle Ferrocarril de Cuernavaca, en la vibrante colonia Granada de la Ciudad de México. Este destacado proyecto arquitectónico incluye 14 niveles de oficinas, una planta baja comercial y estacionamiento subterráneo para la comodidad de sus ocupantes.<br /><br />
            El 9 de enero de 2024, la Torre Cervantes 251 alcanzó un hito significativo al obtener la prestigiosa certificación LEED nivel Oro, reflejo de su compromiso con la sostenibilidad y la innovación en el diseño.<br /><br />
            Desde el inicio, el diseño de la Torre Cervantes 251 se concibió como un fiel reflejo de la visión, los valores y la cultura de UrbanDimension, su propietario. La adaptabilidad a nuevos usos y la flexibilidad en sus procesos se establecieron como principios fundamentales, orientados por criterios de funcionalidad operativa, flexibilidad de uso e inclusividad. Asimismo, se priorizó la comodidad, la salud y la seguridad, junto con la eficiencia energética y la viabilidad económica, asegurando así un equilibrio óptimo entre costo y beneficio. La experiencia conceptual en todo el edificio y sus procesos se diseñó en torno al concepto de "hallazgo", creando un ambiente que estimula el descubrimiento y la innovación.<br /><br />
            Este enfoque integral, alineado con los estándares de diseño sostenible, coincide con los valores de la certificación LEED. Por ello, no sorprende que la obtención de esta certificación fuera uno de los objetivos primordiales establecidos por UrbanDimension, consolidando a la Torre Cervantes 251 como un referente en construcción sostenible y eficiencia en la Ciudad de México.</>],
            documento:proyecto_1_pdf,
        imagenes:[
            {indes:1, url:proyecto_1_img1,tipo:1},
            {indes:2, url:proyecto_1_img2,tipo:2},
            {indes:3, url:proyecto_1_img3,tipo:2},
            {indes:4, url:proyecto_1_img4,tipo:2},
            {indes:5, url:proyecto_1_img5,tipo:2},
        ],
        certificacion:'LEED BD+C: C&S v2009 nivel Oro'
    },
        {id:2, nombre:'Redwood', desc:[<>Redwood es un edificio de 12,380 m2 GFA (11,605 m2 rentables), ubicado en Zapopan, Jalisco. Cuenta con 11 pisos de oficinas corporativas, además de 1 piso de espacio comercial, azotea y áreas comunes en el nivel de lobby.<br /><br />
            Es parte del portafolio de 12 proyectos de FibraMTY que comenzaron su proceso de certificación LEED Operations and Maintenance en el año 2022, siendo el más reciente en lograr la certificación nivel Plata en diciembre de 2023.<br /><br />
            Durante el proceso de certificación, se demostró que Redwood ha sido operado constantemente bajo una gestión eficiente, aunado a la implementación de buenas prácticas que superan los estándares convencionales para este tipo de edificios. Entre los esfuerzos realizados, se destacó la importancia de verificar y monitorear el desempeño del día a día, con el propósito de garantizar el cumplimiento de los criterios y objetivos establecidos y así seguir operando de manera eficiente y sostenible.</>],
            documento:proyecto_2_pdf,
        imagenes:[
            {indes:1, url:proyecto_2_img1,tipo:1},
            {indes:2, url:proyecto_2_img2,tipo:2},
            {indes:3, url:proyecto_2_img3,tipo:2},
        ],
        certificacion:'LEED v4.1 Operations and Maintenance: Existing Buildings, Silver, diciembre 2023'
    },
        {id:3, nombre:'Corporativo Neuchatel', desc:[<>Describir el proyecto de forma general: área 93,659m2, ubicación (Ciudad de México, Rio San Joaquin 498, colonia Ampliación Granada, 11529, alcaldía Miguel Hidalgo), uso principal Oficinas Corporativas y fecha de certificación Septiembre 2023, así como cuáles fueron los motivos para buscar la certificación. Incluir una fotografía general del proyecto.<br /><br />
            Ivanhoe Cambridge y Hines como dueños y desarrollador hemos estado comprometidos con la sustentabilidad y Hines ha participado de cerca desde la concepción de la certificación LEED, por lo que para nosotros certificar nuestros proyectos es un compromiso con el medio ambiente.<br /><br />
            Corporativo Neuchatel es parte de Cuadrante Neuchâtel Polanco, un desarrollo de uso mixto ubicado en la zona de más rápido crecimiento de la ciudad, con fácil acceso peatonal y vehicular a múltiples servicios de la zona.<br /><br />
            Corporativo Neuchatel es el edificio de oficinas más moderno, eficiente y de alto desempeño de alto rendimiento, con 19 plantas de espacio de oficinas de Clase A+. Diseñado por la firma global HOK, Corporativo Neuchatel está redefiniendo el espacio de oficinas, con un enfoque en la hospitalidad, la eficiencia y el dinamismo. Construido con las más altas especificaciones arquitectónicas y estructurales, superando el código de construcción internacional en términos de especificaciones mecánicas, eléctricas y de seguridad.<br /><br />
            Corporativo Neuchatel ofrece servicios como una cafetería integrada y una biblioteca en el vestíbulo, un gimnasio para el uso exclusivo de los inquilinos con vestidores y casilleros, un centro de conferencias, espacio de coworking “The Square” operado por Hines, estacionamientos para bicicletas y estaciones de carga para vehículos eléctricos.</>],
            documento:proyecto_3_pdf,
        imagenes:[
            {indes:1, url:proyecto_3_img1,tipo:1},
            {indes:2, url:proyecto_3_img2,tipo:2},
            {indes:3, url:proyecto_3_img3,tipo:2},
        ],
        certificacion:'LEED 2009 C&S, Gold'
    },
        {id:4, nombre:'CHAPULTEPEC UNO R509', desc:[<>Ubicado en la CDMX, México, Chapultepec UNO R509 es uno de los rascacielos más altos e innovadores de América Latina, pues en sus más de 50 niveles muestra un diseño elegante y eficiente para resolver retos complejos dados por lo estrecho del terreno y la necesidad de incluir un edificio de usos mixtos para oficinas, hotel y vivienda ubicada en una zona sísmica, con requerimientos en sustentabilidad. Por otra parte su ubicación es privilegiada pues, al estar a pie de Paseo de la Reforma es posible tener una vista inmejorable a uno de los pulmones de la CDMX, el Bosque de Chapultepec. La solución de equipo liderada por Arquitectoma, Taller G, los especialistas y Bovis es un diseño único para este gran reto.<br /><br />
            En sus más de 65 000m2 Chapultepec UNO R509 busca ser un edificio en el cual se puedan inspirar nuevos proyectos para incorporar la sustentabilidad y LEED en proyectos no importa lo complejo que sean. Es por eso por lo que, tras 12 años de trabajo, en 2023 se consiguió la certificación LEED Gold, avalando estos esfuerzos y características que hacen de Chapultepec UNO R509 un proyecto icónico desde el punto de vista de sustentabilidad y LEED.</>],
            documento:proyecto_4_pdf,
        imagenes:[
            {indes:1, url:proyecto_4_img1,tipo:1},
            {indes:2, url:proyecto_4_img2,tipo:2},
            {indes:3, url:proyecto_4_img3,tipo:2},
            {indes:4, url:proyecto_4_img4,tipo:2},
            {indes:5, url:proyecto_4_img5,tipo:2},
        ],
        certificacion:'LEED – Core & Shell v2009 Gold 70 Puntos.'
    },
        {id:5, nombre:'Sonora 46', desc:[<>Describir el proyecto de forma general: área (m2), ubicación (ciudad, dirección), usos principales y fecha de certificación, así como cuáles fueron los motivos para buscar la certificación. Incluir una fotografía general del proyecto.<br /><br />
            El proyecto se desarrolla sobre una superficie de terreno total de 1,348.61 m2, Con frente hacia la Avenida Sonora entre Av. Durango y calle Sinaloa, colonia Roma Norte. Por su ubicación está a unas calles de Paseo de la Reforma, Avenida Chapultepec, colinda con la colonia Condesa y rodeado de vías de comunicación de primer orden.<br /><br />
            El edifico con uso de oficinas y comercio consta de: Planta Baja con dos locales comerciales, Planta Mezanine para uso de oficinas y vestíbulo principal de acceso, seis niveles de oficinas y una azotea con área ajardinada, seis sótanos para estacionamiento, bodegas y servicios. Todo ello genera una superficie aproximada de 16 000 m2 de construcción.<br /><br />
            El edificio fue certificado el día 23 de febrero del 2023 por parte del USGBC (U.S. Green Building Council) LEED BD+C: Core & Shell v4 nivel Oro.<br /><br />
            Al margen de formar parte de proyectos elite en el ámbito comercial inmobiliario de la zona central de México, la motivación de la certificación fue el reto de superar las propias expectativas del grupo buscando la síntesis de trabajo, diseño, eficiencia en un espacio pequeño con altos estándares y romper parámetros preestablecidos en la zona de influencia del proyecto a miras de motivar a la evolución de lo convencional y propiciando el mejora en desempeño y ahorro energético en beneficio del medio urbano.</>],
            documento:proyecto_5_pdf,
        imagenes:[
            {indes:1, url:proyecto_5_img1,tipo:1},
            {indes:2, url:proyecto_5_img2,tipo:2},
            {indes:3, url:proyecto_5_img3,tipo:2},
            {indes:4, url:proyecto_5_img4,tipo:2},
        ],
        certificacion:''
    },
        {id:6, nombre:'Puerta Polanco', desc:[<>Puerta Polanco es un edificio de oficinas compuesto de planta baja con zona comercial, doce niveles de oficinas y roof garden. Bajo nivel de banqueta cuenta con nueve sótanos para estacionamiento y el nivel de cisternas y planta de tratamiento.<br /><br />
            El proyecto se ubica en Blvd. Manuel Ávila Camacho 261 esquina con Ejército Nacional, colonia Polanco, Delegación Miguel Hidalgo, en la Ciudad de México. El edificio se desplanta sobre un predio que tiene una superficie de 6,426m2. El área construida y ocupada por Puerta Polanco es de 106,557m2 considerando 51,329m2 sobre el nivel de banqueta y 55,228m2 en sótanos.<br /><br />
            La configuración del edificio consta de plantas libres para oficinas, de entre 3,648m2 a 4,254m2, con dos núcleos que permiten el ingreso a cada nivel a través de 2 grupos de elevadores independientes, así como la distribución adecuada de servicios. La disposición de la planta permite recibir luz natural de la fachada y aprovechar las vistas a los puntos importantes de la ciudad. Gracias a las dimensiones del predio y al riguroso estudio del programa se lograron plantas de oficinas de gran eficiencia.<br /><br />
            En planta baja se encuentra el lobby de oficinas y el comercio. Sobre Blvd. Manuel Ávila Camacho se encuentra el acceso al pasaje comercial y a su gran terraza, la cual permitirá al visitante disfrutar de un oasis aislado del bullicio de los coches, flanqueado por un agradable muro con jardineras escultóricas.<br /><br />
            En el nivel de roof garden se encuentra un área destinada a salas de reuniones para uso de los inquilinos del edificio, estas ofrecen una configuración versátil gracias a particiones de muros móviles.<br /><br />
            El roof garden cuenta con una terraza que permite el disfrute de un área verde con café y con vistas inmejorables.<br /><br />
            El proyecto cuenta con un acceso vehicular sobre Ejército Nacional donde se distribuye el flujo de automóviles entre visitantes y usuarios del edificio. Quienes visitan el corporativo, toman el carril izquierdo de la rampa que lleva a los visitantes al Motor Lobby ubicado en Sótano 1. Los usuarios toman el carril derecho de la rampa que llevan hacia los Sótanos 2 al 9. La salida se ubica sobre Blvd. Manuel Ávila Camacho y la calle interna del edificio (que también funciona como drop-off) permite la incorporación a Ejército Nacional.<br /><br />
            Una de las estrategias para reducir el impacto vial generado por la densidad del programa fue habilitar en Sótano 1 un generoso motor lobby donde se hace la recepción y entrega de vehículos operados por valet parking, evitando la acumulación de vehículos sobre Blvd. Manuel Ávila Camacho y Ejército Nacional.<br /><br />
            El andén de carga se encuentra sobre Ejército Nacional y da servicio tanto al comercio como al corporativo.<br /><br />
            Respecto a las circulaciones verticales, el corporativo cuenta con 5 núcleos de escaleras de emergencia. Tres escaleras dan servicio a los niveles de oficinas terminando su recorrido en Planta Baja, otros dos bloques de escaleras atraviesan Sótanos hasta Planta Baja.<br /><br />
            El edificio tiene cinco tipos de elevadores: Elevadores de Sótanos que dan servicio a los estacionamientos en Sótanos y terminan en Planta Baja. Elevadores Vip, que llevan a los usuarios desde los sótanos hasta el nivel de oficina requerido sin transferir en PB. Mientras que para niveles de oficinas se cuenta con Elevadores con control de destino divididos en dos núcleos. Por último, están dos montacargas.</>],
            documento:proyecto_6_pdf,
        imagenes:[
            {indes:1, url:proyecto_6_img1,tipo:1},
            {indes:2, url:proyecto_6_img2,tipo:2},
        ],
            certificacion:'Certificación LEED Platinum'
        },
        {id:7, nombre:'FORUM NAUCALPAN II', desc:[<>Forum Naucalpan II obtuvo la certificación en el 2021 y se encuentra ubicado en el número 120 de la Avenida Primero de Mayo, colonia San Andrés Atoto, Estado de México, es un edificio nuevo de uso mixto con 29,926 m2 repartidos en 14 niveles de área útil. Cuenta con espacios para comercio en planta baja y nivel 1, así como 4 pisos de hotel en los niveles 10 al 13; los 9 niveles de sótano alojan 1,285 cajones de estacionamiento. En sus alrededores la zona cuenta con infraestructura existente: transporte público, red de calles, vías peatonales, comercios, servicios, electricidad, agua potable, gas y drenaje.<br /><br />
            El proyecto de está ubicado a 3.4km de Polanco / Lomas, museos, restaurantes, plazas comerciales, y a algunos minutos de Satélite es decir, con fácil acceso tanto al centro de negocios más prestigioso de México como a la fuerza laboral corporativa más importante de la ciudad.<br /><br />
            Forum Naucalpan es un oasis urbano con una ubicación cuidadosamente seleccionada que brindan una gama completa de servicios para la convivencia de los inquilinos corporativos y sus usuarios, entre ellos restaurantes, cafeterías, tienda de conveniencia, un gimnasio, zona de ATM’s y servicios comerciales, para asegurar la productividad, seguridad y bienestar de sus consumidores. Destaca por ubicarse en un zona desarrollada, lo cual evita la presión urbana hacia áreas naturales, protegiendo el hábitat de la fauna silvestre<br /><br />
            Durante el diseño se implementaron estrategias para lograr, mediante la integración de todos los involucrados, un edificio eficiente, inteligente y sostenible, cuyo interior brinda un espacio de trabajo saludable y confortable en beneficio de sus ocupantes, que disfrutan de mayores niveles de calidad de vida y productividad. Durante la construcción, se implementaron estrategias para reducir los impactos ambientales relacionados con cada una de las actividades constructivas.<br /><br />
            Entre los aspectos medioambientales relacionados con los edificios se incluyen las emisiones vehiculares y la necesidad de infraestructura para el automóvil. Las emisiones vehiculares contribuyen al cambio climático, causan smog, lluvia ácida y otros problemas de calidad del aire, y los estacionamientos y caminos incrementan el escurrimiento pluvial y contribuyen al efecto isla de calor. Forum Naucalpan II incentiva el uso de formas alternativas de transporte: su cercanía con áreas residenciales y una gran variedad de servicios comerciales promueve la caminata. Su amplio acceso al transporte público incentiva su utilización. Su estacionamiento preferencial para vehículos eficientes promueve el uso de automóviles de bajas emisiones.<br /><br />
            El uso de superficies oscuras para estacionamientos, techos y caminos, contribuyen al efecto isla de calor. Estas superficies absorben la radiación solar y después transfieren ese calor a las áreas circundantes, incrementando la temperatura del ambiente y del edificio, en detrimento del hábitat natural y requiriendo más energía para el enfriamiento interior del edificio. Al ubicar los cajones de estacionamiento en niveles subterráneos, instalar superficies reflejantes en la azotea del edificio y contar con vegetación en áreas exteriores, Forum Naucalpan II contribuye a mitigar el efecto isla de calor de su sitio y la ciudad. Con el estacionamiento subterráneo y su número de cajones limitado al mínimo requerido por el reglamento local, se optimiza también el uso del terreno, dando cabida a más áreas verdes.<br /><br />
            Forum Naucalpan II cuenta con estrategias para reducir, controlar y tratar el escurrimiento pluvial antes de que salga del sitio del proyecto: primero, el escurrimiento se reduce porque el estacionamiento es subterráneo y por la presencia de vegetación en planta baja y nivel 1. Después, el escurrimiento restante se dirige, mediante un sistema de pendientes en los exteriores y azotea, a un tanque de tormentas. Por último, el agua pluvial pasa por un filtro de grava y termina por juntarse con el agua saliente de la planta de tratamiento para su reúso.<br /><br />
            Típicamente, la mayoría del insumo de agua potable de un edificio sale del mismo en forma de agua residual. En países desarrollados, el agua potable suele llegar desde un sistema público de abastecimiento de agua, lejano al sitio del proyecto, y el agua residual suele dirigirse hacia una planta pública de tratamiento para luego ser descargada en un cuerpo de agua distante. Este sistema de by-pass reduce el flujo en los ríos de la zona y reduce los niveles acuíferos. Por otro lado, la energía requerida para potabilización, bombeo y tratamiento final representa una cantidad significativa de energía que se queda sin registrar en el medidor energético del edificio.<br /><br />
            Forum Naucalpan II ahorra un 50.19% de agua potable respecto a un edificio convencional, de dimensiones y ocupación similares. Esto se logra mediante las siguientes estrategias:<br /><br />
            1. Muebles de baño, regaderas y llaves de lavabos eficientes, con bajo consumo de agua.<br />
            2. Planta de tratamiento en sótano 10, con capacidad suficiente para tratar la totalidad del agua residual del edificio.<br />
            3. Reutilización de agua tratada en WCs y mingitorios.<br />
            4. Seleccióndeespeciesnativasoadaptadasparaáreasverdesdelproyecto, con baja necesidad de riego.
            5. Riego de áreas verdes exclusivo con agua pluvial y tratada.<br />
            En Forum Naucalpan se usaron estrategias de diseño enfocadas a reducir la necesidad energética, como la selección del vidrio y de ciertos materiales de construcción apropiados para el clima de la región. Después se redujo el consumo energético mediante un sistema de aire acondicionado que consiste en 2 chillers enfriados por agua de alta eficiencia, y se redujo la carga eléctrica para iluminación. Con lo anterior se logra un ahorro del 17.9% en el costo anual de energía del edificio (en comparación con un edificio de referencia). Los equipos de aire reciben un mantenimiento preventivo cada 4 meses.<br /><br />
            Para conocer y comprobar el consumo de energía tanto del edificio como de sus futuros inquilinos a lo largo del tiempo, Forum Naucalpan II cuenta con una red de medición electrónica monitoreada centralmente que se expande para adaptarse a la sub-medición de inquilinos.<br /><br />
            Los refrigerantes utilizados en el sistema de aire acondicionado del proyecto son libres de cloroflurocarbonos (CFC), respetando así el Protocolo de Montreal. Además, tienen bajo potencial de agotamiento de la capa de ozono (ODP) y bajo potencial de calentamiento global (GWP).<br /><br />
            Forum Naucalpan II fue sometido a un proceso riguroso de comisionamiento crítico para asegurar su alto desempeño, que consiste en asegurar que todos los sistemas y componentes energéticos del proyecto han sido diseñados, instalados y probados de acuerdo con los requerimientos del propietario y cumpliendo las normas y estándares nacionales e internacionales aplicables. Esto disminuye los problemas de mantenimiento a largo plazo.<br /><br />
            La selección de materiales juega un rol significativo en la edificación sostenible. Durante la vida útil de un material, su extracción, procesamiento, transporte, uso y destino final pueden tener consecuencias negativas como contaminación de agua y aire, destrucción de hábitats y agotamiento de recursos naturales. Forum Naucalpan II seleccionó cuidadosamente sus materiales, logrando los siguientes resultados:<br /><br />
            1. El 21% de los materiales con los que se construyó provienen del reciclaje, reduciendo el consumo de materiales vírgenes y la cantidad de residuos que llega a los rellenos sanitarios. Esto aumenta el mercado comercial para materiales reciclados.<br />
            2. El 67.48% de los materiales con los que se construyó fueron fabricados localmente, reduciendo los impactos de su transporte y apoyando a la economía local.<br />
            Reducir la generación de residuos es un componente importante en las prácticas de construcción sostenibles. Forum Naucalpan II implementó un sistema de manejo de residuos que permitió a los contratistas separar cada tipo de residuo, registrar su cantidad y decidir su disposición final, logrando desviar del relleno sanitario el 85% de los residuos totales generados durante la construcción.<br /><br />
            Cuenta con espacios especiales dedicados a la separación y almacenamiento temporal de los residuos reciclables que se generen en el edificio durante su operación, para facilitar a sus operadores y ocupantes la continuación de esta práctica sostenible.<br /><br />
            Forum Naucalpan II cuenta con un sistema de ventilación inteligente para renovar el airea de todos sus espacios, excediendo en un 30% las tasas mínimas publicadas por ASHRAE; ha implementado una política interna que prohíbe fumar en todos los espacios interiores del edificio y también en los espacios exteriores que se encuentren a una distancia menor de 8 metros de cualquier puerta, ventana operable o toma de aire exterior, con el fin de realmente contar con un espacio 100% libre de humo de tabaco.<br /><br />
            Para evitar los compuestos orgánicos volátiles (COV), en Forum Naucalpan se seleccionaron exclusivamente productos de bajas emisiones (adhesivos, selladores, pinturas, recubrimientos y sistemas de revestimientos para pisos), impactando positivamente en la calidad del aire al interior del edificio.<br /><br />
            En el inmueble se implementó una estrategia de diseño integrado para maximizar el nivel de iluminación natural y las vistas al exterior de sus espacios interiores, al tiempo que se mantiene y mejora el desempeño energético del edificio, resultando en que un 91% de los mismos tienen vistas al exterior.<br /><br />
            Algunos de las estrategias sostenibles de Forum Naucalpan II resultaron en un desempeño que excede lo requerido por LEED. Por sobrepasar los requerimientos y lograr resultados sobresalientes, se obtuvo desempeño ejemplar en los siguientes temas:<br /><br />
            1. Acceso a transporte público: se sobrepasa por cuatro veces el número de viajes requeridos.<br />
            2. Reducción del efecto isla de calor: el 100% de los cajones de estacionamiento son subterráneos.<br />
            3. Reducciónenelconsumodeagua:seahorramásdel50%delaguapotable total.<br /><br />
            Por otro lado, la siguiente estrategia de Forum Naucalpan II fue acreditada con un punto por considerarse una innovación:<br /><br />
            1. Se implementó un programa de educación verde que, mediante señalética integrada en los espacios del edificio y la elaboración de un caso de estudio, proporciona información a los ocupantes y visitantes del proyecto acerca de los beneficios de los edificios verdes en general y de este proyecto en particular.
            </>],
            documento:proyecto_7_pdf,
        imagenes:[
            {indes:1, url:proyecto_7_img1,tipo:1},
            {indes:2, url:proyecto_7_img2,tipo:2},],
            certificacion:'LEED BD+C: CORE AND SHELL V3 – LEED 2009 Nivel ORO'
        },
    ];

    const ver_proyectos = proyectos.map((item) => {
        return(
            <>
            <Row className='contenedor_pryecto'>
                <Col  xs={22} lg={8}>
                <Row justify={'center'}>
                        {item.imagenes != null ?
                                <>
                                    {item.imagenes.map(image => {
                                        if (image.indes === 1) {
                                            return (
                                                <>
                                                    <Col span={24}>
                                                        <Image
                                                            src={image.url}
                                                            className='img_grande'
                                                            placeholder={
                                                                <Image
                                                                    preview={false}
                                                                    src={image.url}
                                                                    style={{ width: '80%' }}
                                                                />}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <>
                                                    <Col span={5}>
                                                        <br />
                                                        <Image
                                                            className='img_peque'
                                                            src={image.url}
                                                            placeholder={
                                                                <Image
                                                                    preview={false}
                                                                    src={image.url}
                                                                    style={{ width: '80%' }}
                                                                />}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        }
                                    })}</>
                                :
                                <></>}
                        </Row>
                </Col>
                <Col  xs={22} lg={16}>
                    <p className='titulo_proyecto'>{item.nombre}</p>
                    <p className='desc_proyecto'><strong>Certificación: </strong>{item.categoria}</p>
                    <p className='desc_proyecto'>{item.desc}</p>
                    <Row>
                    <Col xs={0} lg={0}><br />
                            <p>
                            <Link className='btn_proyecto' to={item.documento} target='_blank'>Ver proyecto completo </Link>
                        </p>
                            </Col>
                            <Col xs={24} lg={12}><br />
                        <span className={valor !== 0 ? (valor === item.id ? 'btn_votar_select' : 'btn_votar') : 'btn_noselect'} onClick={() => setValor(item.id)}>
                        SELECCIONAR
                        </span>
                            </Col>
                        </Row>
                </Col>
            </Row>
            </>
        )
    });
    return (
        <>
            <Row justify={'center'}>
                        <Col span={20}>
                            <Form className="login-form"
                                layout="vertical"
                                name="basic"
                                onFinish={onFinish}
                                ref={ref_form}
                            >
                                <Row>
                                    <Col span={24}><br /><br />
                                        <p className='titulo_objeticos color_azul fuente' style={{textAlign:'center'}}>EDIFICIOS DE USOS MIXTOS</p>
                                    </Col>
                                    <Col span={24}>
                                        {ver_proyectos}
                                    </Col>
                                    <Col span={24}>
                                                <Form.Item>
                                                <br /><br />
                                                    <p style={{ textAlign: 'center', display: 'non' }}>
                                                        <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType="submit">
                                                            <p className="btn_registrate_home"><strong>ENVIAR VOTO</strong></p>
                                                        </button>
                                                    </p>

                                                </Form.Item>

                                            </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
        </>
    )
}
export default Mixtos;
import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Upload, Checkbox, Button, notification, Spin, Radio, Image } from 'antd';
import '../../assets/css/proyectos.css';

import { Link } from 'react-router-dom';
import Firebase from '../../utils/firebaseConfig';
import { guardar_votos } from '../../utils/ConexionServidor';
import Item from 'antd/lib/list/Item';

import proyecto_1_pdf from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/PREMIOS_IMPULSO_TORRE_SANTA_FE.pdf';
import proyecto_1_img1 from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/1.jpg';
import proyecto_1_img2 from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/2.jpg';
import proyecto_1_img3 from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/3.jpg';

import proyecto_2_pdf from '../../assets/proyectos/14 CORPORATIVO VIA MAGNA/Premios SUME Vía Magna (1).pdf';
import proyecto_2_img1 from '../../assets/proyectos/14 CORPORATIVO VIA MAGNA/1.jpg';
import proyecto_2_img2 from '../../assets/proyectos/14 CORPORATIVO VIA MAGNA/2.jpg';

import proyecto_3_pdf from '../../assets/proyectos/15 HOMERO 1500/Premios SUME Homero 1500 rev 1.pdf';
import proyecto_3_img1 from '../../assets/proyectos/15 HOMERO 1500/1.jpg';
import proyecto_3_img2 from '../../assets/proyectos/15 HOMERO 1500/2.jpg';

import proyecto_4_pdf from '../../assets/proyectos/18 Torre Reforma 115/18TorreReforma.pdf';
import proyecto_4_img1 from '../../assets/proyectos/18 Torre Reforma 115/1.jpg';
import proyecto_4_img2 from '../../assets/proyectos/18 Torre Reforma 115/2.jpg';

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const Comerciales = ({ history, onResponse, onResponseCerrarLogin, idioma, categoria, setCategoria }) => {

    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const [datos_registro, setDatosRegistro] = useState([]);
    const [value1, setValue1] = useState(1);
    const [valor, setValor] = useState(0);
    const datos = JSON.parse(localStorage.getItem('usuario'));
    const enviado = 0;
    const onChange1 = (e) => {
        console.log('radio checked', e.target.value);
        setValue1(e.target.value);
    };
    console.log(datos.correo);
    const ref_form = React.createRef();
    console.log('voto1',localStorage.getItem('voto1'));
    const onFinish = async values => {

        //enviado(localStorage.setItem('voto1', JSON.stringify(valor)));

        console.log(values);
        values.tipo = categoria;
        values.correo = datos.correo;
        values.categoria1 = valor;

        setCargarPantalla(true);

        guardar_votos(values).then(({ data, status }) => {
            switch (status) {
                case 0:
                    mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu voto ha sido enviado' : 'Tu voto ha sido enviado', + data[3]);
                    setDatosRegistro(data);
                    break;
                case 1:
                    mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 2:
                    mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
                    break;
                default:
                    mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
                    break;
            }
            setCargarPantalla(false);
        });
        setCategoria(categoria + 1);

    };
    /*useEffect(() => {
        setValor(enviado);
    }, []);*/


    const proyectos = [
        {
            id: 1, nombre: 'TORRE SANTA FE, Chihuahua, Chihuahua, México', desc: [<>Torre corporativa en la zona financiera en la ciudad de Chihuahua, Chihuahua, se logró tener un impacto visual reflejando el Nuevo Chihuahua... con una certificacíón LEED ORO, core and Shell.... Para tener un ahorro energético de alto impácto, diseñada con una fachada expuesta hacia el norte, utilizando las circulaciones verticales como una fachada ventilada hacia el sur, logra mantener un ahorro MUY importante en energía, provocando un comfort en el 80% de sus fachadas sin exposición al sol...</>],
            documento: proyecto_1_pdf,
            imagenes: [
                { indes: 1, url: proyecto_1_img1, tipo: 1 },
                { indes: 2, url: proyecto_1_img2, tipo: 2 },
                { indes: 3, url: proyecto_1_img3, tipo: 2 },
            ],
            certificacion:'LEED ORO 2023'
        },
        {
            id: 2, nombre: 'Corporativo Vía Magna',
            desc: [<>Via Magna, un edificio de uso Multi Tenant con una superficie de 28,676 m2, se encuentra ubicado en Av. Vía Magna número 5, Interlomas, Huixquilucan, CDMX. Este destacado inmueble, que obtuvo la certificación LEED el 16 de agosto de 2021, demuestra su compromiso con prácticas sostenibles y ambientalmente responsables.<br /><br />
                Funcionalmente está construido en 7 niveles de oficinas más planta baja, donde incluye vestíbulo general de acceso y oficinas, 4 sótanos para estacionamientos con un total de 536 cajones vehiculares y 26 espacios para motocicletas, cuenta con Distintivo “A”, enfocado a accesibilidad Universal e Inclusión.
            </>],
            documento: proyecto_2_pdf,
            imagenes: [
                { indes: 1, url: proyecto_2_img1, tipo: 1 },
                { indes: 2, url: proyecto_2_img2, tipo: 2 },
            ],
            cetificacion:'LEED v4 BD+C: CS- Certified'
        },
        {
            id: 3, nombre: 'Homero 1500',
            desc: [<>Homero 1500, con una extensión de 7,262 m2 y estratégicamente ubicado en Av. Homero 1500, Col. Polanco, CDMX, ha alcanzado un hito significativo al obtener la certificación LEED Gold en Operación y Mantenimiento (O+M) en enero del 2023. Este reconocimiento destaca el compromiso del edificio con prácticas operativas eficientes y sostenibles, consolidándolo como un referente en la Colonia Polanco.<br /><br />
                Además, Homero 1500 implementa diversos programas y políticas sustentables que contribuyen a la protección del medio ambiente y el bienestar de sus ocupantes.</>],
            documento: proyecto_3_pdf,
            imagenes: [
                { indes: 1, url: proyecto_3_img1, tipo: 1 },
                { indes: 2, url: proyecto_3_img2, tipo: 2 },
            ],
            certificacion:'LEED V4 O+M: EB- ORO'
        },

        {
            id: 4, nombre: 'Torre Reforma 115',
            desc: [<>Torre Reforma 115 se encuentra ubicado en la intersección de Paseo de la Reforma y el anillo Periférico en la Ciudad de México, cuenta un con área de 26.800 m2 de superficie rentable dedicado a oficinas. Este icónico proyecto construido en 2005 tiene 27 niveles de oficinas, y cuenta con estacionamientos subterráneos para comodidad de sus ocupantes.<br /><br />
                El diseño de las oficinas tuvo un enfoque de ser provisto con los mejores servicios de tecnología por lo que, su desempeño a través de los años ha buscado mantenerse dentro de los más altos estándares, obteniendo así la primera de certificación LEED 2009 O+M obtenida en 2018, ganando el nivel Gold. Su camino a través del tiempo demuestra su compromiso con la sustentabilidad y el impacto de los ocupantes, realizando esfuerzos por mantener la confianza de sus clientes a través de entender las dinámicas que generó la pandemia del Coronavirus, por lo cual buscó la certificación WELL Health-Safety obtenida en 2021 y recertificada en 2023. Actualmente se encuentra en proceso de obtener la recertificación el LEED O+M versión 4.1 donde estaría alcanzando nivel Oro (en review).<br /><br />
                Adicionalmente, Torre Reforma 115 cuenta con un diseño elegante, innovador y vanguardista como pocos otros edificios en la ciudad. Sus usuarios gozan de los mejores servicios tecnológicos, de vistas panorámicas inigualables y de excelentes espacios abiertos que se integran armónicamente con el entorno de Paseo de la Reforma. Es por esto que Torre Reforma 115 se destaca por su visión integral, entendiendo que el valor agregado de sus espacios se encuentra en el beneficio a sus ocupantes a través del confort, la sustentabilidad ambiental y la viabilidad económica.</>],
            documento: proyecto_4_pdf,
            imagenes: [
                { indes: 1, url: proyecto_4_img1, tipo: 1 },
                { indes: 2, url: proyecto_4_img2, tipo: 2 },],
                certificacion:'LEED O+M v2009 y LEED O+M v4.1'
        }
    ];

    const ver_proyectos = proyectos.map((item) => {
        return (
            <>
                <Row className='contenedor_pryecto'>
                    <Col xs={22} lg={8}>
                        <Row justify={'center'}>
                        {item.imagenes != null ?
                                <>
                                    {item.imagenes.map(image => {
                                        if (image.indes === 1) {
                                            return (
                                                <>
                                                    <Col span={24}>
                                                        <Image
                                                            src={image.url}
                                                            className='img_grande'
                                                            placeholder={
                                                                <Image
                                                                    preview={false}
                                                                    src={image.url}
                                                                    style={{ width: '80%' }}
                                                                />}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <>
                                                    <Col span={5}>
                                                        <br />
                                                        <Image
                                                            className='img_peque'
                                                            src={image.url}
                                                            placeholder={
                                                                <Image
                                                                    preview={false}
                                                                    src={image.url}
                                                                    style={{ width: '80%' }}
                                                                />}
                                                        />
                                                    </Col>
                                                </>
                                            );
                                        }
                                    })}</>
                                :
                                <></>}
                        </Row>
                    </Col>
                    <Col xs={22} lg={16}>
                        <p className='titulo_proyecto'>{item.nombre}</p>
                        <p className='desc_proyecto'><strong>Certificación: </strong>{item.categoria}</p>
                        <p className='desc_proyecto'>{item.desc}</p>
                        <Row>
                            <Col xs={0} lg={0}><br />
                            <p>
                            <Link className='btn_proyecto' to={item.documento} target='_blank'>Ver proyecto completo </Link>
                        </p>
                            </Col>
                            <Col xs={24} lg={12}><br />
                            <span className={valor !== 0 ? (valor === item.id ? 'btn_votar_select' : 'btn_votar') : 'btn_noselect'} onClick={() => setValor(item.id)}>
                            SELECCIONAR
                        </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    });
    return (
        <>
            <Row justify={'center'}>
                <Col span={20}>
                    <Form className="login-form"
                        layout="vertical"
                        name="basic"
                        onFinish={onFinish}
                        ref={ref_form}
                    >
                        <Row>
                            <Col span={24}><br /><br />
                                <p className='titulo_objeticos color_azul fuente' style={{textAlign:'center'}}>EDIFICIOS COMERCIALES</p>
                            </Col>
                            <Col span={24}>
                                {ver_proyectos}
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                <br /><br />
                                    <p style={{ textAlign: 'center', display: 'non' }}>
                                        <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType={"submit"}>
                                            <p className="btn_registrate_home"><strong>ENVIAR VOTO</strong></p>
                                        </button>
                                    </p>

                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
export default Comerciales;
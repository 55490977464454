import React, { useState } from 'react';
import { Row, Col, Carousel, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import '../../assets/css/agenda.css';

import miercoles from '../../assets/images/sitio/Botones/Sinergia_Boton_23noviembre.png';
import miercoles_hover from '../../assets/images/sitio/Botones/Sinergia_Boton_23noviembre_scroll.png';
import jueves from '../../assets/images/sitio/Botones/Sinergia_Boton_24noviembre.png';
import jueves_hover from '../../assets/images/sitio/Botones/Sinergia_Boton_24noviembre_scroll.png';

import Slider from 'react-slick';


const Agenda = (props) => {
  const [ver_dia, setVerDia] = useState(1);
  const { idioma } = props;

  const dias = [
    {
      hora: '08:00', tipo: 1, actividad: 'Registro', titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [''],
      text: [''], text_en: [''],
      actividad_en: 'Registration', titulo_en: '', descripcion_en: [<></>]
    },
    {
      hora: '09:00', tipo: 1, actividad: 'Inauguración', titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [''],
      text: [''], text_en: [''],
      actividad_en: 'Inauguration', titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '10:00', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Conferencia Magistral</strong></span> <br />Los Retos del Cambio Climático y Compromisos de las Empresas.</>], nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [<>
        <strong>Ricardo Bussey</strong> - JOHNSON CONTROLS<br />
        <strong>Peter Templeton</strong> - USGBC/GBCI</>
        ],
      text: ['En esta conferencia magistral se explorarán los desafíos del cambio climático y el poder de la acción corporativa. Con la presencia de Peter Templeton, Presidente y CEO del USGBC, y Ricardo Bussey, Presidente de la Mesa Directiva de SUMe 2023-2025 y Director de Relaciones Gubernamentales, Sostenibilidad y Asuntos Públicos LATAM & Global Community Affairs de Johnson Controls, esta sesión promete ser una fusión de visiones y estrategias, donde se discutirán planteamientos y retos sobre la edificación sustentable y la certificación LEED. Se abordará cómo las estrategias ESG de las empresas son cruciales para combatir el cambio climático, como los edificios sustentables desde una perspectiva sistémica, no solo ahorran dinero y protegen el planeta, sino que también crean entornos más saludables y felices para todos.'],
      text_en: [<>This keynote will explore the challenges of climate change and the power of corporate action. With the presence of Peter Templeton, President and CEO of the USGBC, and Ricardo Bussey, Chairman of the Board of Directors of SUMe 2023-2025 and Director of Government Relations, Sustainability and Public Affairs LATAM & Global Community Affairs of Johnson Controls, this session promises to be a fusion of visions and strategies, where approaches and challenges on sustainable building and LEED certification will be discussed. It will address how companies' ESG strategies are crucial to combating climate change, as sustainable buildings from a systemic perspective, not only save money and protect the planet, but also create healthier and happier environments for all. </>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Keynote Lecture</strong></span><br />Climate Change Challenges and Business Commitments.</>], descripcion_en: ['']
    },
    {
      hora: '11:00', tipo: 1, actividad: [<><span className='color_verde'>Presentación</span><br />Patrocinador Oro</>], titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [''],
      text: [''], text_en: [''],
      actividad_en: [<><span className='color_verde'>Presentation</span><br />Gold Sponsor</>], titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '11:15', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 1:</strong></span><br />Retos y Oportunidades para Implementar la Sustentabilidad en el Sector Industrial (Nearshoring).</>], nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [<>

        <strong>Dario Ibargüengoitia</strong> - IBALCA<br />
        <strong>Javier Macías</strong> - HINES<br />
        <strong>Rodolfo Morales</strong> - FINSA<br />
        <strong>Paola Naciff</strong> - FIBRA TERRAFINA

      </>],
      text: [<>El Nearshoring está redefiniendo el panorama industrial de nuestro país, impulsando el crecimiento económico y la expansión de la edificación sustentable en México. En un momento en que la transformación industrial se acelera, las empresas están abrazando el cambio, motivadas por la demanda de los consumidores por prácticas más sostenibles y la urgencia de combatir el cambio climático. El objetivo es claro: alcanzar cero emisiones para 2050 o antes. Organizaciones visionarias están trazando caminos innovadores hacia la sostenibilidad, delineando estrategias de descarbonización y reducción de emisiones que están marcando la diferencia. En este panel, tendremos el privilegio de escuchar de primera mano a expertos en el desarrollo de parques industriales, que compartirán sus experiencias y avances, revelando cómo están superando los desafíos actuales para convertir la teoría en práctica tangible. Prepárate para una sesión que no solo te informará, sino que te motivará a ser parte del cambio imprescindible hacia la creación de un país próspero y con un futuro posible.</>],
      text_en: [<>Nearshoring is redefining the industrial landscape of our country, driving economic growth and the expansion of sustainable building in Mexico. At a time when industrial transformation is accelerating, companies are embracing change, motivated by consumer demand for more sustainable practices and the urgency to combat climate change. The goal is clear: to reach zero emissions by 2050 or sooner. Visionary organizations are charting innovative paths to sustainability, outlining decarbonization and emissions reduction strategies that are making a difference. In this panel, we will have the privilege of hearing first-hand from experts in industrial parks´ development, who will share their experiences and advancements, revealing how they are overcoming current challenges to turn theory into tangible practice. Get ready for a session that will not only inform you but motivate you to be part of the essential change towards the creation of a prosperous country with a possible future.</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 1:</strong></span><br />Challenges & Opportunities to Implement Sustainability in the Industrial Sector (Nearshoring).</>], descripcion_en: ['']
    },
    {
      hora: '12:15', tipo: 1, actividad: 'Receso', titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: 'Recess', titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '12:30', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 2</strong></span><br /><strong>Navegando la Economía de Transición:</strong><br />Claves para un Portafolio Inmobiliario Exitoso.</>], nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [<>

        <strong>Alicia Silva</strong> - REVITALIZA CONSULTORES<br />
        <strong>Chris Pyke</strong> - GRESB
      </>],
      text: ['En el contexto de una economía global que prioriza la acción climática, la creación de portafolios inmobiliarios exitosos exige ahora, más que nunca, establecer metas ambiciosas y herramientas precisas para benchmarks y mejoras continuas. La sostenibilidad se ha convertido en el eje central del negocio, y la regulación cada vez más estricta, está redibujando el mapa de los flujos de inversión. Los activos sostenibles están llamados a evolucionar, a fin de marcar un nuevo paradigma para nuestra industria. En este panel, contaremos con la perspicacia del Dr. Chris Pyke, Chief Innovation Officer en GRESB, para profundizar en la urgencia de adoptar prácticas que superen las expectativas regulatorias y financieras. Ahondaremos en cómo la transición hacia una economía baja en carbono ofrece oportunidades sin precedentes y también desafíos significativos para el sector inmobiliario.'],
      text_en: ['In the context of a global economy that prioritizes climate action, the creation of successful real estate portfolios requires now, more than ever, to set ambitious goals and precise tools for benchmarking and continuous improvement. Sustainability has become the backbone of the business, and increasingly stringent regulation is redrawing the map of investment flows. Sustainable assets are called to evolve, in order to set a new paradigm for our industry. In this panel, we will have the insight of Dr. Chris Pyke, Chief Innovation Officer at GRESB, to delve into the urgency of adopting practices that exceed regulatory and financial expectations. We will delve into how the transition to a low-carbon economy offers unprecedented opportunities and also significant challenges for the real estate sector.'],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 2</strong></span><br /><strong>Navigating the Transition Economy:</strong><br />Keys to a Successful Real Estate Portfolio.</>], descripcion_en: ['']
    },
    {
      hora: '13:30', tipo: 1, actividad: 'Lunch', titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: 'Lunch', titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '14:30', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Conferencia Magistral:</strong></span><br /><strong>LEED v5:</strong><br /> Alineándose al Futuro.</>], nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [<>
      <strong>Ulises Treviño</strong> - BEA<br />
        <strong>Melissa Baker</strong> - USGBC
      </>],
      text: ['Esta conversación revelará la evolución en el futuro de la sostenibilidad y certificaciones de edificios en operación.  Melissa Baker, Vicepresidenta Ejecutiva del USGBC,  nos platicará sobre la evolución para llegar a LEED v5. Esta nueva versión de LEED no es solo una actualización; es una reiteración audaz que se alinea a los cambios en el mundo, uniendo nuestros espacios vitales con la promesa de un futuro próspero y de bajas emisiones de carbono. LEED v5 se erige como una nueva guía de acción, abordando desafíos críticos como la justicia social, la salud pública, la preservación de ecosistemas y la fortaleza ante adversidades. Esta iteración es el fruto de intensos diálogos y compromisos con la comunidad global LEED, y se establece sobre el consenso colectivo de que el cambio no es solo necesario, sino urgente. En esta conferencia, serás testigo de cómo LEED v5 representa nuestras aspiraciones más nobles y se convierte en el estándar de oro para tomar decisiones comerciales conscientes, transformando el entorno construido en un aliado en nuestra lucha colectiva por un mañana sostenible. A través de estrategias visionarias y prácticas pioneras, LEED v5 se alinea con las acciones que demanda nuestro tiempo, ofreciendo no solo una certificación, sino un compromiso tangible con el planeta y sus habitantes.'],
      text_en: [<>This conversation will reveal the evolution in the future of green building certifications in operations. Melissa Baker, Executive Vice President of USGBC, will talk about the evolution of LEED v5. This new version of LEED isn't just an update; It's a bold reiteration that aligns with changes in the world, uniting our living spaces with the promise of a prosperous, low-carbon future. LEED v5 stands as a new guide to action, addressing critical challenges such as social justice, public health, ecosystem preservation, and resilience in the face of adversity. This iteration is the result of intense dialogues and engagements with the global LEED community, and is built on the collective consensus that change is not only necessary, but urgent. In this conference, you will witness how LEED v5 represents our noblest aspirations and becomes the gold standard for making conscious business decisions, transforming the built environment into an ally in our collective struggle for a sustainable tomorrow. Through visionary strategies and pioneering practices, LEED v5 aligns with the actions our time demands, offering not just certification, but a tangible commitment to the planet and its inhabitants.</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Keynote</strong></span><br /><strong>LEED v5:</strong><br /> Aligning the Future.</>], descripcion_en: ['']
    },
    {
      hora: '15:30', tipo: 1, actividad: 'Coffee Break', titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: 'Coffee Break', titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '15:45', tipo: 1, actividad: [<><span className='color_verde'>Presentación</span><br />Patrocinador Oro</>], titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>Presentation</span><br />Gold Sponsor</>], titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '16:00', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 3</strong></span><br /><strong>Optimización Energética a Gran Escala:</strong><br />Estrategias para Portafolios Sostenibles.</>], nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [<>

        <strong>Jesús Galván</strong> - CBRE<br />
        <strong>Mauricio Ramírez</strong> - BOVIS<br />
        <strong>Jonathan Landry</strong> - CHAAC I+N+C<br />
        <strong>Marcela Heredia</strong> - CMIC CDMX <br />
        <strong>Ian de la Garza</strong> - FINSOLAR

      </>],
      text: ['En un mundo donde el cambio climático es una realidad inminente, la optimización ambiental de un portafolio inmobiliario no es solo una meta, sino una necesidad apremiante para la supervivencia de nuestro planeta. Este panel es un punto de encuentro para los líderes que están redefiniendo la sostenibilidad en la industria inmobiliaria y quieren hacer la diferencia con la gestión de sus portafolios, así como para los asesores que quieren aportar mejores respuestas a los fondos que apoyan. Nos sumergiremos en el fascinante mundo de la regeneración, donde las acciones hablan más que las palabras, y donde la eficiencia se mide en el impacto real sobre nuestro entorno a muchos más niveles que solo pensar en energía. Exploraremos programas avanzados y estrategias integrales que van desde la innovación en la construcción hasta la implementación de energías renovables, pasando por la gestión inteligente y la optimización de recursos. Hoy más que nunca se necesita un compromiso con la verdad más allá del "greenwashing". Discutiremos cómo la tecnología, las auditorías energéticas, el diseño inteligente, y la capacitación del personal convergen en una sinfonía de operaciones que no solo reducen el consumo de energía y mitigan el riesgo climático, sino que también elevan la rentabilidad y la responsabilidad ambiental. Con casos de éxito que ilustran el camino y estrategias probadas que marcan la diferencia, este panel no solo te informará, sino que te inspirará a ser parte del cambio.'],
      text_en: ['In a world where climate change is an imminent reality, the environmental optimization of a real estate portfolio is not only a goal, but a pressing necessity for the survival of our planet. This panel is a meeting point for leaders who are redefining sustainability in the real estate industry and want to make a difference with the management of their portfolios, as well as for advisors who want to provide better answers to the funds they support. We will immerse ourselves in the fascinating world of regeneration, where actions speak louder than words, and where efficiency is measured in the real impact on our environment on many more levels than just thinking about energy. We will explore advanced programs and comprehensive strategies ranging from construction innovation to renewable energy implementation, smart management and resource optimization. Today, more than ever, a commitment to the truth beyond greenwashing is needed. We will discuss how technology, energy audits, smart design, and staff training converge into a symphony of operations that not only reduce energy consumption and mitigate climate risk, but also elevate profitability and environmental responsibility. With success stories that illustrate the path and proven strategies that make a difference, this panel will not only inform you, but inspire you to be part of the change.'],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 3</strong></span><br /><strong>Optimization Large-Scale Energy:</strong><br />Strategies for Sustainable Portfolios.</>], descripcion_en: ['']
    },
    {
      hora: '17:00', tipo: 1, actividad: [<><span className='color_verde'>Actividad</span><br />What Design Can Do</>], titulo: '', nombre: '', lugar: '', extra: '', dia: '1',
      descripcion: [<>
      <strong>Ricardo Lozano</strong> - WHAT DESIGN CAN DO<br />
      <strong>Joanna Ruiz Galindo</strong> - WHAT DESIGN CAN DO</>],
      text: ['Descubre cómo la creatividad y la innovación se entrelazan para forjar un futuro sostenible en la sesión interactiva presentada por What Design Can Do (WDCD), la plataforma global que está catalizando el cambio hacia una sociedad más sostenible, justa y equitativa a través de la lente del diseño. Desde sus raíces en Ámsterdam hasta su expansión global en ciudades que son centros neurálgicos de la creatividad como São Paulo, Ciudad de México, Delhi, Nairobi y Tokio, WDCD ha sido un estandarte del potencial transformador del diseño desde 2011. Con un espíritu pionero impulsado por creativos, WDCD se compromete con la misión de movilizar a la comunidad creativa global para que aporte soluciones innovadoras a los desafíos. Esta sesión invita a los agentes de cambio, líderes de pensamiento y creadores de todo el mundo a reunirse y reimaginar nuestro futuro colectivo. Te invitamos a sumergirte en una visión del mundo donde cada producto, cada proceso y cada práctica son rediseñados para eliminar el desperdicio y reducir nuestro impacto en el planeta. Un mundo donde la economía circular no es un concepto abstracto, sino una prioridad palpable, que garantiza un futuro más limpio, justo y seguro para todos.'],
      text_en: [<>Discover how creativity and innovation intertwine to forge a sustainable future in the interactive session presented by What Design Can Do (WDCD), the global platform that is catalyzing change towards a more sustainable, just and equitable society through the lens of design. From its roots in Amsterdam to its global expansion into creative hubs cities such as São Paulo, Mexico City, Delhi, Nairobi and Tokyo, WDCD has been a banner of design's transformative potential since 2011. With a pioneering spirit driven by creatives, WDCD is committed to the mission of mobilizing the global creative community to bring innovative solutions to challenges. This session invites changemakers, thought leaders, and creators from around the world to come together and reimagine our collective future. We invite you to immerse yourself in a vision of the world where every product, every process, and every practice is redesigned to eliminate waste and reduce our impact on the planet. A world where the circular economy is not an abstract concept, but a palpable priority, ensuring a cleaner, fairer and safer future for all.</>],
      actividad_en: [<><span className='color_verde'>Activity</span><br />What Design Can Do</>], titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '09:00', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 4</strong></span><br />El Mercado del Carbono en México.</>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>

        <strong>Severo López Mestre</strong> - ESPECIALISTA EN ENERGÍA<br />
        <strong>Ruth Corona</strong> - JLL<br />
        <strong>Carlos Sánchez</strong> - MEXICO2<br />
        <strong>Ricardo Torres Hernández</strong> - Subsecretario de Medio Ambiente del Estado de Querétaro
      </>],
      text: ['En esta sesión contaremos con los expertos que están al frente de la transición hacia un mercado de carbono eficaz en México. Con la guía de especialistas reconocidos, esta conferencia te otorgará una visión clara y precisa del panorama actual del mercado de carbono mexicano. A través de sus exposiciones detalladas, entenderás los mecanismos de operación, las políticas y regulaciones vigentes, y cómo los proyectos de reducción de emisiones están marcando la diferencia en sectores clave. Asistir a esta conferencia es asegurarte un conocimiento profundo y actualizado de cómo el mercado de carbono se mueve y evoluciona en México. Es una coyuntura única para obtener una perspectiva integral de las oportunidades, los retos y los beneficios que este mercado presenta para nuestro país y para el mundo.'],
      text_en: ['In this session we will feature the experts who are at the forefront of the transition to an effective carbon market in Mexico. With the guidance of renowned specialists, this conference will give you a clear and accurate view of the current landscape of the Mexican carbon market. Through their in-depth presentations, you will understand the operating mechanisms, policies and regulations in place, and how emission reduction projects are making a difference in key sectors. To attend this conference is to ensure an in-depth and up-to-date knowledge of how the carbon market moves and evolves in Mexico. It is a unique juncture to obtain a comprehensive perspective of the opportunities, challenges and benefits that this market presents for our country and for the world.'],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 4</strong></span><br />The Carbon Market in Mexico.</>], descripcion_en: ['']
    },
    {
      hora: '10:00', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 5</strong></span><br /><strong>Near Traveling:</strong><br />Destinos Verdes, Viajes Sostenibles.</>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>

        <strong>Daniela Castro</strong> - JWA <br />
        <strong>Antonio Cárdenas</strong> - FIBRA HOTEL<br />
        <strong>Janera Soerel</strong> - IWBI<br />
        <strong>Juan Carlos Pineda</strong> - HKS<br />
        <strong>David Burckle</strong> - Vereda & Co
      </>],
      text: ['Esta sesión te invita a sumergirte en el movimiento de Near Traveling en México, donde la industria de la hospitalidad en su conjunto está evolucionando. No se trata sólo de hoteles de lujo, sino de una transformación integral que abarca desde posadas encantadoras hasta complejos turísticos extravagantes, todos unidos por el compromiso de ofrecer nuevas experiencias que cautivan el alma sin comprometer el entorno natural. La excelencia en el servicio ahora se calibra por la capacidad de armonizar la comodidad con la conservación, protegiendo y destacando la belleza virgen de los destinos que hacen de México un edén para viajeros. Cada iniciativa en el sector de la hospitalidad que respeta la ecología de nuestro planeta sienta un precedente de respeto y asegura que la singularidad de nuestras joyas naturales y culturales se mantenga. Te invitamos a ser parte de este renacimiento del turismo que combina el lujo y la innovación con la ética ambiental, y que se proyecta como un modelo de inspiración para el mundo.'],
      text_en: [<>This session invites you to dive into the Near Traveling movement in Mexico, where the hospitality industry as a whole is evolving. It's not just about luxury hotels, but a comprehensive transformation that ranges from charming inns to extravagant resorts, all united by a commitment to offering new experiences that captivate the soul without compromising the natural environment. Excellence in service is now calibrated by the ability to harmonize comfort with conservation, protecting and highlighting the unspoiled beauty of the destinations that make Mexico an Eden for travelers. Every initiative in the hospitality sector that respects the ecology of our planet sets a precedent of respect and ensures that the uniqueness of our natural and cultural jewels is maintained. We invite you to be part of this renaissance of tourism that combines luxury and innovation with environmental ethics, and that is projected as a model of inspiration for the world.</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 5</strong></span><br /><strong>Near Traveling:</strong><br />Green Destinations, Sustainable Travel.</>], descripcion_en: ['']
    },
    {
      hora: '11:00', tipo: 1, actividad: 'Coffee Break', titulo: '', nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [''],
      actividad_en: 'Coffee Break', titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '11:15', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 6</strong></span><br /><strong>Arquitectura de Resiliencia:</strong><br />Atendiendo el Cambio Climático.</>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>
        <strong>Jachen Schleich</strong> - MINERGIE MÉXICO <br />
        <strong>Salvador Rivas</strong> - S* ARC ARCHITECTS<br />
        <strong>Juan Miguel Gutiérrez</strong> - CEMEX<br />
        <strong>Jerzy Wójcik</strong> - JWA
      </>],
      text: ['La arquitectura de resiliencia es un enfoque del diseño arquitectónico que busca crear estructuras y entornos capaces de resistir, adaptarse y recuperarse de manera efectiva ante perturbaciones, desastres naturales, y los impactos del cambio climático. Además de centrarse en diseñar y construir para minimizar daños, tiene como prioridad proteger a las personas y garantizar la continuidad de las actividades esenciales, además de preocuparse por la calidad de vida. En este panel se discutirán una serie de aspectos como: la ubicación del edificio, los materiales de construcción, los sistemas de infraestructura, la eficiencia energética, la adaptación al clima, la gestión del agua, además de la inclusión social, la participación comunitaria y la promoción de los espacios seguros y accesibles para todas las personas.'],
      text_en: ['Resilience architecture is an approach to architectural design that seeks to create structures and environments capable of effectively resisting, adapting, and recovering from shocks, natural disasters, and the impacts of climate change. In addition to focusing on designing and building to minimize damage, it makes it a priority to protect people and ensure the continuity of essential activities, as well as caring about quality of life. This panel will discuss a number of aspects such as: the location of the building, construction materials, infrastructure systems, energy efficiency, climate adaptation, water management, as well as social inclusion, community participation and the promotion of safe and accessible spaces for all people.'],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 6</strong></span><br /><strong>Resilience Architecture:</strong><br />Addressing Climate Change.</>], descripcion_en: ['']
    },
    {
      hora: '12:15', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Conferencia Magistral</strong></span><br /><strong>Infraestructura Resiliente y Reconstrucción Sostenible:</strong><br />El Despertar de Otis ante el Cambio Climático.</>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>
        <strong>Martha Delgado</strong> - Líder Ambiental y Exfuncionaria Pública
      </>],
      text: ['El 25 de octubre de 2023, el Huracán Otis de categoría 5 azotó la costa del pacifico mexicano dejando a su paso una gran devastación, que ha dejado grandes desafíos para la población. En esta Conferencia Magistral, la Mtra. Martha Delgado, una líder ambiental con reconocimiento mundial y casi tres décadas de experiencia, nos guiará a través de su visión para tras la destrucción del Huracán Otis, lograr una reconstrucción que repare, mejore, construya, e inspire. Acompáñanos a descubrir cómo podemos levantarnos más fuertes frente a la adversidad y asegurar un porvenir resiliente para nuestra gente y nuestro planeta. Se discutirán estrategias avanzadas para edificaciones que no solo resisten los embates de la naturaleza, sino que también prosperan en su presencia, asegurando la calidad de vida y la continuidad de las comunidades.'],
      text_en: [<>On October 25, 2023, Category 5 Hurricane Otis hit the Mexican Pacific coast, leaving in its wake great devastation, which has left great challenges for the population. In this Keynote Lecture, Martha Delgado, a world-renowned environmental leader with nearly three decades of experience, will guide us through her vision for a rebuild that repairs, improves, builds, and inspires in the aftermath of Hurricane Otis' destruction. Join us as we discover how we can rise stronger in the face of adversity and secure a resilient future for our people and our planet. Advanced strategies will be discussed for buildings that not only resist the onslaught of nature, but also thrive in its presence, ensuring the quality of life and continuity of communities.</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Keynote Lecture</strong></span><br /><strong>Resilient Infrastructure and Sustainable Reconstruction:</strong><br />Otis' Awakening to Climate Change.</>], descripcion_en: ['']
    },
    {
      hora: '13:15', tipo: 1, actividad: [<><span className='color_verde'>Presentación</span><br />Patrocinador Oro</>], titulo: '', nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>Presentation</span><br />Gold Sponsor</>], titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '13:30', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 7</strong></span><br />El Valor de la Biodiversidad en los Bienes Raíces del Futuro.</>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>

        <strong>Jessica Diaz</strong> - PIIMA<br />
        <strong>Maria Matamoros</strong> - ÁMBITO ARQUITECTURA Y SOSTENIBILIDAD<br />
        <strong>Yven Echeverria</strong> - ESPECIALISTA EN CAMBIO CLIMATICO <br />
        <strong>George Jaksch</strong> - BIODIVERSITY PARTNERSHIP MESOAMERICA<br />
        <strong>Marion Hammerl</strong> - GLOBAL NATURE FUND
      </>],
      text: ['La biodiversidad es uno de los activos más valiosos del mundo. El Foro Económico Mundial estimó que más del 50% del PIB mundial depende de los recursos naturales. El entorno construido, junto con la infraestructura adjunta, es responsable de aproximadamente el 80% de la pérdida de biodiversidad mundial. Esto representa una oportunidad para identificar estrategias de protección, promoción y restauración de la biodiversidad. Este panel constituirá una revisión de los criterios y requisitos básicos referidos al entorno construido y al sector inmobiliario que deben aplicarse para la protección, conservación y mejora de la biodiversidad. También se profundizará en la mitigación de los impactos sobre la biodiversidad con base en lineamientos y estándares internacionales. Presentará los recursos incorporados por el sistema de calificación LEED y el sistema de calificación SITES que pueden dirigir un trabajo tan importante.'],
      text_en: [<>Biodiversity is one of the world's most valuable assets. The World Economic Forum estimated that more than 50% of the world's GDP depends on natural resources. The built environment, along with the accompanying infrastructure, is responsible for approximately 80% of global biodiversity loss. This represents an opportunity to identify strategies that protect, promote and restore biodiversity. This panel will constitute a review of the basic criteria and requirements relating to the built environment and the real estate sector that must be applied for the protection, conservation and enhancement of biodiversity. The mitigation of impacts on biodiversity will also be deepened based on international guidelines and standards. It will present the resources incorporated by the LEED rating system and the SITES rating system that can drive such important work.</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 7</strong></span><br />The Value of Biodiversity in the Real Estate of the Future.</>], descripcion_en: ['']
    },
    {
      hora: '14:30', tipo: 1, actividad: 'Comida', titulo: '', nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: 'Lunch', titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '15:30', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 8</strong></span><br /><strong>Innovación y Sostenibilidad:</strong><br />La Revolución de los Materiales.</>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>
        <strong>Joaquina Niembro</strong> - UNIVERSIDAD PANAMERICANA<br />
        <strong>Maria Fernanda Aguirre</strong> - GBC CHILE <br />
        <strong>Alicia Carrillo</strong> - HOLCIM<br />
        <strong>Fernanda Guarro</strong> - 3M<br />
        <strong>Gabriela González</strong> - USG
      </>],
      text: ['En la vanguardia de la sostenibilidad, esta sesión de nuestro foro te invita a ser testigo de la revolución de los materiales que está redefiniendo la construcción y la arquitectura. Descubre cómo la innovación material está marcando el camino para reducir radicalmente las emisiones de CO2 en la industria y cómo puedes ser parte de este cambio transformador. Adéntrate en un mundo donde cada elemento, y componente no solo construye, sino que protege y hace una diferencia en la salud de nuestro entorno. Representantes de empresas líderes de la industria compartirán estrategias pioneras y casos de éxito que demuestran que un mundo de infraestructura sostenible no es un sueño lejano, sino una realidad emergente. No te pierdas esta oportunidad de sumergirte en las soluciones del futuro y de colaborar en la creación de entornos construidos que honren nuestro compromiso con el planeta. ¡Innova, inspira e incide en el legado que dejaremos a las próximas generaciones!'],
      text_en: [<>At the forefront of sustainability, this session of our forum invites you to witness the materials revolution that is redefining building and architecture. Find out how material innovation is leading the way to radically reduce CO2 emissions in industry and how you can be part of this transformative change. Immerse yourself in a world where every element and component not only builds but protects and makes a difference in the health of our environment. Representatives from industry-leading companies will share pioneering strategies and success stories that demonstrate that a world of sustainable infrastructure is not a distant dream, but an emerging reality. Don't miss this opportunity to dive into the solutions of the future and help create built environments that honor our commitment to the planet. Innovate, inspire and influence the legacy we will leave to the next generations!</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 8</strong></span><br /><strong>Innovation and Sustainability:</strong><br />The Materials Revolution.</>], descripcion_en: ['']
    },
    {
      hora: '16:30', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 9</strong></span><br /><strong>Infraestructura para las Comunidades del Futuro:</strong><br />Avanzando hacia la Sostenibilidad </>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>
      <strong>Heidi Valdez Vélez</strong> - Revitaliza Consultores<br />
      <strong>Carlos Monjaraz</strong> - KOVAL Desarrolladora<br />
      <strong>Tamara Weitzsäcker</strong> - Twin Dolphin<br />
      <strong>Daniela Contreras</strong> -  Municipio de San Pedro Garza García
        
      </>],
      text: ['Con más de 170 proyectos a nivel global, LEED para Ciudades y Comunidades del USGBC está revolucionando la forma en que se planifican, desarrollan y operan las comunidades para mejorar su sostenibilidad general y la calidad de vida de los residentes.  LEED para Ciudades y Comunidades ayuda a que los proyectos realicen un seguimiento creíble del progreso hacia los objetivos generales de sostenibilidad, y permite la comparación con otros en todo el mundo. LEED para Ciudades y Comunidades ayuda a los líderes locales y al sector privado a: Designar comparativas de desempeño contra estándares locales y globales, demostrar un compromiso con la sostenibilidad, la resiliencia y la equidad social, desarrollar una cultura de toma de decisiones basada en datos, transparencia y liderazgo y mejorar el nivel de vida y la calidad de vida en ciudades y comunidades. En esta conversación escucharemos como desarrolladoras y expertos en todo el mundo han utilizado este sistema de certificación como herramienta de transformación y catalizador para tomar mejores decisiones y más conscientes con el medio ambiente, permitiendo la incorporación de políticas, incentivos y programas para lograr un desempeño de alta sostenibilidad en sus proyectos.'],
      text_en: [<>With more than 170 projects globally, the USGBC's LEED for Cities and Communities is revolutionizing the way communities are planned, developed and operated to improve their overall sustainability and the quality of life for residents.  LEED for Cities and Communities helps projects credibly track progress toward overall sustainability goals, and allows for comparison with others around the world. LEED for Cities & Communities helps local leaders and the private sector to: Benchmark performance against local and global standards, demonstrate a commitment to sustainability, resilience, and social equity, develop a culture of data-driven decision-making, transparency, and leadership, and improve living standards and quality of life in cities and communities. In this conversation we will hear how developers and experts around the world have used this certification system as a tool for transformation and a catalyst to make better and more environmentally conscious decisions, allowing the incorporation of policies, incentives and programs to achieve high sustainability performance in their projects.</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 9</strong></span><br /><strong>Communities of the Future:</strong><br />Moving Toward Sustainability with LEED for Cities and Communities.</>], descripcion_en: ['']
    },
    {
      hora: '17:30', tipo: 1, actividad: [<><span className='color_verde'>Presentación</span><br />Patrocinador Oro</>], titulo: '', nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>Presentation</span><br />Gold Sponsor</>], titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '17:45', tipo: 1, actividad: '', titulo: [<><span className='color_verde'><strong>Sesión 10</strong></span><br />Estrategias de Financiamiento para Proyectos Sostenibles.</>], nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [<>
        <strong>Enrique Motolá</strong> - THREE CONSULTORÍA MEDIOAMBIENTAL<br />
        <strong>Ommid Saberi</strong> - IFC<br />
        <strong>Aidée Olmos</strong> - HSBC<br />
        <strong>Valeria Dagnino</strong> – CLIMATEBONDS<br />
        <strong>Andrés Bahamon</strong> – SANTANDER
      </>],
      text: ['Las nuevas regulaciones y las taxonomías emergentes están trazando el camino hacia inversiones más inteligentes y sostenibles. Con la introducción de la Taxonomía Europea que guía a los inversionistas hacia opciones más verdes y la contraparte mexicana que establece estándares similares, nos encontramos en el amanecer de una era donde financiar el cambio climático y la responsabilidad social más que una obligación; es una oportunidad para transformar.  Esta sesión, destacada por la participación de expertos de las finanzas, explorará las estrategias de financiamiento que no solo están disponibles en México, sino que también están redefiniendo la viabilidad de proyectos en sectores clave como la construcción, la energía renovable y el transporte. Aprenderemos cómo estas regulaciones financieras no solo demandan el cumplimiento de criterios ambientales, sino que también recompensan la innovación y el liderazgo sostenible. Únete a nosotros para descubrir cómo puedes capitalizar estas nuevas exigencias de financiamiento, transformando cada desafío en un escalón hacia el éxito sostenible y resiliente.'],
      text_en: [<>New regulations and emerging taxonomies are charting the path to smarter, more sustainable investments. With the introduction of the European Taxonomy guiding investors towards greener options and the Mexican counterpart setting similar standards, we are at the dawn of an era where financing climate change and social responsibility is more than an obligation; It's an opportunity to transform.  This session, highlighted by the participation of finance experts, will explore financing strategies that are not only available in Mexico, but are also redefining the viability of projects in key sectors such as construction, renewable energy, and transportation. We will learn how these financial regulations not only demand compliance with environmental criteria, but also reward innovation and sustainable leadership. Join us to discover how you can capitalize on these new funding demands, transforming every challenge into a stepping stone to sustainable and resilient success.</>],
      actividad_en: '', titulo_en: [<><span className='color_verde'><strong>Session 10</strong></span><br />Financing Strategies for Sustainable Projects.</>], descripcion_en: ['']
    },
    /*{
      hora: '18:45', tipo: 1, actividad: [<><span className='color_verde'>Recorrido</span><br />Casa Abierta Monte</>], titulo: '', nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>Tour</span><br />Casa Abierta Monte</>], titulo_en: '', descripcion_en: ['']
    },*/
    {
      hora: '18:00', tipo: 1, actividad: [<><span className='color_verde'><strong>Coctél</strong></span><br />Premios IMPULSO SUMe by Saint-Gobain.</>], titulo: '', nombre: '', lugar: '', extra: '', dia: '2',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>Cocktail</span><br />IMPULSO SUMe Awards by Saint-Gobain</>], titulo_en: 'SUMe International Awards Ceremony', descripcion_en: ['']
    },
    {
      hora: '09:00', tipo: 1, actividad: [<><span className='color_verde'>Tour Edificios LEED</span></>], titulo: 'Conoce los edificios más icónicos de la Ciudad de México que han alcanzado la certificación LEED.', nombre: '', lugar: '', extra: '', dia: '3',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>LEED Building Tour</span></>], titulo_en: 'Learn about the most iconic buildings in Mexico City that have  achieved LEED  certification.', descripcion_en: ['']
    },
    {
      hora: '09:00', tipo: 1, actividad: [<><span className='color_verde'>Curso Especial</span></>], titulo: [<>
        Aproveche la oportunidad de tomar el curso especial de la mano de expertos internacionales (Será impartido en Inglés)
        <span className='color_verde'></span></>], nombre: '', lugar: '', extra: '', dia: '3',
      descripcion: [''],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>True Zero Waste Course</span></>], titulo_en: 'Duration: 3 hours', descripcion_en: ['']
    },
    {
      hora: '', tipo: 1, actividad: [<><span className='color_verde'>Taller Presencial Sistema de Certificación TRUE Zero Waste</span></>], titulo: '', nombre: '', lugar: '', extra: '', dia: '3',
      descripcion: [<>
        <strong>Stephanie Barger</strong> - GBCI <br />
        <strong>Alan Martinez</strong> - Niagara Bottling
      </>],
      text: [''],
      text_en: [''],
      actividad_en: [<><span className='color_verde'>LEED FOR CITIES AND COMMUNITIES Workshop</span></>], titulo_en: '', descripcion_en: ['']
    },
    {
      hora: '10:45', tipo: 1, actividad: 'SESIÓN 3:', titulo: 'ELECTROMOVILIDAD: Transportación sostenible', nombre: '', lugar: '', extra: '', dia: '0',
      descripcion: [<>
        <p className='descripcion_agenda'>
          <span className='color_amarillo '></span> • Autos Sustentables: El mercado actual<br />
          <br /><br />
          {idioma === 'es' ? 'Moderador:' : 'Moderator:'} <br />
          <span className='color_amarillo '>Horacio Ramos</span> SIEMENS<br /><br />
        </p>
      </>],
      text: [''],
      text_en: [''],
      actividad_en: '', titulo_en: '', descripcion_en: ['']
    }
  ]

  let dias_vista = dias.filter(dia => {
    if (dia.dia == ver_dia) {
      return true;
    }
    return false;
  });

  let ver_actividades = dias_vista.map((seccion, index) => {
    if (seccion.tipo == 1) {
      return (
        <>
          <Row className="contenedor_actividad" justify='center' align='top'>
            <Col xs={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }} >
              <p className='hora_agenda fuente'><strong>{seccion.hora != '' ? <>{seccion.hora} h</> : ''}</strong></p>
            </Col>
            <Col xs={{ span: 17, offset: 0 }} md={{ span: 19, offset: 1 }} className=''>
              <div >
                <p className='actividad_agenda fuente'>
                  {idioma === 'es' ?
                    <>{seccion.actividad != '' ? <><strong>{seccion.actividad}</strong><br /></> : <></>}
                      {seccion.titulo != '' ? <>{seccion.titulo}</> : <></>}</>
                    :
                    <>{seccion.actividad_en != '' ? <><strong>{seccion.actividad_en}</strong><br /></> : <></>}
                      {seccion.titulo_en != '' ? <>{seccion.titulo_en}</> : <></>}</>}
                </p>
                <p className='descripcion_agenda fuente'>
                  {idioma === 'es' ?
                    <>{seccion.descripcion}</>
                    :
                    <>{seccion.descripcion_en}</>}
                </p>
                <p className='texto_agenda fuente'>
                  {idioma === 'es' ?
                    <>{seccion.text}</>
                    :
                    <>{seccion.text_en}</>}
                </p>
              </div>
            </Col>
          </Row>
        </>
      )
    } else if (seccion.tipo === 2) {
      return (
        <>
          <Row className="contenedor_actividad" justify='center' align='middle'>
            <Col xs={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }} >
              <p className='hora_agenda fuente'><strong>{seccion.hora} h</strong></p>
            </Col>
            <Col xs={{ span: 17, offset: 0 }} md={{ span: 19, offset: 1 }} className=''>
              <div >
                <p className='actividad_agenda_2 fuente'>
                  {seccion.actividad}</p>
              </div>
            </Col>
          </Row>
        </>
      )
    }
  })


  return (
    <>
      <Row justify='center' className='contenedor_agenda_back'>
        <Col xs={22} md={20} className="">
          <p className='titulo_objeticos color_blanco fuente'>
            {idioma == 'es' ? 'PROGRAMA DEL EVENTO' : 'EVENT SCRENDULE'}
          </p>
          {idioma === 'es' ?
            <p className='subtitulo_agenda_2 color_blanco fuente'>El programa de conferencias de Construyendo el Futuro aborda los principales temas relacionados con <br />la edificación sustentable y ha sido preparado por un grupo multidisciplinario de expertos en la materia.</p>
            :
            <p className='subtitulo_agenda_2 color_blanco fuente'>The Building the Future conference program addresses the main topics related to <br />sustainable building and has been prepared by a multidisciplinary group of experts in the field.</p>
          }
        </Col>
        <Col xs={22} md={20}>
          <Row justify="center" align="middle" style={{ backgroundColor: 'rgba(151, 182, 35, 0)' }}>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} onClick={() => { setVerDia(1) }} className={ver_dia === 1 ? 'dia_select' : 'dia_select_no'}>
              <Row justify="center" align="middle">
                <Col xs={0} md={10}>
                  <p className='fecha_dia'>{idioma === 'es' ? 'Día 1' : 'Day 1'}</p>
                </Col>
                <Col xs={22} md={14}>
                  <p className='fecha_calendaio'>{idioma === 'es' ? '27 Febrero 2024' : 'February 27, 2024'}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} onClick={() => { setVerDia(2) }} className={ver_dia === 2 ? 'dia_select' : 'dia_select_no'}>
              <Row justify="center" align="middle">
                <Col xs={0} md={10}>
                  <p className='fecha_dia'>{idioma === 'es' ? 'Día 2' : 'Day 2'}</p>
                </Col>
                <Col xs={22} md={14}>
                  <p className='fecha_calendaio'>{idioma === 'es' ? '28 Febrero 2024' : 'February 28, 2024'}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} onClick={() => { setVerDia(3) }} className={ver_dia === 3 ? 'dia_select' : 'dia_select_no'}>
              <Row justify="center" align="middle">
                <Col xs={0} md={10}>
                  <p className='fecha_dia'>{idioma === 'es' ? 'Día 3' : 'Day 3'}</p>
                </Col>
                <Col xs={22} md={14}>
                  <p className='fecha_calendaio'>{idioma === 'es' ? '29 Febrero 2024' : 'February 29, 2024'}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center" align="middle" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
            <Col xs={22} md={20} className="divPrograma-" style={{ height: '100%' }}>
              <br />
              <p className='descp_dia color_verde fuente'>
                {idioma === 'es' ?
                  <>{ver_dia === 1 ? 'Estrategias de Éxito para las Inversiones Inmobiliarias en Tiempos de Cambio'
                    :
                    (ver_dia === 2 ? 'Estrategias Verdes para el avance de Comunidades Resilientes'
                      : 'Visitas Edificios LEED • Actividades extra')
                  }
                  </>
                  :
                  <>{ver_dia === 1 ? 'Successful Strategies for Real Estate Investments in Times of Change'
                    :
                    (ver_dia === 2 ? 'Green Strategies for the Advancement of Resilient Communities'
                      : 'Green Building Tours')
                  }
                  </>
                }
              </p>
              <br />
              {ver_actividades}
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}

export default Agenda;
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal, notification } from 'antd';
import '../../assets/css/menu.css';
import logo_blanco from '../../../src/assets/images/evento/logos/LOGO_contruyendo_header_bco.png';
import logo_color from '../../../src/assets/images/evento/logos/LOGO_contruyendo_header_color.png';

import { Auth } from "../../utils/AuthContext";
import { FacebookFilled, InstagramOutlined, MenuOutlined, TwitterOutlined } from '@ant-design/icons';


import Login from '../registro/Login';

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
    duration: 10,
  });
}

const secciones = [
  { nombre: 'Inicio', nombre_en: 'Home', url: '/' },
  { nombre: 'Contenido', nombre_en: 'Content', url: '/#acerca-de' },
  { nombre: 'Programa', nombre_en: 'Schendule', url: '/#programa' },
  { nombre: 'Speakers', nombre_en: 'Speakers', url: '/#speakers',tipo:'' },//clase
  { nombre: 'Entradas', nombre_en: 'Tickets', url: '/#entradas' },
  { nombre: 'Premio SUMe', nombre_en: 'SUMe Awards', url: '/convocatoria' },
  //{ nombre: 'Sponsor', nombre_en: '', url: '/#sponsor',tipo:'' },//clase
  
  //{nombre:'Expo', url:'/expo'},
  {nombre:'¡REGÍSTRATE!',nombre_en: 'Sing up', url:'/registro',tipo:'clase' }
]


const Header = (props) => {

  const [mostrar_login, setMostrarLogin] = useState(false);
  const [mostrar_menu_movil, setMostrarMenuMovil] = useState(false);
  const { usuario } = useContext(Auth);
  //const { height, width, ref } = useResizeDetector();
  const [mostrar_menu, setMostrarMenu] = useState(true);
  const { setIdioma, idioma} = props; //mostrar_login, setMostrarLogin

  //setIdioma('es'||'en')

  let mensajelogin = idioma === 'es' ? 'Por favor inicie sesión para acceder este contenido.' : 'Please login to access this content';
  useEffect(()=>{
    if(!usuario){
      setMostrarLogin(false);
    }
  },[]);

  const [scrollY, setScrollY] = useState(0);
  const [scrol, setScrol] = useState(150);

  function logit() {
    setScrollY(window.pageYOffset);
    setScrol(scrollY);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  /*useEffect(() => {
    if (width > 767) {
      setMostrarMenuMovil(true);
    } else {
      setMostrarMenuMovil(false);
    }
  }, [width]);*/

  const cerrarMenu = () => {
      setMostrarMenuMovil(false);
  }

  const success = () => {
    mensaje('success', 'Bienvenido', '')
  }

  let visualizar_secciones = secciones.map((seccion) => {
    if(seccion.tipo === 'clase'){
      return (
        <Link className="titles registro_btn" to={seccion.url} onClick={cerrarMenu}>{/*top-and-bottom*/}
          {idioma === 'es' ?
            <span className="seccion" >
              {seccion.nombre}
            </span>
            :
            <span className="seccion" >
              {seccion.nombre_en}
            </span>
          }
        </Link>
      );
    }else{
      return (
        <Link className="titles" to={seccion.url} onClick={cerrarMenu}>
          {idioma === 'es' ?
            <span className="seccion" >
              {seccion.nombre}
            </span>
            :
            <span className="seccion" >
              {seccion.nombre_en}
            </span>
          }
        </Link>
      );
    }
  });

  return (
    <Row justify="start" align="middle" className={(scrol <= scrollY && scrollY > 80) ? "navbar--hidden" : "contenedor_header"}>
      <Col xs={{span:10, offset:2}} md={{span:22, offset:0}}>
        <Row justify="" align="middle">
          <Col xs={{span:4, offset:2}}>
            <img alt="logo" className="log_header" src={(scrol <= scrollY && scrollY > 80) ? logo_color : logo_blanco} />
          </Col>
          <Col xs={{span:0, offset:0}} md={{span:0, offset:0}}>
        <p className="sin_margin">
          <span className={idioma === 'es' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('es')}>ESPAÑOL</span> 
          <span className="idioma_no"> | </span> 
          <span className={idioma === 'en' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('en')}>ENGLISH</span>
        </p>
      </Col>
      <Col xs={{span:8, offset:5}} md={{span:3, offset:1}}>
        <p className="sin_margin">
          <span className={idioma === 'es' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('es')}>ES</span> 
          <span className="idioma_no"> | </span> 
          <span className={idioma === 'en' ? "idioma_select" : "idioma_no"} onClick={() => setIdioma('en')}>EN</span>
        </p>
  </Col>
          <Col xs={{ span: 0, offset: 0 }} md={{ span: 14, offset: 0 }}>
          <>
            {visualizar_secciones}
          </>
      </Col>
        </Row>
      </Col>
      <Col xs={{ span: 4, offset: 8 }} md={{ span: 0, offset: 0 }}>
        <MenuOutlined style={{ color: '#ffffff' }} onClick={() => setMostrarMenuMovil(!mostrar_menu_movil)} />
      </Col>
      
      <Col xs={{ span: mostrar_menu_movil ? 24 : 0, offset: 0 }} md={{ span: 0, offset: 0 }}>
          <>

            {visualizar_secciones}
          </>

        

      </Col>
      
      <Modal
        title={<span><strong>{idioma === 'es' ? 'Inicia sesión' : 'Login'}</strong> <small>{idioma === 'es' ? '(únicamente con registro previo)' : '(Only with previu Register)'}</small></span>}
        visible={mostrar_login}//mostrar_login
        onOk={() => { setMostrarLogin(false) }}
        onCancel={() => { setMostrarLogin(false) }}
        footer={null}
        bodyStyle={{backgroundColor:'#fff'}}
      >
        <Login onResponseCerrarLogin={() => { setMostrarLogin(false); }} onResponse={() => { setMostrarLogin(false); window.location.href = "/on-demand"; }} idioma={idioma} onClick={success} />
      </Modal>

    </Row>
  );
}

export default Header;
import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import { useLocation, BrowserRouter } from 'react-router-dom';
import { HashLink, HashLink as Link } from 'react-router-hash-link';
import { HashScroll } from "react-hash-scroll";
import '../../assets/css/evento.css';
import Contador from './Contador';
import CarouselPonentes from './CarouselPonentes';
import Agenda from './Agenda';
import Whatsapp from '../master/Whatsapp';
import { Element } from 'react-scroll';


import logo_banner from '../../assets/images/evento/logos/LOGO_contruyendo_banner_bco.png';
import info_banner from '../../assets/images/evento/logos/iconos y fecha_principal.png';
import info_banner_en from '../../assets/images/evento/ingles/fecha y lugar_eng.png';


import entrada_general from '../../assets/images/evento/entradas/entradageneral_actual.png';
import entrada_sume from '../../assets/images/evento/entradas/miembros sumeactual_.png';

import entrada_general_en from '../../assets/images/evento/ingles/home_entradas/general.png';
import entrada_sume_en from '../../assets/images/evento/ingles/home_entradas/members_.png';

import extra_1 from '../../assets/images/evento/extras/visita_B.png';
import extra_1_v from '../../assets/images/evento/extras/visita_v.png';
import extra_2 from '../../assets/images/evento/extras/precios/true_b.png';
import extra_2_v from '../../assets/images/evento/extras/precios/true_v.png';
import extra_2_sume from '../../assets/images/evento/extras/precios/true_sume_b.png';
import extra_2_sume_v from '../../assets/images/evento/extras/precios/true_sume_v.png';
import extra_3 from '../../assets/images/evento/extras/precios/precios_LEED_feb_B.png';
import extra_3_v from '../../assets/images/evento/extras/precios/precios_LEED_feb_V.png';
import extra_3_sume from '../../assets/images/evento/extras/precios/precios_LEED_feb_sumeB.png';
import extra_3_sume_v from '../../assets/images/evento/extras/precios/precios_LEED_feb_sumeV.png';

import extra_1_en from '../../assets/images/evento/ingles/home_entradas/visita_b_eng.png';
import extra_1_v_en from '../../assets/images/evento/ingles/home_entradas/visita_v_eng.png';
import extra_2_en from '../../assets/images/evento/ingles/home_entradas/true_b_eng.png';
import extra_2_v_en from '../../assets/images/evento/ingles/home_entradas/true_v_eng.png';
import extra_2_sume_en from '../../assets/images/evento/ingles/home_entradas/true_sumes_b_eng.png';
import extra_2_sume_v_en from '../../assets/images/evento/ingles/home_entradas/true_sumes_v_eng.png';
import extra_3_en from '../../assets/images/evento/ingles/home_entradas/precios_LEED_feb_eng_B.png';
import extra_3_v_en from '../../assets/images/evento/ingles/home_entradas/precios_LEED_feb_eng_V.png';
import extra_3_sume_en from '../../assets/images/evento/ingles/home_entradas/precios_LEED_feb_eng_sumeB.png';
import extra_3_sume_v_en from '../../assets/images/evento/ingles/home_entradas/precios_LEED_feb_eng-sumeV.png';


import galeria from '../../assets/images/evento/home/GALERIA_construyendo-el-futuro.jpg';
import galeria_video from '../../assets/video/GALERIA_construyendo el futuro.mp4';

import AcercaDe from './AcercaDe';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import Slider from 'react-slick';

const Evento = (props) => {

  const { idioma } = props;
  const [ver_extra, setVerExtra] = useState(0);
  const ref_carousel = React.createRef();
    const [activar_carouse, setActivaCarosel] = useState(0);

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <CaretRightFilled className={className} style={{ ...style, display: "block", color:'#97B623', fontSize:'32px', right:'-3%', backgroundColor:'#ffffff', border:'2px solid #97B623', borderRadius:'8px', height:'38px', width:'34px', margin:'0px', padding:'0px' }} onClick={onClick} />
        );
      }
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <CaretLeftFilled className={className}  style={{ ...style, display: "block", color:'#97B623', fontSize:'34px', left:'-1%',backgroundColor:'#ffffff', border:'2px solid #97B623', borderRadius:'8px', height:'38px', width:'34px', zIndex:'2' }}  onClick={onClick} />
        );
      }

  const settingss = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows:true,
    nextArrow:<SampleNextArrow /> ,
    prevArrow:<SamplePrevArrow  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <>
      {/*<Whatsapp idioma={idioma} />*/}
      <Row justify="center" align="middle">
        <Col xs={0} lg={24} className="principal">
          <Row>
            <Col span={6} offset={9} style={{ display: 'non' }}><br /><br />
              <a href="https://maps.app.goo.gl/VgMAbWAAQg1ihp9K8" target='_blank'><img src={idioma === 'es' ? info_banner : info_banner_en} width="100%" /></a>
            </Col>
            <Col xs={{span:8, offset:8}} md={{span:6, offset:9}} lg={{span:8, offset:8}}>
              <img src={logo_banner} width="100%" />
            </Col>
            <Col xs={{span:14, offset:5}} lg={{span:16, offset:4}}><br /><br />
              {idioma === 'es' ?
              <p className='texto_banner fuente'>
              <strong>La Segunda Edición del Foro Construyendo el Futuro Un llamado a la Acción</strong>,<br />
              <span className='subtexto_banner'>reúne a la comunidad Internacional de Construcción Sustentable los días <span className='color_verde'><br />
                <strong>27, 28 y 29 de febrero de 2024 en Torre Mayor en la Ciudad de México.</strong></span></span>
            </p>
            :
            <p className='texto_banner fuente'>
                <strong>The Second Edition of the Building the Future Forum – A Call to Action</strong>,<br />
                <span className='subtexto_banner'>brings together the International Green Building community on February<span className='color_verde'><br />
                  <strong>27, 28 and 29, 2024 at Torre Mayor in Mexico City.</strong></span></span>
              </p>}
            </Col>
            <Col span={4} offset={10}>
              <br />
              <a href="/registro" className='btn_registrate_home fuente'>{idioma === 'es' ? '¡REGÍSTRATE!' : 'SIGN UP!'}</a>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={0} className="principal_movil">
          <Row>
            <Col span={10} offset={7}><br />
              <img src={info_banner} width="100%" />
            </Col>
            <Col span={12} offset={6}>
              <img src={logo_banner} width="100%" /><br /><br />
            </Col>
            <Col span={20} offset={2}>
              <a href="/registro" className='btn_registrate_home fuente'>{idioma === 'es' ? '¡REGÍSTRATE!' : 'SIGN UP!'}</a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='contenedor_description'>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }}>
          <Contador idioma={idioma} dateTarget="02/27/2024 08:00:00 AM" />
        </Col>
      </Row>
      <Element name="#acerca-de" />
      <Row justify="center" align="middle" >
        <Col id="acerca-de" name="#acerca-de" span={24}>
          <AcercaDe idioma={idioma} />
        </Col>
      </Row>
      <Element name="#programa" />
      <Row justify="center" align="middle">
        <Col span={24} id="programa">
          <Agenda idioma={idioma} />
        </Col>
      </Row>
      <Element name="#speakers" />
      <Row id="speakers" justify="center" align="middle" style={{ display: 'non' }}>

        <Col span={24} className='contenedor_ponente_-'>

          <CarouselPonentes idioma={idioma} />
        </Col>
      </Row>
      <Row id="registro" style={{ display: 'non', padding: '3% 0%' }}>
        <Element name="#entradas" />
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }}>
          <Row justify={'center'} align={'middle'}>
            <Col xs={20} md={8}>
              <p className='titulo_entradas color_negro fuente'>{idioma === 'es' ? 'Entradas' : 'Tickets'}</p>
              {idioma === 'es' ? 
              <p className='texto_entradas fuente'><strong>Costo especial para miembros SUME y aliados</strong><br /><br />
              El evento busca facilitar el encuentro y el contacto entre los profesionales de la Edificación sustentable en Latinoamérica. Queremos que seas parte de la solución frente al cambio climático.
              </p>
              :
              <p className='texto_entradas fuente'><strong>Special cost for SUME members and allies</strong><br /><br />
              The event seeks to facilitate the meeting and contact between green building professionals in Latin America. We want you to be part of the solution to climate change.
              </p>

              }
            </Col>
            <Col xs={20} md={12}>
              <Row justify={'space-around'}>
                <Col xs={22} md={12}>
                  <Link to="/registro/1"><img src={idioma === 'es' ? entrada_general : entrada_general_en} style={{ width: '100%' }} /></Link>
                </Col>
                <Col xs={22} md={12}>
                  <Link to="/registro/2"><img src={idioma === 'es' ? entrada_sume : entrada_sume_en} style={{ width: '100%' }} /></Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} className='contenedor_extras'>
          <p className='titulo_extras color_blanco fuente'>{idioma === 'es' ? 'Actividades extra' : 'Extra activities'}</p>
          <Row justify={'center'}>
            <Col xs={20} md={16}>
            <Row justify={'center'}>
              <Col xs={20} md={8}>
              {idioma === 'es' ?
              <Link to="/registro/3"><img onMouseEnter={() => setVerExtra(1)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 1 ? extra_1_v : extra_1} style={{ width: '100%' }} /></Link>
              :
              <Link to="/registro/3"><img onMouseEnter={() => setVerExtra(1)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 1 ? extra_1_v_en : extra_1_en} style={{ width: '100%' }} /></Link>
              }
              </Col>
            <Col xs={20} md={8}>
              {idioma === 'es' ?
              <Link to="/registro/4"><img onMouseEnter={() => setVerExtra(2)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 2 ? extra_2_v : extra_2} style={{ width: '100%' }} /></Link>
              :
              <Link to="/registro/4"><img onMouseEnter={() => setVerExtra(2)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 2 ? extra_2_v_en : extra_2_en} style={{ width: '100%' }} /></Link>
              }
            </Col>
            <Col xs={20} md={8}>
              {idioma === 'es' ?
              <Link to="/registro/6"><img onMouseEnter={() => setVerExtra(4)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 4 ? extra_2_sume_v : extra_2_sume} style={{ width: '100%' }} /></Link>
              :
              <Link to="/registro/6"><img onMouseEnter={() => setVerExtra(4)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 4 ? extra_2_sume_v_en : extra_2_sume_en} style={{ width: '100%' }} /></Link>
              }
            </Col>
            {/*<Col xs={20} md={8}>
              {idioma === 'es' ?
              <Link to="/registro/5"><img onMouseEnter={() => setVerExtra(3)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 3 ? extra_3_v : extra_3} style={{ width: '100%' }} /></Link>
              :
              <Link to="/registro/5"><img onMouseEnter={() => setVerExtra(3)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 3 ? extra_3_v_en : extra_3_en} style={{ width: '100%' }} /></Link>
              }
            </Col>
            <Col xs={20} md={8}>
              {idioma === 'es' ?
              <Link to="/registro/7"><img onMouseEnter={() => setVerExtra(5)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 5 ? extra_3_sume_v : extra_3_sume} style={{ width: '100%' }} /></Link>
              :
              <Link to="/registro/7"><img onMouseEnter={() => setVerExtra(5)} onMouseLeave={() => setVerExtra(0)} src={ver_extra === 5 ? extra_3_sume_v_en : extra_3_sume_en} style={{ width: '100%' }} /></Link>
              }
            </Col>*/}
            </Row>
            </Col>
            
          </Row>
        </Col>
      </Row>
      
      <Row>
        <Col xs={24}>
          <video autoPlay loop muted style={{ width: '100%' }} >
            <source src={galeria_video}/>
          </video>
        </Col>
      </Row>
      
      <Row align="middle" justify="center">
        
        <Element name="#sponsors" />


      </Row>

    </>
  );
}

export default Evento;
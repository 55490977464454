import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Upload, Checkbox, Button, notification, Spin, Radio, Image } from 'antd';
import '../../assets/css/proyectos.css';

import { Link } from 'react-router-dom';
import Firebase from '../../utils/firebaseConfig';
import { guardar_votos } from '../../utils/ConexionServidor';
import Item from 'antd/lib/list/Item';

import proyecto_1_pdf from '../../assets/proyectos/01 Valle Azul CRDC/POSTULACION_CRDC PREMIOS IMPULSO SUMe.pdf';
import proyecto_1_img1 from '../../assets/proyectos/01 Valle Azul CRDC/1.jpg';
import proyecto_1_img2 from '../../assets/proyectos/01 Valle Azul CRDC/2.jpg';
import proyecto_1_img3 from '../../assets/proyectos/01 Valle Azul CRDC/3.jpg';
import proyecto_1_img4 from '../../assets/proyectos/01 Valle Azul CRDC/4.jpg';
import proyecto_1_img5 from '../../assets/proyectos/01 Valle Azul CRDC/5.jpg';

import proyecto_2_pdf from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/PREMIOS_IMPULSO_TORRE_SANTA_FE.pdf';
import proyecto_2_img1 from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/1.jpg';
import proyecto_2_img2 from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/2.jpg';
import proyecto_2_img3 from '../../assets/proyectos/02 Torre Santa Fe Chihuahua/3.jpg';

const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const Vivienda = ({ history, onResponse, onResponseCerrarLogin, idioma, categoria, setCategoria }) => {

    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const [datos_registro, setDatosRegistro] = useState([]);
    const [value1, setValue1] = useState(1);
    const [valor,setValor] = useState(0);
    const datos = JSON.parse(localStorage.getItem('usuario'));
    const onChange1 = (e) => {
      console.log('radio checked', e.target.value);
      setValue1(e.target.value);
    };
      console.log(datos.correo);
    const ref_form = React.createRef();

    const onFinish = async values => {

        localStorage.setItem('voto5', JSON.stringify(valor));

        console.log(values);
        values.tipo = categoria;
        values.correo = datos.correo;
        values.categoria5 = valor;

        setCargarPantalla(true);

        guardar_votos(values).then(({ data, status }) => {
            switch (status) {
                case 0:
                    mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu voto ha sido enviado' : 'Tu voto ha sido enviado', + data[3]);
                    setDatosRegistro(data);
                    break;
                case 1:
                    mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 2:
                    mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
                    break;
                default:
                    mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
                    break;
            }
            setCargarPantalla(false);
        });
        setCategoria(categoria+1);

    };


    const proyectos = [
        {id:1, nombre:'Valle Azul', desc:[<>El proyecto Valle Azul es una iniciativa innovadora que ha transformado el panorama de la vivienda social en Costa Rica. Ubicado en la ciudad de La Guácima de Alajuela, en una parcela de 1,2 km al suroeste y 375m al oeste de la Iglesia de El Coco, este desarrollo abarca una superficie total estimada de 22 500 m2 , Valle Azul representa un modelo ejemplar de construcción sostenible y compromiso con el medio ambiente. En su construcción logró retirar 90 toneladas de residuos plásticos del medio ambiente logrando tener un impacto de haber retirado 86.4 Ton CO2 eq. por la utilización de RESIN8TM.<br /><br />
            El proyecto se desarrolló gracias a una alianza entre Hábitat para la Humanidad y las empresas DOW, FUPROVI, CRDC y PEDREGAL.<br /><br />
            Las 102 viviendas de interés social construidas en Valle Azul han sido diseñadas para promover un estilo de vida saludable y sostenible. Además de servir como hogares para familias de bajos ingresos, el proyecto incluye espacios comunitarios como un parque infantil, un anfiteatro y un centro de reciclaje de residuos, promoviendo la cohesión social y la conciencia ambiental.<br /><br />
            Motivados por la necesidad de ofrecer viviendas asequibles y respetuosas con el medio ambiente, los desarrolladores del proyecto buscaron la certificación Edge como un medio para validar y destacar sus esfuerzos por reducir la huella ambiental de la construcción de viviendas sociales.</>],
            documento:proyecto_1_pdf,
        imagenes:[
            {indes:1, url:proyecto_1_img1,tipo:1},
            {indes:2, url:proyecto_1_img2,tipo:2},
            {indes:3, url:proyecto_1_img3,tipo:2},
            {indes:4, url:proyecto_1_img4,tipo:2},
            {indes:5, url:proyecto_1_img5,tipo:2},
        ],
        certificacion:'EDGE CERTIFICATE CERTIFICATE NUMBER LP3-CRI-20010610082550-2'
        }
    ];

    const ver_proyectos = proyectos.map((item) => {
        return(
            <>
            <Row className='contenedor_pryecto'>
                <Col  xs={22} lg={8}>
                    <Row justify={'center'}>
                    {item.imagenes.map(image => {
                        if(image.indes === 1){
                            return(
                                <>
                                <Col span={24}>
                                    <Image
                                    src={image.url}
                                    className='img_grande'
                                    placeholder={
                                    <Image
                                        preview={false}
                                        src={image.url}
                                        style={{width:'80%'}}
                                    />}
                                    />
                                </Col>
                                </>
                            );
                        }else{
                            return(
                                <>
                                <Col span={5}>
                                    <br />
                                    <Image
                                    className='img_peque'
                                    src={image.url}
                                    placeholder={
                                    <Image
                                        preview={false}
                                        src={image.url}
                                        style={{width:'80%'}}
                                    />}
                                    />
                                </Col>
                                </>
                            );
                        }
                    })}
                    </Row>
                </Col>
                <Col  xs={22} lg={16}>
                    <p className='titulo_proyecto'>{item.nombre}</p>
                    <p className='desc_proyecto'><strong>Certificación: </strong>{item.categoria}</p>
                    <p className='desc_proyecto'>{item.desc}</p>
                    <Row>
                    <Col xs={0} lg={0}><br />
                            <p>
                            <Link className='btn_proyecto' to={item.documento} target='_blank'>Ver proyecto completo </Link>
                        </p>
                            </Col>
                            <Col xs={24} lg={12}><br />
                        <span className={valor !== 0 ? (valor === item.id ? 'btn_votar_select' : 'btn_votar') : 'btn_noselect'} onClick={() => setValor(item.id)}>
                        SELECCIONAR
                        </span>
                            </Col>
                        </Row>
                </Col>
            </Row>
            </>
        )
    });
    return (
        <>
            <Row justify={'center'}>
                        <Col span={20}>
                            <Form className="login-form"
                                layout="vertical"
                                name="basic"
                                onFinish={onFinish}
                                ref={ref_form}
                            >
                                <Row>
                                    <Col span={24}><br /><br />
                                        <p className='titulo_objeticos color_azul fuente' style={{textAlign:'center'}}>VIVIENDA</p>
                                    </Col>
                                    <Col span={24}>
                                        {ver_proyectos}
                                    </Col>
                                    <Col span={24}>
                                                <Form.Item><br /><br />

                                                    <p style={{ textAlign: 'center', display: 'non' }}>
                                                        <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType="submit">
                                                            <p className="btn_registrate_home"><strong>ENVIAR VOTO</strong></p>
                                                        </button>
                                                    </p>

                                                </Form.Item>

                                            </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
        </>
    )
}
export default Vivienda;
import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import '../../assets/css/caracteristicas.css';
import { Link, useLocation } from 'react-router-dom';

import img_video from '../../assets/images/evento/home/imagen_ video.jpg';

import img_objetivos from '../../assets/images/evento/objetivos_esp.png';
import img_objetivos_movil from '../../assets/images/evento/objetivos_esp_movil.png';

import img_objetivos_en from '../../assets/images/evento/ingles/objetivos_ENG_.png';
import img_objetivos_movil_en from '../../assets/images/evento/ingles/objetivos_ENG-movil.png';

import Slider from 'react-slick';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';


const AcercaDe = (props) => {
    const { idioma } = props;
    const ref_carousel = React.createRef();
    const [activar_carouse, setActivaCarosel] = useState(0);

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <CaretRightFilled className={className} style={{ ...style, display: "block", color: '#97B623', fontSize: '32px', right: '-10px', backgroundColor: '#ffffff', border: '2px solid #97B623', borderRadius: '8px', height: '38px', width: '34px', margin: '0px', padding: '0px', boxShadow: '2px 6px 13px 0px rgba(0,0,0,0.35)' }} onClick={onClick} />
        );
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <CaretLeftFilled className={className} style={{ ...style, display: "block", color: '#97B623', fontSize: '34px', left: '-10px', backgroundColor: '#ffffff', border: '2px solid #97B623', borderRadius: '8px', height: '38px', width: '34px', zIndex: '2', boxShadow: '-2px 6px 13px 0px rgba(0,0,0,0.35)' }} onClick={onClick} />
        );
    }

    const settingss = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: false,
        arrows: true,
        centerMode: true,
        className: "center",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };
    return (
        <>
            <Row align="middle" justify={'center'} style={{ padding: '3% 0%' }} className='back_gris'>
                <Col xs={20}>
                    <p className='titulo_objeticos fuente'>
                        {idioma === 'es' ?
                            'Nuestro foro tiene como objetivos:'
                            :
                            'Our forum aims to:'}
                    </p>
                </Col>
                <Col xs={22} md={17}>
                    <Slider ref={ref_carousel} {...settingss} style={{ margin: '0px', padding: '0px', height: '100%' }} className="">
                        <div className='div_fuera'>
                            <div className='div_gris'>
                                <p className='num_objetivo color_azul'>1</p>
                                <p className='texto_objetivo'>
                                    {idioma === 'es' ?
                                        'Promover el intercambio de conocimientos y experiencias de alto nivel en edificación sustentable.'
                                        :
                                        'Promote the exchange of knowledge and experiences between experts and professionals in green building'}
                                </p>
                            </div>
                            <br />
                        </div>
                        <div className='div_fuera'>
                            <div className='div_gris'>
                                <p className='num_objetivo color_azul'>2</p>
                                <p className='texto_objetivo'>
                                    {idioma === 'es' ?
                                        'Acelerar la descarbonización en la edificación.'
                                        :
                                        'Accelerate  decarbonization in the construction sector'}
                                </p>
                            </div>
                        </div>
                        <div className='div_fuera'>
                            <div className='div_gris'>
                                <p className='num_objetivo color_azul'>3</p>
                                <p className='texto_objetivo'>
                                    {idioma === 'es' ?
                                        'Crear alianzas estratégicas para hacer frente a la emergencia climática.'
                                        :
                                        'Create strategic alliances to address the climate emergency'
                                    }
                                </p>
                            </div>
                        </div>
                        <div className='div_fuera'>
                            <div className='div_gris'>
                                <p className='num_objetivo color_azul'>4</p>
                                <p className='texto_objetivo'>
                                    {idioma === 'es' ?
                                        'Identificar retos y oportunidades de la edificación sustentable y su implementación en México.'
                                        :
                                        'Identify challenges and opportunities of Green building and its implementation in Mexico'}
                                </p>
                            </div>
                        </div>
                        <div className='div_fuera'>
                            <div className='div_gris'>
                                <p className='num_objetivo color_azul'>5</p>
                                <p className='texto_objetivo'>
                                    {idioma === 'es' ?
                                        'Promover la sensibilización y la acción sobre la emergencia climática y la acción urgente en el desarrollo de comunidades más sustentables y resilientes.'
                                        :
                                        'Promote awareness and action on the climate emergency and urgent action towards the development of more sustainable and resilient communities.'}
                                </p>
                            </div>
                        </div>
                    </Slider>
                    <br /><br />
                </Col>
                <Col xs={20}>
                    {idioma === 'es' ? 
                    <p className='desc_objetivos fuente'>
                        Los objetivos mencionados serán abordados por panelistas y expertos nacionales e <br className='no_ver_chico' />internacionales de alto nivel en edificación sustentable y sustentabilidad.
                        <br /><br />
                        Dentro del desarrollo de este importante evento, celebraremos el <br className='no_ver_chico' /><Link to="/convocatoria" className='color_azul'><strong>Premio Impulso SUME a la sustentabilidad 2024 by Saint Gobain</strong></Link>
                        <br /><br />en las siguientes categorías:
                    </p>
                    :
                    <p className='desc_objetivos fuente'>
                        The aforementioned objectives will be addressed by panelists and high-level national and international <br className='no_ver_chico' />experts in green building and sustainability.
                        <br /><br />
                        As part of this important event, we will celebrate the  <br className='no_ver_chico' /><strong>2024 SUMe International Sustainability Award by St. Gobain</strong>
                        <br /><br /> in the following categories:
                    </p>}
                </Col>
                <Col xs={0} md={20}>
                    <img src={idioma === 'es' ? img_objetivos : img_objetivos_en} style={{ width: '100%' }} />
                </Col>
                <Col xs={22} md={0}>
                    <img src={idioma === 'es' ? img_objetivos_movil : img_objetivos_movil_en} style={{ width: '100%' }} />
                </Col>
                <Col xs={20}>
                    <p className='desc_objetivos fuente'><br />
                        <i><strong>
                            {idioma === 'es' ? 
                            'Los premios serán seleccionados por un grupo de expertos para la votación del público.'
                            :
                            'The awards will be selected by a group of experts for public voting.'}
                            </strong></i>

                    </p>
                    <p>
                        <Link to="/votacion" className='btn_registrate_home fuente'>
                            {idioma === 'es' ? 'VOTA POR LOS GANADORES AQUÍ' : 'VOTE FOR THE WINNERS'}
                        </Link>
                    </p>
                </Col>
            </Row>
            <Row align="middle" style={{ padding: '3% 0%' }}>
                <Col xs={{ span: 20, offset: 2 }}>
                    <p className='titulo_objeticos fuente'><strong>
                        {idioma === 'es'
                        ?
                        '¿Porqué asistir?'
                        :'Why attend?'
                                }</strong></p>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 8, offset: 2 }}>
                    <Row>
                        <Col span={24}>
                            {idioma === 'es' ?
                            <p style={{ borderLeft: 'solid 4px #3ccafb' }} className='texto_acercade'><strong>Vislumbrar el futuro de la edificación sustentable</strong><br /><br />
                            Adquiere una comprensión más amplia de qué viene para la edificación sustentable, desde el desarrollo de economías verdes, la preservación de recursos y cómo implementar acciones concretas para alcanzar el Net-Zero.</p>
                            :
                            <p style={{ borderLeft: 'solid 4px #3ccafb' }} className='texto_acercade'><strong>Envision the future of green building</strong><br /><br />
                                Gain a broader understanding of what's next for green building, from developing green economies, preserving resources, and how to implement concrete actions to achieve Net-Zero.</p>
                            }
                        </Col>
                        <Col span={24}>
                            {idioma === 'es' ?
                            <p style={{ borderLeft: 'solid 4px #39b14a' }} className='texto_acercade'><strong>Soluciones, productos y servicios de vanguardia</strong><br /><br />
                            Conoce de primera mano los materiales, productos y servicios más innovadores de la construcción sustentable.</p>
                            :
                            <p style={{ borderLeft: 'solid 4px #39b14a' }} className='texto_acercade'><strong>State-of-the-art solutions, products and services </strong><br /><br />
                                Get first-hand knowledge of the most innovative materials, products and services in green building.</p>
                            }
                        </Col>
                        <Col span={24} >
                            {idioma === 'es' ? 
                            <p style={{ borderLeft: 'solid 4px #f88319' }} className='texto_acercade'><strong>Networking con profesionales calificados</strong><br /><br />
                            Contacta y comparte con más de 250 profesionales de la edificación sustentable, ponentes y líderes internacionales en un ambiente seguro y relajado.</p>
                            :
                            <p style={{ borderLeft: 'solid 4px #f88319' }} className='texto_acercade'><strong>Networking with qualified professionals</strong><br /><br />
                                Contact and share with more than 250 green building professionals, speakers and international leaders in a safe and relaxed environment.</p>
                            }
                        </Col>
                        <Col span={24}>
                            {idioma === 'es' ?
                            <p style={{ borderLeft: 'solid 4px #bb2634' }} className='texto_acercade'><strong>Nuevas herramientas para ESG/ASG</strong><br /><br />
                            Adquiere nuevas herramientas para comunicar y lograr los objetivos de sustentabilidad de tu empresa, vislumbra nuevas oportunidades profesionales y mucho más.</p>
                            :
                            <p style={{ borderLeft: 'solid 4px #bb2634' }} className='texto_acercade'><strong>New approaches for ESG/ASG</strong><br /><br />
                                Acquire new tools to communicate and achieve your company's sustainability goals, envision new career opportunities, and more.</p>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 11, offset: 1 }}>
                    <div className="contenedor_video">
                        <iframe className="video" src="https://www.youtube.com/embed/XniQb3viwps?rel=0&autoplay=0;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen;" allowfullscreen="1"></iframe>
                    </div>
                </Col>
                <Col span={24}>
                    <br /><br />
                </Col>
            </Row>
        </>
    )
}
export default AcercaDe;
import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import '../../assets/css/convocatoria.css';

//import banner from '../../assets/images/evento/banner_premiossume.jpg';

import banner from '../../assets/images/evento/BannerVotaciones.jpg';
import banner_movil from '../../assets/images/evento/banner_premiossume_movil.jpg';
import anexo1 from '../../assets/documentos/ANEXO_1_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN_2024.docx';
import { Link } from 'react-router-dom';


const Convocatoria = (props) => {
    const { idioma } = props;


    return (
        <>
        <Row>
            <Col xs={24} md={0}>
                <Link to="/votacion" target='_blank'><img src={banner} style={{width:'100%'}} /></Link>
            </Col>
            <Col xs={0} md={24}>
            <Link to="/votacion" target='_blank'><img src={banner} style={{width:'100%'}} /></Link>
            </Col>
        </Row>
            <Row align="middle" justify={'center'} style={{ padding: '3% 0%' }} className='back_gris'>
                <Col xs={20}>
                    <p className='titulo_objeticos fuente color_azul' style={{textAlign:'center'}}>
                    CONVOCATORIA 
                    </p>
                    <p className='titulo_objeticos fuente' style={{textAlign:'center'}}>
                    PREMIOS IMPULSO SUME 2024 BY SAINT-GOBAIN
                    </p>
                </Col>
                <Col xs={20}>
                    <p className='texto_convocatoria'>
                        <strong>Foro Construyendo el Futuro - Un llamado a la Acción y Sustentabilidad para México, AC</strong> (SUMe) extienden la presente convocatoria de participación a la segunda edición de los Premios <strong>Impulso SUMe 2024 by Saint-Gobain</strong>, a todos aquellos proyectos que se han distinguido dentro por sus aportaciones a la edificación sustentable en México y Latinoamérica, dentro de alguna de las siguientes categorías:

                        <ol type="1">
                        <li>Vivienda</li>
                        <li>Edificios Comerciales</li>
                        <li>Edificios Industriales</li>
                        <li>Edificios de Usos Mixtos</li>
                        <li>Edificios de Tránsito (aeropuertos, estaciones de trenes, estaciones de servicio, etc)</li>
                        <li>Mejor Material </li>
                        <li>Liderazgo</li>
                        </ol>
                        Los proyectos elegibles deberán ser proyectos terminados entre <strong>EL 1 DE ENERO DE 2020 Y EL 31 DE DICIEMBRE DE 2023</strong> y deberán contar con una certificación o recertificación vigente en edificación sustentable reconocida internacionalmente y que haya sido validada por un tercero.
                        <br />
                        <br />
                        Los proyectos participantes deberán documentar su elegibilidad con base en los siguientes criterios de evaluación: 
                    </p><br />
                    <p className='sub_convocatoria fuente'>CRITERIOS DE EVALUACIÓN</p>
                    <p className='texto_convocatoria'>
                    <strong>1. Impacto social </strong>
                    <br /><br />
                    Para determinar el impacto social del proyecto, deberá especificarse la forma en la que el proyecto ha impactado en una mejora en la calidad de vida de los usuarios, así como su trascendencia para la comunidad. Peso en la calificación: 30%
                    <br /><br />
                    <strong>2. Impacto medioambiental</strong>
                    <br /><br />
                    Para determinar el impacto del proyecto en el medio ambiente, deberá documentarse su innovación en alguno o más de los siguientes aspectos: eficiencia energética (incluyendo fuentes alternativas), gestión de agua, biodiversidad, descarbonización, ciclo de vida, movilidad sustentable, entre otros. Peso en la calificación: 25%
                    <br /><br />
                    <strong>3. Salud y bienestar</strong>
                    <br /><br />
                    El proyecto deberá comprobar su contribución a la salud y bienestar de sus usuarios, considerando uno o más de los siguientes aspectos: calidad del aire, biofilia, acústica, calidad de la iluminación, diseño incluyente y protección civil. Peso en la calificación: 15%
                    <br /><br />
                    <strong>4. Innovación</strong>
                    <br /><br />
                    Los proyectos elegibles deberán documentar su aportación a la innovación en materia de edificación sustentable, dando a conocer las estrategias empleadas que hayan representado un aporte inédito en la edificación sustentable, tales como una mejora en los procedimientos tradicionales o el uso de elementos innovadores que hayan aportado un valor diferenciador. Peso en la calificación: 15%
                    <br /><br />
                    <strong>5. Impacto urbano</strong>
                    <br /><br />
                    Todos los proyectos participantes deberán documentar también su impacto urbano a través de aspectos tales como: integración al entorno urbano, disminución del efecto de islas urbanas de calor mediante paisajismo u otras estrategias, fomento a la movilidad sustentable, estrategias de accesibilidad, facilitar la inclusión, entre otras. Peso en la calificación: 15%

                    </p><br />
                    <p>
                        <a href={anexo1} className='btn_registrate_home fuente' download target='_blank'>
                            DESCARGA EL ANEXO 1
                        </a>
                    </p>
                    <br />
                    <p className='sub_convocatoria fuente'>INSCRIPCIÓN DE PROYECTOS</p>
                    <p className='texto_convocatoria'>
                    La inscripción a los <strong>Premios Impulso SUMe 2024 by Saint-Gobain</strong> es gratuita y no existe ningún costo o retribución monetaria para los ganadores de los premios en cada una de las categorías presentadas. 
                    <br /><br />
                    La postulación de los proyectos se llevará a cabo hasta el <strong>DOMINGO 11 DE FEBRERO DE 2024</strong>. Y podrá ser realizada por cualquiera de los actores profesionales involucrados en el desarrollo, planeación, diseño, financiamiento, consultoría, construcción, proveeduría y/o acabados del proyecto. No hay límite en el número de obras postuladas. Una obra o proyecto puede participar en más de una categoría, si cumple con los criterios para ello.
                    <br /><br />
                    Cada uno de los criterios antes expuestos cuenta con un peso específico en porcentaje para un total de 100%. 
                    <br /><br />
                    Para documentar los criterios anteriores, los candidatos deberán presentar la descripción del proyecto y la documentación que acredite su certificación, así como fotografías en alta calidad y la autorización para el uso de imágenes y difusión de la información que permitan a los votantes identificar los proyectos finalistas. 
                    <br /><br />
                    *Favor de referirse al Anexo 1 como una guía de la documentación necesaria. 
                    </p>
                    <p><strong>ENVÍO DE MATERIALES:</strong> El material gráfico y la presentación con el resumen de la obra<br /> o proyecto deberá ser compartida vía Google Drive / WeTransfer / Dropbox al correo<br /> electrónico: <strong><a href="mailto:premiosimpulsosume@construyendoelfuturo.mx" target='_blank' className='color_azul'>premiosimpulsosume@construyendoelfuturo.mx</a></strong></p>
                    <br />
                    <p className='sub_convocatoria fuente'>DETERMINACIÓN DE PROYECTOS ELEGIBLES Y GANADORES</p>
                    <p className='texto_convocatoria'>
                    El jurado del proyecto, integrado por reconocidos profesionales en la edificación sustentable en México y Latinoamérica, determinará la elegibilidad del proyecto con base en el cumplimiento de los criterios establecidos <strong>en la semana del  12 AL 16 DE FEBRERO DE 2024.</strong>
                    <br /><br />
                    Los proyectos finalistas serán aquellos que obtengan una puntuación más elevada y serán dados a conocer a través de la página web de <strong>Foro Construyendo El Futuro Un Llamado a la Acción</strong> (<a href="https://construyendoelfuturo.mx/" className='color_azul' target='_blank'>www.construyendoelfuturo.mx</a>), para ser sometidos a votación pública, que se llevará a cabo del <strong>22 AL 27 DE FEBRERO DE  2024</strong>, fecha en la que los votantes podrán elegir tres proyectos. La intención de voto se registrará a través de la encuesta correspondiente en la página web: <a href="https://construyendoelfuturo.mx/" className='color_azul' target='_blank'>www.construyendoelfuturo.mx</a>. La última fecha para recibir votos será el <strong>VIERNES 23 DE FEBRERO DE 2024 A LA MEDIA NOCHE.</strong>
                    <br /><br />
                    Solo se tomarán en cuenta tres votos por persona, la cual tendrá una dirección IP asignada. En caso de que más de una dirección IP registre más de una encuesta, solo será tomada en cuenta la primera de ellas que se haya registrado, con el fin de evitar más de una encuesta de votación por individuo.
                    <br /><br />
                    Los proyectos ganadores serán aquellos que obtengan una votación más elevada y serán reconocidos en el Coctel de Premiación que se llevará a cabo en la Torre Mayor el <strong>MIÉRCOLES 28 DE FEBRERO DE 2024</strong>. El Comité Organizador se pondrá en contacto con los titulares de los proyectos ganadores para darles a conocer la logística del evento para su participación.
                    <br /><br />
                    IMPORTANTE: La información contenida en el Anexo 1, así como la posible exposición del proyecto ante el jurado vía remota (Zoom o algún otro medio), es confidencial y será utilizada para determinar la elegibilidad de los proyectos, así como los finalistas. Los proyectos finalistas serán sometidos a votación a través de los canales de difusión de <strong>Foro Construyendo el Futuro - Un Llamado a la Acción,</strong> así como de Sustentabilidad para México (SUMe). 
                    <br /><br />
                    Los párrafos relacionados con las conclusiones del proyecto que sean enviados con la información serán los que se utilicen para publicar los proyectos finalistas y darlos a conocer a los votantes.
                    <br /><br />
                    <strong>DESCRIPCION CATEGORIAS:</strong>
                    <br /><br />
                    <strong>1. Vivienda </strong>se centra en destacar proyectos de vivienda que sobresalen por su enfoque en la sustentabilidad. Esta categoría premia a aquellos desarrollos residenciales que integran de manera excepcional prácticas de diseño y construcción que minimizan el impacto ambiental, maximizan la eficiencia energética y promueven el uso responsable de los recursos. Se valoran aspectos como el uso de materiales sustentables, la implementación de sistemas para la conservación del agua y energía, y la creación de espacios saludables y confortables para sus habitantes. Los proyectos reconocidos en esta categoría son ejemplares en cuanto a su compromiso con la protección del medio ambiente y el bienestar de la comunidad, mostrando cómo la vivienda puede ser sustentable, innovadora y funcional. Estos proyectos sirven de modelo para el futuro de la vivienda sustentable, fusionando aspectos ecológicos con calidad de vida y diseño avanzado.
                    <br /><br />
                    <strong>2. Edificios de Tránsito </strong>son estructuras diseñadas para facilitar y apoyar el transporte de personas y mercancías. Estos edificios incluyen, por ejemplo, estaciones de metro, terminales de autobuses, aeropuertos y estaciones de tren. Caracterizados por su alto flujo de usuarios y operaciones continuas, estos edificios son fundamentales en la infraestructura de transporte y a menudo funcionan como importantes nodos de conexión en las redes de tránsito urbano y regional. Se reconocerá a los edificios de esta categoría que cuenten con atributos ambientales como eficiencia energética, salud, bienestar de los usuarios y regeneración ambiental y estén verificados bajo una certificación ambiental.
                    <br /><br />
                    <strong>3. Edificios Industriales </strong>son grandes espacios estructurados diseñados para albergar actividades de fabricación, almacenamiento, y distribución en el sector industrial. Estas edificaciones se caracterizan por tener amplios espacios abiertos, techos altos y, generalmente, pocas divisiones internas, lo que facilita el manejo de maquinaria pesada y el almacenamiento de grandes volúmenes de productos. Suelen ubicarse en zonas industriales o periféricas de las ciudades y están equipadas para soportar operaciones logísticas intensivas, incluyendo el acceso para vehículos de carga. Además, su diseño y construcción suelen enfocarse en la funcionalidad y eficiencia de recursos como energía y agua, adaptándose a las necesidades específicas de cada tipo de industria.
                    <br /><br />
                    <strong>4. Edificios de Usos Mixtos </strong>reconoce proyectos de construcción que excelentemente combinan diversos usos, como residencial, comercial, oficinas, y en ocasiones incluso cultural o recreativo, dentro de un mismo complejo o edificio. Esta categoría valora la integración armoniosa y funcional de estos diferentes espacios, promoviendo la densidad urbana, la eficiencia en el uso del suelo y la reducción de la necesidad de transporte. Se premian proyectos que no solo son ejemplos de excelencia arquitectónica y funcional, sino que destacan por su contribución a la sustentabilidad urbana, ofreciendo soluciones innovadoras para vivir y trabajar en entornos más conectados y accesibles. Los proyectos galardonados en esta categoría son aquellos que demuestran cómo los edificios de usos mixtos pueden mejorar la calidad de vida urbana, fomentando comunidades más vivas, integradas y sustentables. Se busca premiar a los edificios que destaquen por su liderazgo ambiental en temas de eficiencia energética, eficiencia en agua, salud y bienestar para los usuarios y el medio ambiente.
                    <br /><br />
                    <strong>5. Edificios Comerciales </strong>se centra en reconocer y honrar edificios de oficinas, centros y otras estructuras comerciales que se destacan por su compromiso con la construcción y operación sustentables. Esta categoría valora proyectos que no solo cumplen con los más altos estándares de eficiencia energética y uso responsable de recursos, sino que además incorporan prácticas innovadoras en diseño sustentable y gestión ambiental. Los criterios de evaluación incluyen la implementación de tecnologías sustentables avanzadas, el uso de materiales sustentables y de bajo impacto, así como estrategias para mejorar la calidad del aire interior y promover la salud y bienestar de los usuarios. Se considera también la integración de estos edificios en su entorno urbano, fomentando la accesibilidad y conectividad, y contribuyendo positivamente al tejido social y económico de la comunidad. Los proyectos comerciales premiados bajo esta categoría sirven como referentes en el sector, demostrando cómo los espacios comerciales pueden ser centros de actividad económica, y ejemplos de eficiencia energética, eficiencia en agua, regeneración ambiental y social, innovación y liderazgo en el ámbito de la construcción sustentable.
                    <br /><br />
                    <strong>6. Liderazgo Impulso SUMe </strong>está dedicada a reconocer a personas que han demostrado un compromiso excepcional y una contribución significativa al avance de la construcción y diseño sustentable en México. Esta categoría premia a aquellos líderes que han implementado prácticas sustentables en sus proyectos, y han inspirado e influenciado a otros en la industria para seguir sus pasos. Los criterios incluyen innovación, impacto, promoción de la sustentabilidad más allá de sus propios proyectos, y la habilidad para fomentar el cambio en las prácticas de construcción a nivel local, regional o incluso global. El galardonado en esta categoríaa se destacará por ser un agente de cambio, un educador y un modelo a seguir, demostrando cómo el liderazgo individual puede tener un impacto profundo y positivo en el campo de la sustentabilidad y transformación de la industria de la construcción en México.
                    <br /><br />
                    <strong>7. Mejor Material </strong>se enfoca en reconocer productos o sistemas que sobresalen en los atributos sustentables en la industria de la construcción. Esta categoría valora la innovación en la utilización de contenidos de reciclados, renovables o de bajo impacto ambiental, así como la eficiencia en su uso y gestión durante todo el ciclo de vida del proyecto. Se premian aquellos esfuerzos que demuestran un compromiso con la reducción de la huella de carbono y la minimización del impacto ambiental, contribuyendo a la creación de productos más saludables y respetuosos con el medio ambiente. Se reconocen los esfuerzos por buscar Declaraciones Ambientales de Producto (por sus siglas en inglés EPDs), o certificaciones internacionales para validar lo que se ha logrado. La categoría busca destacar productos que además de cumplir con estándares de sustentabilidad, impulsan la innovación y establecen nuevos referentes en los materiales usados en la industria de la construcción sustentable. 
                    <br /><br />
                    <strong>VERACIDAD DE LA INFORMACIÓN</strong>
                    <br /><br />
                    Ni el Comité Organizador de <strong>“Foro Construyendo el Futuro - Un Llamado a la Acción”,</strong> ni el patrocinador o ninguno de los miembros del jurado será sujeto de responsabilidad respecto a la veracidad de la información que sea compartida en cuanto a propiedad intelectual de los proyectos, líderes de proyecto o cualquier información relacionada con los proyectos sometidos a consideración. La documentación probatoria de las certificaciones será testimonio de la existencia del proyecto.
                    <br /><br />
                    <strong>*EN CASO DE QUE SE DETECTE QUE UN PROYECTO HAYA PRESENTADO INFORMACIÓN FALSA, SERÁ DESCALIFICADO INMEDIATAMENTE.</strong>
                    </p>
                    <p>
                        <Link to="/votacion" className='btn_registrate_home fuente'>
                            {idioma === 'es' ? 'VOTA POR LOS GANADORES' : 'VOTE FOR THE WINNERS'}
                        </Link>
                    </p>
                </Col>
                
            </Row>
           
        </>
    )
}
export default Convocatoria;
import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form, Input, Button, notification, Select } from "antd";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import Firebase from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import Errores from './Errores'
import '../../assets/css/registro.css';

import img_crear from '../../assets/images/temporal/1920x200.png';
import img_entrar from '../../assets/images/temporal/1920x200.png';
import sin_login from '../../assets/images/temporal/1920x200.png';


const app = new Firebase();

const nombre_webinar = "2024";

const {Option} = Select;

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
        duration: 10,
    });
}

const LoginVotaciones = ({ history, onResponse }) => {

    const { usuario } = useContext(Auth);
    const [error, seterror] = useState('');
    const [correo_recuperar, setCorreoRecuperar] = useState('');
    const [mostrar_recuperar, setMostrarRecuperar] = useState(false);
    const [otra_empresa, setOtraEmpresa] = useState(false);

    useEffect(() => {


        if (usuario) {
            onResponse();
        }
    }, [history, usuario]);

    const cambiarEmpresa = val => {
        console.log(val);
        if(val === 'OTRA'){
            setOtraEmpresa(true);
        }else{
            setOtraEmpresa(false);
        }
    }

    const onFinish = async values => {

        if(otra_empresa){
            values.empresa = values.otra_empresa;
        }
        localStorage.setItem('usuario',JSON.stringify(values));
        app.existe_objeto(nombre_webinar+ '/usuarios', 'correo',values.correo, (data)=>{
            if(!data){
                app.escribir(nombre_webinar + '/usuarios', undefined, values, (data) => {
                });
            }
            app.app.auth().signInAnonymously().then(()=>{
                mensaje('success', 'Bienvenido', '');
                onResponse();
            }).catch((error) => {
                mensaje('error', 'Error al cargar usuario, inténtalo más tarde', '');
            });
        });

    };

    return (
        !mostrar_recuperar ?
            (
                <Row className="contenedor_registro">

                    <Col span={24}>
                        <Form className="login-form" onFinish={onFinish} layout="vertical"

                        >
                            <Row>
                                <Col span={24} style={{ textAlign: 'center', color: '#112f6d' }}>
                                    ¡Gracias por acompañarnos!
                                <br /><br />
                                    <strong>Ingresa tus datos para acceder a la transmisión.</strong>
                                    <br /><br />
                                </Col>

                                <Col span={24}> <hr /></Col>
                            </Row>

                            <Form.Item
                                name="nombre"
                                label={<span style={{ color: '#112f6d' }}>Nombre completo</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu nombre!',
                                    },
                                ]}
                            >
                                <Input style={{ backgroundColor: '#F5F8F5' }} />
                            </Form.Item>
                            <Form.Item
                                name="correo"
                                label={<span style={{ color: '#112f6d' }}>Correo electrónico</span>}
                                rules={[
                                    {
                                        type: 'email',
                                        message: '...',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingresa tu correo electrónico!',
                                    },
                                ]}
                                onChange={(e) => { setCorreoRecuperar(e.target.value); }}
                            >
                                <Input style={{ backgroundColor: '#F5F8F5' }} />
                            </Form.Item>
                            <Form.Item
                                name="empresa"
                                label={<span style={{ color: '#112f6d' }}>Empresa</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu correo empresa!',
                                    },
                                ]}
                            >
                                <Input style={{ backgroundColor: '#F5F8F5' }} />
                            </Form.Item>
                            
                           {/* <Form.Item
                                name="empresa"
                                label={<span style={{ color: '#112f6d' }}>Empresa/Procedencia</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu empresa/procedencia!',
                                    },
                                ]}
                            >
                                <Select style={{ backgroundColor: '#F5F8F5' }} onChange={cambiarEmpresa} >
                                    <Option value="ÁGUILAS DEL DESIERTO DE BAJA CALIFORNIA, S DE R.L. DE CV">ÁGUILAS DEL DESIERTO DE BAJA CALIFORNIA, S DE R.L. DE CV</Option>
                                    <Option value="ALEN DEL NORTE SA DE CV">ALEN DEL NORTE SA DE CV</Option>
                                    <Option value="APEX SIMULACION Y TECNOLOGIA S.A. DE C.V. ">APEX SIMULACION Y TECNOLOGIA S.A. DE C.V. </Option>
                                    <Option value="ARCOSA INDUSTRIES DE MEXICO S DE RL DE CV">ARCOSA INDUSTRIES DE MEXICO S DE RL DE CV</Option>
                                    <Option value="AT PILOT">AT PILOT</Option>
                                    <Option value="Auto Motive Trucks, S.A. de C.V., ( Fotón México )            ">Auto Motive Trucks, S.A. de C.V., ( Fotón México )            </Option>
                                    <Option value="AUTO TRANSPORTES ILHUILCAMINA, S.A. DE CV">AUTO TRANSPORTES ILHUILCAMINA, S.A. DE CV</Option>
                                    <Option value="AUTOTANQUES NIETO S.A. DE C.V.">AUTOTANQUES NIETO S.A. DE C.V.</Option>
                                    <Option value="Bachoco S.A. de C.V.">Bachoco S.A. de C.V.</Option>
                                    <Option value="BAJA FERRIES, S.A. DE CV">BAJA FERRIES, S.A. DE CV</Option>
                                    <Option value="BARCEL, S.A. DE CV">BARCEL, S.A. DE CV</Option>
                                    <Option value="BASCULAS REVUELTA MAZA, S.A. DE CV">BASCULAS REVUELTA MAZA, S.A. DE CV</Option>
                                    <Option value="BEBIDAS MUNDIALES SA DE CV">BEBIDAS MUNDIALES SA DE CV</Option>
                                    <Option value="BIMBO, S.A. DE CV">BIMBO, S.A. DE CV</Option>
                                    <Option value="BIO SERVICIOS CORPORATIVOS SA DE CV">BIO SERVICIOS CORPORATIVOS SA DE CV</Option>
                                    <Option value="BONAFONT, SA DE CV">BONAFONT, SA DE CV</Option>
                                    <Option value="BULKMATIC DE MÉXICO, S DE R.L. DE CV">BULKMATIC DE MÉXICO, S DE R.L. DE CV</Option>
                                    <Option value="CADENA COMERCIAL OXXO">CADENA COMERCIAL OXXO</Option>
                                    <Option value="CARGILL DE MÉXICO, S.A. DE CV">CARGILL DE MÉXICO, S.A. DE CV</Option>
                                    <Option value="Cementos Apasco, S.A. DE C.V.">Cementos Apasco, S.A. DE C.V.</Option>
                                    <Option value="Cementos Moctezuma,S.A. de C.V.">Cementos Moctezuma,S.A. de C.V.</Option>
                                    <Option value="CEMEX MÉXICO, S.A. DE CV">CEMEX MÉXICO, S.A. DE CV</Option>
                                    <Option value="CERVECERIA CUAUHTEMOC MOCTEZUMA SA DE CV">CERVECERIA CUAUHTEMOC MOCTEZUMA SA DE CV</Option>
                                    <Option value="COFREMEX, S.A. DE CV">COFREMEX, S.A. DE CV</Option>
                                    <Option value="COLGATE PALMOLIVE, S.A. DE CV">COLGATE PALMOLIVE, S.A. DE CV</Option>
                                    <Option value="COMERCIALIZADORA DE PARTES CM, S.A. DE C.V. (TRANSTEX)">COMERCIALIZADORA DE PARTES CM, S.A. DE C.V. (TRANSTEX)</Option>
                                    <Option value="COMERCIALIZADORA ELORO, S.A.">COMERCIALIZADORA ELORO, S.A.</Option>
                                    <Option value="COMERCIALIZADORA PEPSICO MÉXICO, S DE R.L. DE CV">COMERCIALIZADORA PEPSICO MÉXICO, S DE R.L. DE CV</Option>
                                    <Option value="COMPAÑÍA MINERA AUTLAN, SAB DE CV">COMPAÑÍA MINERA AUTLAN, SAB DE CV</Option>
                                    <Option value="CONSERVAS LA COSTEÑA, S.A. DE CV">CONSERVAS LA COSTEÑA, S.A. DE CV</Option>
                                    <Option value="COOPERATIVA LA CRUZ AZUL S.C.L">COOPERATIVA LA CRUZ AZUL S.C.L</Option>
                                    <Option value="COPPEL, SA DE CV">COPPEL, SA DE CV</Option>
                                    <Option value="Cummins, S. de R.L.  de  C.V.">Cummins, S. de R.L.  de  C.V.</Option>
                                    <Option value="DAIMLER VEHÍCULOS COMERCIALES MÉXICO, S DE R.L. DE CV">DAIMLER VEHÍCULOS COMERCIALES MÉXICO, S DE R.L. DE CV</Option>
                                    <Option value="DANA HEAVY AXLE MEXICO SA DE CV">DANA HEAVY AXLE MEXICO SA DE CV</Option>
                                    <Option value="DE ACERO, S.A. DE CV">DE ACERO, S.A. DE CV</Option>
                                    <Option value="DESARROLLO LOGÍSTICO, S.A. DE CV (SOLISTICA)">DESARROLLO LOGÍSTICO, S.A. DE CV (SOLISTICA)</Option>
                                    <Option value="DIBLO CORPORATIVO S.A. DE CV (GRUPO MODELO)">DIBLO CORPORATIVO S.A. DE CV (GRUPO MODELO)</Option>
                                    <Option value="EL PALACIO DE HIERRO, S.A. DE CV">EL PALACIO DE HIERRO, S.A. DE CV</Option>
                                    <Option value="ELEMENT FLEET MANAGEMENT CORPORATION MEXICO">ELEMENT FLEET MANAGEMENT CORPORATION MEXICO</Option>
                                    <Option value="ELICARGA SA DE CV (HARINAS ELIZONDO)">ELICARGA SA DE CV (HARINAS ELIZONDO)</Option>
                                    <Option value="EMBOTELLADORA LAS MARGARITAS SAPI DE C.V">EMBOTELLADORA LAS MARGARITAS SAPI DE C.V</Option>
                                    <Option value="EMBOTELLADORA MEXICANA SA DE CV">EMBOTELLADORA MEXICANA SA DE CV</Option>
                                    <Option value="ENERTEC EXPORTS, S. DE R.L. DE C.V.">ENERTEC EXPORTS, S. DE R.L. DE C.V.</Option>
                                    <Option value="ESGARI HOLDING MEXICO S.A. DE C.V .">ESGARI HOLDING MEXICO S.A. DE C.V .</Option>
                                    <Option value="ESPECIALIZADOS SAGOT SA DE CV">ESPECIALIZADOS SAGOT SA DE CV</Option>
                                    <Option value="ESTAFETA MEXICANA, S.A. DE CV">ESTAFETA MEXICANA, S.A. DE CV</Option>
                                    <Option value="ESTAYTESU, SA DE CV">ESTAYTESU, SA DE CV</Option>
                                    <Option value="FABRICA DE JABÓN LA CORONA, S.A. DE CV">FABRICA DE JABÓN LA CORONA, S.A. DE CV</Option>
                                    <Option value="FARMACEUTICOS MAYPO S.A. DE C.V.">FARMACEUTICOS MAYPO S.A. DE C.V.</Option>
                                    <Option value="FLETAMENTO INTEGRAL, S.A. DE C.V.">FLETAMENTO INTEGRAL, S.A. DE C.V.</Option>
                                    <Option value="FLETES HESA, S.A. DE CV">FLETES HESA, S.A. DE CV</Option>
                                    <Option value="FLETES HESA, S.A. DE CV ">FLETES HESA, S.A. DE CV </Option>
                                    <Option value="FLETES NAUTICOS MEXICO SAPI DE CV">FLETES NAUTICOS MEXICO SAPI DE CV</Option>
                                    <Option value="GCC TRANSPORTE SA DE CV">GCC TRANSPORTE SA DE CV</Option>
                                    <Option value="HENDRICKSON MEXICANA, S DE R.L. DE CV">HENDRICKSON MEXICANA, S DE R.L. DE CV</Option>
                                    <Option value="HINO MOTORS SALES MÉXICO, S.A. DE CV">HINO MOTORS SALES MÉXICO, S.A. DE CV</Option>
                                    <Option value="HOLDING OPERACIONES MÉXICO, S DE R.L. DE C.V.">HOLDING OPERACIONES MÉXICO, S DE R.L. DE C.V.</Option>
                                    <Option value="IDEALEASE DE MÉXICO, S.A. DE CV">IDEALEASE DE MÉXICO, S.A. DE CV</Option>
                                    <Option value="INDUSTRIA DE REFRESCOS S DE RL DE CV (GEPP)">INDUSTRIA DE REFRESCOS S DE RL DE CV (GEPP)</Option>
                                    <Option value="INDUSTRIAS MICHELIN, S.A. DE CV      ">INDUSTRIAS MICHELIN, S.A. DE CV      </Option>
                                    <Option value="INDUSTRIAS RESSER S.A. DE C.V.">INDUSTRIAS RESSER S.A. DE C.V.</Option>
                                    <Option value="INFRA SA DE CV">INFRA SA DE CV</Option>
                                    <Option value="INNOVAZIONE AGENTE DE SEGUROS SA DE CV">INNOVAZIONE AGENTE DE SEGUROS SA DE CV</Option>
                                    <Option value="ISUZU MOTORS DE MÉXICO, S DE R.L.">ISUZU MOTORS DE MÉXICO, S DE R.L.</Option>
                                    <Option value="KENWORTH MEXICANA, S.A. DE CV">KENWORTH MEXICANA, S.A. DE CV</Option>
                                    <Option value="LALA ADMINISTRACIÓN Y CONTROL, S.A. DE CV">LALA ADMINISTRACIÓN Y CONTROL, S.A. DE CV</Option>
                                    <Option value="LANDER SIMULATION & TRAINING SOLUTIONS SA">LANDER SIMULATION & TRAINING SOLUTIONS SA</Option>
                                    <Option value="LDR SOLUTIONS SA DE CV">LDR SOLUTIONS SA DE CV</Option>
                                    <Option value="LIS TECNOLOGIA EN LOGISTICA SA DE CV">LIS TECNOLOGIA EN LOGISTICA SA DE CV</Option>
                                    <Option value="LIVERPOOL SA DE CV">LIVERPOOL SA DE CV</Option>
                                    <Option value="LOGISTICA LEX  S DE RL DE CV">LOGISTICA LEX  S DE RL DE CV</Option>
                                    <Option value="MABE, S.A. DE C.V.">MABE, S.A. DE C.V.</Option>
                                    <Option value="MAFR CONSULTORIA ESTRATEGICA SC">MAFR CONSULTORIA ESTRATEGICA SC</Option>
                                    <Option value="MAN TRUCK& BUS MÉXICO, S.A. DE CV">MAN TRUCK& BUS MÉXICO, S.A. DE CV</Option>
                                    <Option value="MANUFACTURAS INDUSTRIALES GAMI, S.A. DE CV">MANUFACTURAS INDUSTRIALES GAMI, S.A. DE CV</Option>
                                    <Option value="MATERIAS PRIMAS DE MONTERREY SA DE CV">MATERIAS PRIMAS DE MONTERREY SA DE CV</Option>
                                    <Option value="MECANICA CIENTIFICOS DE MÉXICO S.A DE C.V.">MECANICA CIENTIFICOS DE MÉXICO S.A DE C.V.</Option>
                                    <Option value="MERITOR MEXICO, S DE RL DE CV">MERITOR MEXICO, S DE RL DE CV</Option>
                                    <Option value="METALURGICA MET MEX PEÑOLES, S.A. DE C.V.">METALURGICA MET MEX PEÑOLES, S.A. DE C.V.</Option>
                                    <Option value="METRICA MOVIL S.A DE C.V">METRICA MOVIL S.A DE C.V</Option>
                                    <Option value="MEXITRANS S.A. DE C.V ">MEXITRANS S.A. DE C.V </Option>
                                    <Option value="MF VEHICULOS ELECTRICOS S.A DE C.V.">MF VEHICULOS ELECTRICOS S.A DE C.V.</Option>
                                    <Option value="MICHELIN SASCAR MEXICO SA DE CV (COPILOTO SATELITAL)">MICHELIN SASCAR MEXICO SA DE CV (COPILOTO SATELITAL)</Option>
                                    <Option value="MULTISERVICIOS  VALLE DEL TANGAMANGA SA DE CV">MULTISERVICIOS  VALLE DEL TANGAMANGA SA DE CV</Option>
                                    <Option value="NAVISTAR MÉXICO, S DE R.L. DE CV">NAVISTAR MÉXICO, S DE R.L. DE CV</Option>
                                    <Option value="NAVMAN WIRELESS DE MEXICO S. DE R.L. DE C.V.">NAVMAN WIRELESS DE MEXICO S. DE R.L. DE C.V.</Option>
                                    <Option value="NEWTIMES SA DE CV">NEWTIMES SA DE CV</Option>
                                    <Option value="NISSAN MEXICANA SA DE CV">NISSAN MEXICANA SA DE CV</Option>
                                    <Option value="OMNISOURCE MEXICO SA DE CV">OMNISOURCE MEXICO SA DE CV</Option>
                                    <Option value="OMNITRACS DE MEXICO SA DE CV">OMNITRACS DE MEXICO SA DE CV</Option>
                                    <Option value="OPERADORA DE SERVICIOS PAQUEXPRESS SA DE CV">OPERADORA DE SERVICIOS PAQUEXPRESS SA DE CV</Option>
                                    <Option value="PRAXAIR MÉXICO, S DE R.L. DE CV">PRAXAIR MÉXICO, S DE R.L. DE CV</Option>
                                    <Option value="QUIMMCO CENTRO TECNOLOGICO  SA DE CV">QUIMMCO CENTRO TECNOLOGICO  SA DE CV</Option>
                                    <Option value="RECURSO CONFIABLE, S.A. DE CV">RECURSO CONFIABLE, S.A. DE CV</Option>
                                    <Option value="REDPACK, S.A. DE CV">REDPACK, S.A. DE CV</Option>
                                    <Option value="RITCHIE BROSS AUCTIONEERS DE MEXICO S. DE R.L. DE C.V">RITCHIE BROSS AUCTIONEERS DE MEXICO S. DE R.L. DE C.V</Option>
                                    <Option value="SAF-HOLLAND INTERNATIONAL DE MÉXICO, S DE R.L. DE CV">SAF-HOLLAND INTERNATIONAL DE MÉXICO, S DE R.L. DE CV</Option>
                                    <Option value="SAMSARA NETWORKS MEXICO S DE R.L. DE C.V.">SAMSARA NETWORKS MEXICO S DE R.L. DE C.V.</Option>
                                    <Option value="SANCHEZ Y MARTIN S.A. DE C.V">SANCHEZ Y MARTIN S.A. DE C.V</Option>
                                    <Option value="SCANIA COMERCIAL, S.A. DE C.V.">SCANIA COMERCIAL, S.A. DE C.V.</Option>
                                    <Option value="SECONMEX ADMINISTRACION SA DE CV">SECONMEX ADMINISTRACION SA DE CV</Option>
                                    <Option value="SERVICIOS COMERCIALES AMAZON MÉXICO S. DE R.L. DE C.V.">SERVICIOS COMERCIALES AMAZON MÉXICO S. DE R.L. DE C.V.</Option>
                                    <Option value="SERVICIOS DE OPERACIÓN Y DISTRIBUCIÓN, S.A. DE CV (Kimberly Clark)">SERVICIOS DE OPERACIÓN Y DISTRIBUCIÓN, S.A. DE CV (Kimberly Clark)</Option>
                                    <Option value="SERVICIOS DE TRANSPORTES CAD SA DE CV">SERVICIOS DE TRANSPORTES CAD SA DE CV</Option>
                                    <Option value="SERVICIOS OPERATIVOS DE AUTOPISTAS SA DE CV">SERVICIOS OPERATIVOS DE AUTOPISTAS SA DE CV</Option>
                                    <Option value="SETRAMEX, S.A. DE CV">SETRAMEX, S.A. DE CV</Option>
                                    <Option value="SHACMAN MÉXICO">SHACMAN MÉXICO</Option>
                                    <Option value="SIGMA ALIMENTOS NORESTE, S.A. DE CV">SIGMA ALIMENTOS NORESTE, S.A. DE CV</Option>
                                    <Option value="SISTEMAS KINEDYNE, S.A. DE CV">SISTEMAS KINEDYNE, S.A. DE CV</Option>
                                    <Option value="SOLTRALOG SA DE CV">SOLTRALOG SA DE CV</Option>
                                    <Option value="SOLUCIONES INTEGRALES LOYALTY SA DE CV">SOLUCIONES INTEGRALES LOYALTY SA DE CV</Option>
                                    <Option value="SOLUCIONES INTEGRALES PARA TRITURACION SA DE CV">SOLUCIONES INTEGRALES PARA TRITURACION SA DE CV</Option>
                                    <Option value="SOLUCIONES WAYPOINT MÉXICO S.A DE C.V.">SOLUCIONES WAYPOINT MÉXICO S.A DE C.V.</Option>
                                    <Option value="SWEDISH STEEL AB MEXICO SA DE CV">SWEDISH STEEL AB MEXICO SA DE CV</Option>
                                    <Option value="TDR TRANSPORTES, S.A. DE CV">TDR TRANSPORTES, S.A. DE CV</Option>
                                    <Option value="TECNOMOTUM SA DE CV">TECNOMOTUM SA DE CV</Option>
                                    <Option value="TERNIUM S.A. DE CV">TERNIUM S.A. DE CV</Option>
                                    <Option value="TIP DE MÉXICO, S.A.P.I. DE C.V.">TIP DE MÉXICO, S.A.P.I. DE C.V.</Option>
                                    <Option value="TRANCASA">TRANCASA</Option>
                                    <Option value="TRANSPORTADORA ALPURA, SAPI DE CV">TRANSPORTADORA ALPURA, SAPI DE CV</Option>
                                    <Option value="TRANSPORTES ELOLA">TRANSPORTES ELOLA</Option>
                                    <Option value="TRANSPORTES ESPECIALIZADOS DEL RIO FUERTE, S DE R.L. DE CV">TRANSPORTES ESPECIALIZADOS DEL RIO FUERTE, S DE R.L. DE CV</Option>
                                    <Option value="TRANSPORTES GAS DE MÉXICO, S.A. DE CV">TRANSPORTES GAS DE MÉXICO, S.A. DE CV</Option>
                                    <Option value="TRANSPORTES JACONA S.A. DE C.V.">TRANSPORTES JACONA S.A. DE C.V.</Option>
                                    <Option value="TRANSPORTES NARCEA, S.A. DE CV">TRANSPORTES NARCEA, S.A. DE CV</Option>
                                    <Option value="TRANSYOGGO, S.A. DE CV">TRANSYOGGO, S.A. DE CV</Option>
                                    <Option value="TRATE DE OCCIDENTE S.A. DE C.V.">TRATE DE OCCIDENTE S.A. DE C.V.</Option>
                                    <Option value="VOLVO DISTRIBUIDORA DE MÉXICO, S.A. DE CV">VOLVO DISTRIBUIDORA DE MÉXICO, S.A. DE CV</Option>
                                    <Option value="XIGNUX CORPORATIVO SA DE CV">XIGNUX CORPORATIVO SA DE CV</Option>
                                    <Option value="OTRA">OTRA</Option>
                                </Select>
                            </Form.Item>*/}
                            {otra_empresa &&
                                <Form.Item
                                    name="otra_empresa"
                                    label={<span style={{ color: '#112f6d' }}>¿Cúal?</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa tu empresa!',
                                        },
                                    ]}
                                >
                                    <Input style={{ backgroundColor: '#F5F8F5' }} />
                                </Form.Item>
                            }
                            
                            <Form.Item
                                name="cargo"
                                label={<span style={{ color: '#112f6d' }}>Cargo</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu cargo!',
                                    },
                                ]}
                            >
                                <Input style={{ backgroundColor: '#F5F8F5' }} />
                            </Form.Item>
                            <Form.Item
                                name="telefono"
                                label={<span style={{ color: '#112f6d' }}>Teléfono</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa tu teléfono!',
                                    },
                                ]}
                            >
                                <Input style={{ backgroundColor: '#F5F8F5' }} />
                            </Form.Item>
                            


                            {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                            <Form.Item>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <br />
                                        <button htmlType="submit" style={{ backgroundColor: 'white', border: '1px solid white', padding: '0px' }} >
                                            <img style={{ width: '200px', padding: '0px', cursor: 'pointer' }} src={img_entrar} alt="Entrar" />
                                        </button>
                                        <br />
                                        <span style={{ color: 'blue', cursor: 'pointer', display: 'none' }} onClick={() => setMostrarRecuperar(true)}>¿Olvidaste tu contraseña?</span>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'center', display: 'none' }}>
                                        <br />
                                        <span style={{ color: '#112f6d' }}><strong>¿No tienes cuenta?</strong></span>
                                        <br />
                                        <button onClick={() => { history.push("/registro/aplicacion-nom-012"); }} style={{ backgroundColor: 'white', border: '1px solid white', padding: '0px' }} >
                                            <img style={{ width: '200px', cursor: 'pointer' }} src={img_crear} alt="Entrar" />
                                        </button>
                                    </Col>
                                </Row>

                            </Form.Item>

                        </Form>

                    </Col>
                </Row>
            )
            :
            (
                <></>
            )

    );
};
export default withRouter(LoginVotaciones);

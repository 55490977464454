import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Upload,
    Checkbox,
    Button, notification, Spin
} from 'antd';
import { withRouter, useLocation } from "react-router";

import ReCAPTCHA from "react-google-recaptcha";
import Firebase from "../../utils/firebaseConfig";


import { useMatch, useNavigate, useParams } from "react-router-dom";

import { registro_votacion, codigo, paquete, SERVER_URL } from '../../utils/ConexionServidor';
import banner_registro from '../../assets/images/temporal/1920x200.png';
import banner_registro_movil from '../../assets/images/temporal/1920x200.png';

import logo_banner from '../../assets/images/evento/logos/LOGO_contruyendo_banner_bco.png';
import info_banner from '../../assets/images/evento/logos/iconos y fecha_principal.png';
import info_banner_en from '../../assets/images/evento/ingles/fecha y lugar_eng.png';


import { CaretLeftOutlined, CaretRightOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import Whatsapp from "../master/Whatsapp";
import Categorias from "./Categorias";

import logo from '../../assets/images/evento/votar/Sume_LogoPrincipal.png';

const { Option } = Select;

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const app = new Firebase();

const RegistroVotos = ({ history, onResponse, onResponseCerrarLogin, idioma }) => {


    const [datos_registro, setDatosRegistro] = useState([]);
    const [confirmacion, setConfirmacion] = useState(false);
    const [ver_total, setTotal] = useState(0);
    const [facturar, setFacturar] = useState(false);
    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const nom_evento = "construyendo-futuro";

    const ref_inputxs = useRef(null);
    const ref_inputmd = useRef(null);

    //const [form] = Form.useForm();

    const ref_form = React.createRef();
    const ref_recaptcha = React.createRef();

    const params = useParams();

    useEffect(() => {
        if (datos_registro.length > 0) {
          setConfirmacion(true);
        }
      }, [datos_registro]);

    const onFailed = async values => {

        switch (values.errorFields[0].name[0]) {
            case 'correo': mensaje('warning', idioma === 'es' ? 'Completa el campo correo electrónico' : 'Fill in the email field', ''); break;
            case 'nombre': mensaje('warning', idioma === 'es' ? 'Completa el campo nombre' : 'Fill in the name field', ''); break;
            case 'ap_paterno': mensaje('warning', idioma === 'es' ? 'Completa el campo apellido paterno' : 'Complete the paternal surname field', ''); break;
            case 'ap_materno': mensaje('warning', idioma === 'es' ? 'Completa el campo apellido materno' : 'Complete the maternal surname field', ''); break;
           default: mensaje('warning', idioma === 'es' ? 'Faltan campos' : 'Fields are missing', idioma === 'es' ? 'Por favor rellena todos los campos' : 'Please fill in all the fields'); break;

        }
    }

    const onFinish = async values => {

        localStorage.setItem('usuario',JSON.stringify(values));
        /*app.obtener_objeto(nom_evento+ '/usuarios', 'correo',values.correo, (data)=>{
            if(!data){
                app.escribir(nom_evento + '/usuarios', undefined, values, (data) => {
                });
            }
            app.app.auth().signInAnonymously().then(()=>{
                mensaje('success', 'Bienvenido', '');
                onResponse();
            }).catch((error) => {
                mensaje('error', 'Error al cargar usuario, inténtalo más tarde', '');
            });
        });

        if (ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' ') {
            mensaje('warning', idioma === 'es' ? 'Recaptcha inválido' : 'Invalid recaptcha', idioma === 'es' ? 'Por favor verifica tu identidad' : 'Please verify your identity');
            return;
        }

        values.subtotal = ver_total;
        values["g-recaptcha-response"] = ref_recaptcha.current.getValue();*/

        const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
        //values.password = values.password.split('').map(letra => acentos[letra] || letra).join('').toString().toLowerCase();

        values.idioma = idioma;
        values.nombre_curso = nom_evento;

        console.log(values);

        setCargarPantalla(true);

        registro_votacion(values).then(({ data, status }) => {
            switch (status) {
                case 0:
                    mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha sido exitoso' : 'We send you a registration confirmation email to the email' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 1:
                    mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 2:
                    mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
                    break;
                default:
                    mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
                    break;
            }
            setCargarPantalla(false);
        });

    };

    return (

        <>
            <Spin spinning={cargar_pantalla} delay={0}>
                {confirmacion ?
                    <Categorias />
                    :
                    <Row className="contenedor_registro_votacion" justify={'center'}>
                        <Col xs={8} md={8}>
                            <img src={logo} style={{width:'300px'}} />
                        </Col>
                        <Col span={18}>
                            <Form className="login-form-"
                                layout="vertical"
                                name="basic"
                                initialValues={{
                                    factura: facturar,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFailed}
                                ref={ref_form}
                            >

                                <Row>

                                    <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }}>

                                    <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="correo"
                                                    rules={idioma === 'es' ?
                                                        [
                                                            {
                                                                type: 'email',
                                                                message: '...',
                                                            },
                                                            {
                                                                required: true,
                                                                message: <span className="label_requerido_registro">Ingresa tu correo electrónico !</span>,
                                                            },
                                                        ] :
                                                        [
                                                            {
                                                                type: 'email',
                                                                message: '...',
                                                            },
                                                            {
                                                                required: true,
                                                                message: <span className="label_requerido_registro">Enter your email!</span>,
                                                            },
                                                        ]}
                                                >
                                                    <Input className="inputs_registro" placeholder={idioma === 'es' ? "Correo electrónico" : "Email"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="nombre"
                                                    rules={idioma === 'es' ? [

                                                        {
                                                            required: true,
                                                            message: <span className="label_requerido_registro">Ingresa tu nombre!</span>,
                                                        },
                                                    ] :
                                                        [{
                                                            required: true,
                                                            message: <span className="label_requerido_registro">Enter your name!</span>,
                                                        },
                                                        ]}
                                                >
                                                    <Input className="inputs_registro" placeholder={idioma === 'es' ? "Nombre(s)" : "Name"} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} md={{ span: 11 }}>
                                                <Form.Item
                                                    name="ap_paterno"
                                                    rules={idioma === 'es' ?
                                                        [

                                                            {
                                                                required: true,
                                                                message: <span className="label_requerido_registro">Ingresa tu apellido paterno!</span>,
                                                            },
                                                        ] :
                                                        [

                                                            {
                                                                required: true,
                                                                message: <span className="label_requerido_registro">Enter your paternal last name!</span>,
                                                            },
                                                        ]}
                                                >
                                                    <Input className="inputs_registro" placeholder={idioma === 'es' ? "Apellido paterno" : "Last name"} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                                                <Form.Item
                                                    name="ap_materno"
                                                    rules={idioma === 'es' ?
                                                        [

                                                            {
                                                                required: true,
                                                                message: <span className="label_requerido_registro">Ingresa tu apellido materno!</span>,
                                                            },
                                                        ] :
                                                        [

                                                            {
                                                                required: true,
                                                                message: <span className="label_requerido_registro">Enter your maternal last name!</span>,
                                                            },
                                                        ]}
                                                >
                                                    <Input className="inputs_registro" placeholder={idioma === 'es' ? "Apellido materno" : "Last Name"} />
                                                </Form.Item>
                                            </Col>
                                            
                                            <Col span={24}>
                                                <hr style={{ border: 'none', backgroundColor: '#97B623', height: '6px' }} />
                                                
                                            </Col>

                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Form.Item
                                                    name="aceptar_terminos"
                                                    valuePropName="checked"
                                                    rules={idioma === 'es' ?
                                                        [
                                                            {
                                                                validator: (_, value) =>
                                                                    value ? Promise.resolve() : Promise.reject(<span className="label_requerido_registro">Acepta el aviso de privacidad</span>),
                                                            },
                                                        ] :
                                                        [
                                                            {
                                                                validator: (_, value) =>
                                                                    value ? Promise.resolve() : Promise.reject(<span className="label_requerido_registro">Accept the privacy notice</span>),
                                                            },
                                                        ]}
                                                >
                                                    <Checkbox>
                                                        {idioma === 'es' ?
                                                            <span className="texto_label_form color_blanco">He leido y acepto el <strong><a style={{ color: '#0055cd' }} target="_blank" href="https://www.sume.org.mx/about-6">aviso de privacidad.</a></strong></span>
                                                            :
                                                            <span className="texto_label_form">I have read and accept the <strong><a style={{ color: '#0055cd' }} target="_blank" href="https://www.sume.org.mx/about-6">aotice of privacy.</a></strong></span>}

                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                    <Col span={24}>

                                        <br /><br />
                                        <Row justify="center" align="middle" className="contenedor_totales-">

                                            <Col span={24}>
                                                <Form.Item>

                                                    <p style={{ textAlign: 'center', display: 'non' }}>
                                                        <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType="submit">
                                                            <p className="btn_registrate_home"><strong>{idioma === 'es' ? 'VOTAR' : 'SING UP'}</strong></p>
                                                        </button>
                                                    </p>

                                                </Form.Item>

                                            </Col>
                                        </Row>
                                    </Col>


                                </Row>

                            </Form>
                            <br /><br /><br /><br /><br /><br /><br /><br /><br />
                        </Col>
                    </Row>
                }
            </Spin>
        </>

    );
};

export default withRouter(RegistroVotos);

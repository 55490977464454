import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Upload, Checkbox, Button, notification, Spin, Radio, Image } from 'antd';
import '../../assets/css/proyectos.css';

import { Link } from 'react-router-dom';
import Firebase from '../../utils/firebaseConfig';
import { guardar_votos } from '../../utils/ConexionServidor';
import Item from 'antd/lib/list/Item';

import proyecto_1_pdf from '../../assets/proyectos/16 NEP TIJUANA/ANEXO_1_PREMIOS_IMPULSO_SUME_BY_SAINT_GOBAIN_2024_NEP.pdf';
import proyecto_1_img1 from '../../assets/proyectos/16 NEP TIJUANA/1.jpg';
import proyecto_1_img2 from '../../assets/proyectos/16 NEP TIJUANA/2.jpg';
import proyecto_1_img3 from '../../assets/proyectos/16 NEP TIJUANA/3.jpg';
import proyecto_1_img4 from '../../assets/proyectos/16 NEP TIJUANA/4.jpg';
import proyecto_1_img5 from '../../assets/proyectos/16 NEP TIJUANA/5.jpg';


const app = new Firebase();

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
    });
}

const Transito = ({ history, onResponse, onResponseCerrarLogin, idioma, categoria, setCategoria }) => {

    const [cargar_pantalla, setCargarPantalla] = useState(false);
    const [datos_registro, setDatosRegistro] = useState([]);
    const [value1, setValue1] = useState(1);
    const [valor,setValor] = useState(0);
    const datos = JSON.parse(localStorage.getItem('usuario'));
    const onChange1 = (e) => {
      console.log('radio checked', e.target.value);
      setValue1(e.target.value);
    };
      console.log(datos.correo);
    const ref_form = React.createRef();

    const onFinish = async values => {

        localStorage.setItem('voto3', JSON.stringify(valor));

        console.log(values);
        values.tipo = categoria;
        values.correo = datos.correo;
        values.categoria3 = valor;

        setCargarPantalla(true);

        guardar_votos(values).then(({ data, status }) => {
            switch (status) {
                case 0:
                    mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu voto ha sido enviado' : 'Tu voto ha sido enviado', + data[3]);
                    setDatosRegistro(data);
                    break;
                case 1:
                    mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
                    setDatosRegistro(data);
                    break;
                case 2:
                    mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
                    break;
                default:
                    mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
                    break;
            }
            setCargarPantalla(false);
        });
        setCategoria(categoria+1);

    };


    const proyectos = [
        {id:1, nombre:'Nuevo Edificio Procesador de Pasajeros (NEP) del Aeropuerto Internacional de Tijuana', desc:[<>El Nuevo Edificio Procesador del Aeropuerto Internacional de Tijuana, ubicado en Aeropuerto S/N, Nueva Tijuana, 22435 Tijuana, B.C., desarrollado en su etapa conceptual por el despacho de Estudio Lamela (España) y posteriormente llevado a la realidad a través del desarrollo de proyecto constructivo por parte del Arquitecto mexicano Juan Pablo Serrano de Serrano Monjaraz + WSP México, con cerca de 40,000 m2 distribuidos en 4 niveles, fue concebido con el fin de optimizar el proceso de ingreso de pasajeros nacionales e internacionales, ya sea a México o a los Estados Unidos de América, y brindar una experiencia de alto nivel a los pasajeros que utilizan el Aeropuerto.<br /><br />
        El mayor desafío para el equipo del proyecto fue lograr un partido arquitectónico y un flujo de pasajeros eficiente, a través de cada uno de los subsistemas aeroportuarios en un edificio de cuatro niveles; debía ser una instalación que limitara cuidadosamente el flujo de pasajeros y lo guiara de manera intuitiva a través de los distintos procesos que debe llevar a cabo. Todo lo anterior integrado a la arquitectura de los edificios existentes, el Cross Border Xpress y el Edificio Terminal.<br /><br />
        El edificio siguiendo el principio “form follows function” (Louis H. Sullivan, 1896), a través de una arquitectura lineal, sobria y elegante, con una materialidad en tonos neutros y espacios interiores con dobles alturas e iluminación natural, brindan al pasajero una percepción espacial inigualable, haciendo satisfactoria su experiencia de principio a fin. Cuenta con un esqueleto estructural de más de 6,000 toneladas de acero y 8,000 metros cúbicos de hormigón; 18,000 m2 de muro cortina y vidrios fix point, así como 20,000 m2 de panel composite de aluminio.
        A pesar de estar conectado físicamente al Edificio Principal de Tijuana, este edificio cuenta con todo el equipamiento necesario para el procesamiento de pasajeros:
        <ul>
            <li>Un sistema de manejo de equipaje.</li>
            <li>Un Equipo de Inspección de equipaje facturado (incluyendo tomografía de detección de explosivos).</li>
            <li>Líneas de revisión para artículos de mano con máquinas de rayos X y retorno automático de bandejas (5 totalmente automáticos y 1 manual).</li>
            <li>7 carriles de Aduana y 18 puntos de control de Inmigración.</li>
            <li>52 mostradores de Check - in Internacional y doméstico.</li>
        </ul>
        El programa del edificio se desglosa de manera general de la siguiente manera:
        <ol type='1'>
            <li>Segundo piso – Flujo de Pasajeros Nacionales:</li>
                <ul>
                    <li>Área de cambio de moneda y cajeros automáticos</li>
                    <li>Inmigración</li>
                    <li>Aduana</li>
                    <li>Documentación Nacional</li>
                </ul>
            <li>Primer piso – Flujo de Pasajeros Internacionales:</li>
                <ul>
                    <li>Área de Sala de Espera para Pasajeros en Tránsito</li>
                    <li>Corredor al Dique B (Puertas 10 y 12)</li>
                </ul>
            <li>Planta Baja</li>
                <ul>
                    <li>Punto de Inspección de pasajeros</li>
                    <li>Pasillo de conexión a Dique B (Puertas 10-18) o Dique A (puertas 1- 9)</li>
                </ul>
            <li>Sótano</li>
                <ul>
                    <li>Equipos MEP</li>
                    <li>Equipo BHS (Sistema de manejo de equipaje)</li>
                    <li>2 Puertas de embarque (19 y 20)</li>
                </ul>
        </ol>
        Se buscó la certificación LEED, mediante la integración de prácticas sustentables, permite:
        <ul>
            <li>Aumentar la calidad de vida de los usuarios.</li>
            <li>Dar un valor agregado al proyecto.</li>
            <li>Promover la biodiversidad y los servicios del ecosistema.</li>
            <li>Generar ahorros sustanciales en gastos de operación y mantenimiento.</li>
            <li>Reducir el impacto ambiental del edificio.</li>
            <li>Disminuir las emisiones de CO2.</li>
            <li>Disminuir el consumo energético y de agua.</li>
            <li>Reducir la cantidad de residuos que se envían a vertederos.</li>
        </ul>
        </>],
            documento:proyecto_1_pdf,
        imagenes:[
            {indes:1, url:proyecto_1_img1,tipo:1},
            {indes:2, url:proyecto_1_img2,tipo:2},
            {indes:3, url:proyecto_1_img3,tipo:2},
            {indes:4, url:proyecto_1_img4,tipo:2},
            {indes:5, url:proyecto_1_img5,tipo:2},
        ],
        certificacion:'LEED v4: BUILDING DESIGN AND CONSTRUCTION: NEW CONSTRUCTION AND MAJOR RENOVATIONS, GOLD'
    }
    ];

    const ver_proyectos = proyectos.map((item) => {
        return(
            <>
            <Row className='contenedor_pryecto'>
                <Col  xs={22} lg={8}>
                    <Row justify={'center'}>
                    {item.imagenes.map(image => {
                        if(image.indes === 1){
                            return(
                                <>
                                <Col span={24}>
                                    <Image
                                    src={image.url}
                                    className='img_grande'
                                    placeholder={
                                    <Image
                                        preview={false}
                                        src={image.url}
                                        style={{width:'80%'}}
                                    />}
                                    />
                                </Col>
                                </>
                            );
                        }else{
                            return(
                                <>
                                <Col span={5}>
                                    <br />
                                    <Image
                                    className='img_peque'
                                    src={image.url}
                                    placeholder={
                                    <Image
                                        preview={false}
                                        src={image.url}
                                        style={{width:'80%'}}
                                    />}
                                    />
                                </Col>
                                </>
                            );
                        }
                    })}
                    </Row>
                </Col>
                <Col  xs={22} lg={16}>
                    <p className='titulo_proyecto'>{item.nombre}</p>
                    <p className='desc_proyecto'><strong>Certificación: </strong>{item.categoria}</p>
                    <p className='desc_proyecto'>{item.desc}</p>
                    <Row>
                    <Col xs={0} lg={0}><br />
                            <p>
                            <Link className='btn_proyecto' to={item.documento} target='_blank'>Ver proyecto completo </Link>
                        </p>
                            </Col>
                            <Col xs={24} lg={12}><br />
                        <span className={valor !== 0 ? (valor === item.id ? 'btn_votar_select' : 'btn_votar') : 'btn_noselect'} onClick={() => setValor(item.id)}>
                        SELECCIONAR
                        </span>
                            </Col>
                        </Row>
                </Col>
            </Row>
            </>
        )
    });
    return (
        <>
            <Row justify={'center'}>
                        <Col span={20}>
                            <Form className="login-form"
                                layout="vertical"
                                name="basic"
                                onFinish={onFinish}
                                ref={ref_form}
                            >
                                <Row>
                                    <Col span={24}><br /><br />
                                        <p className='titulo_objeticos color_azul fuente' style={{textAlign:'center'}}>EDIFICIOS DE TRÁNSITO</p>
                                    </Col>
                                    <Col span={24}>
                                        {ver_proyectos}
                                    </Col>
                                    <Col span={24}>
                                                <Form.Item>
                                                <br /><br />
                                                    <p style={{ textAlign: 'center', display: 'non' }}>
                                                        <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType="submit">
                                                            <p className="btn_registrate_home"><strong>ENVIAR VOTO</strong></p>
                                                        </button>
                                                    </p>

                                                </Form.Item>

                                            </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
        </>
    )
}
export default Transito;
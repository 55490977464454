import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Upload,
  Checkbox,
  Button, notification, Spin
} from 'antd';
import { withRouter, useLocation } from "react-router";

import ReCAPTCHA from "react-google-recaptcha";
import RegistroCompleto from './RegistroCompleto';

import { useMatch, useNavigate, useParams } from "react-router-dom";

import { mail_registro_confirmacion, codigo, paquete, SERVER_URL } from '../../utils/ConexionServidor';
import banner_registro from '../../assets/images/evento/registro/ConstruyendoElFuturo_bannerMAIL.jpg';
import banner_registro_movil from '../../assets/images/evento/registro/ConstruyendoElFuturo_banner_movil.jpg';

import img_paquete_1 from '../../assets/images/evento/registro/precios/entradageneral_registro_bco.png';
import img_paquete_1_hover from '../../assets/images/evento/registro/precios/entradageneral_registro_verde.png';
import img_paquete_2 from '../../assets/images/evento/registro/precios/miembrosume_registro_bco.png';
import img_paquete_2_hover from '../../assets/images/evento/registro/precios/miembrosume_registro_verde.png';

import img_paquete_1_en from '../../assets/images/evento/registro/ingles/general_b_eng.png';
import img_paquete_1_hover_en from '../../assets/images/evento/registro/ingles/general_v_eng.png';
import img_paquete_2_en from '../../assets/images/evento/registro/ingles/sume_b_eng.png';
import img_paquete_2_hover_en from '../../assets/images/evento/registro/ingles/sume_v_eng.png';


import img_visita from '../../assets/images/evento/registro/visita_b.png';
import img_visita_hover from '../../assets/images/evento/registro/visita_v.png';
import img_curso_1 from '../../assets/images/evento/registro/precios/true_b.png';
import img_curso_1_hover from '../../assets/images/evento/registro/precios/true_v.png';
import img_curso_1_hover_sume from '../../assets/images/evento/registro/precios/truesume_v.png';
import img_curso_1_sume from '../../assets/images/evento/registro/precios/truesume_b.png';
import img_curso_2 from '../../assets/images/evento/registro/precios/leed_registro_b.png';
import img_curso_2_hover from '../../assets/images/evento/registro/precios/leed_registro_v.png';
import img_curso_2_sume from '../../assets/images/evento/registro/precios/leed_registro_sume_b.png';
import img_curso_2_hover_sume from '../../assets/images/evento/registro/precios/leed_registro_sume_v.png';

import img_visita_en from '../../assets/images/evento/registro/ingles/visita_b_eng.png';
import img_visita_hover_en from '../../assets/images/evento/registro/ingles/visita_v_eng.png';
import img_curso_1_en from '../../assets/images/evento/registro/ingles/true_b_eng.png';
import img_curso_1_hover_en from '../../assets/images/evento/registro/ingles/ltrue__v_eng.png';
import img_curso_1_hover_sume_en from '../../assets/images/evento/registro/ingles/true_sume_v_eng.png';
import img_curso_1_sume_en from '../../assets/images/evento/registro/ingles/true_sume_b_eng.png';
import img_curso_2_en from '../../assets/images/evento/registro/ingles/preciosReg_leed_febENG_b.png';
import img_curso_2_hover_en from '../../assets/images/evento/registro/ingles/preciosReg_leed_febENG_V.png';
import img_curso_2_sume_en from '../../assets/images/evento/registro/ingles/preciosReg_leedSUME_febENG_b.png';
import img_curso_2_hover_sume_en from '../../assets/images/evento/registro/ingles/preciosReg_leedSUME_febENG_v.png';


import logo_banner from '../../assets/images/evento/logos/LOGO_contruyendo_banner_bco.png';
import info_banner from '../../assets/images/evento/logos/iconos y fecha_principal.png';
import info_banner_en from '../../assets/images/evento/ingles/fecha y lugar_eng.png';

import agregar from '../../assets/images/evento/registro/btn_agregar.png';
import quitar from '../../assets/images/evento/registro/btn_quitar.png';

import agregar_en from '../../assets/images/evento/ingles/add_.png';
import quitar_en from '../../assets/images/evento/ingles/REMOVE_.png';

import { CaretLeftOutlined, CaretRightOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import Whatsapp from "../master/Whatsapp";


const { Option } = Select;

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

let url_file = "";

const Registro = (props) => {

  console.log(props);

  const location = useLocation();

  const [error, seterror] = useState("");
  const [otro_medio, setOtroMedio] = useState(false);
  const [datos_registro, setDatosRegistro] = useState([]);
  const [confirmacion, setConfirmacion] = useState(false);
  const [paquete_elegido, setPaqueteElegido] = useState(0);
  const [ver_total, setTotal] = useState(0);
  const [select_imagen, setSelectImagen] = useState([false, false, false, false]);
  const [porcentaje_codigo, setPorcentajeCodigo] = useState(0);
  const [paquetes, setPaquetes] = useState([]);
  const [facturar, setFacturar] = useState(false);
  const [cargar_pantalla, setCargarPantalla] = useState(false);
  const { idioma, setMostrarLogin, cerrarMenu } = props;
  const nom_evento = "construyendo-futuro";
  const [numero, setNumero] = useState(0);
  

  const [valor_codigo, setValorCodigo] = useState(0);
  const [select_entrada, setSelectEntrada] = useState(0);
  const [select_visita, setSelectVisita] = useState(0);
  const [select_curso, setSelectCurso] = useState(0);
  const [select_miembro, setSelectMiembro] = useState(0);

  const ref_inputxs = useRef(null);
  const ref_inputmd = useRef(null);

  //const [form] = Form.useForm();

  const ref_form = React.createRef();
  const ref_recaptcha = React.createRef();

  let normFile = (e) => {

    //url_file = e.file.response.data;

    if (e.file.response !== undefined) {
      console.log('Upload event:', e.file.response.data);
      url_file = e.file.response.data;
    }

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const cambiarHoverImagen = (index) => {
    let arreglo = [];
    for (let i = 0; i < 4; i++) {
      if (i === index) {
        arreglo[i] = true;
      } else {
        arreglo[i] = false;
      }
    }
    setSelectImagen(arreglo);
  }

  //

  useEffect(() => {
    paquete().then(({ data, status }) => {
      switch (status) {
        case 0:
          setPaquetes(data)
          break;
        default:
          mensaje('error', 'Error de conexión');
          break;
      }
    })
  }, []);

  const params = useParams();

 

  

  useEffect(() => {
    if (paquete_elegido !== 0 && paquetes.length > 0) {
      setTotal(paquetes[paquete_elegido - 1].precio)//sumar precios
        cambiarHoverImagen(paquete_elegido - 1);
    }
  }, [paquetes, paquete_elegido]);
  useEffect(() => {
    if(numero === 1){
    }
  }, []);

  useEffect(() => {

    if(idioma === 'es'){
      if(select_entrada === 1){
        setTotal(paquetes[0].precio);
        if(select_visita !== 0){
          setTotal(paquetes[0].precio+paquetes[2].precio);
        }else{
          if(select_curso === 1){
            setTotal(paquetes[0].precio+paquetes[3].precio);
          }else if(select_curso === 2){
            setTotal(paquetes[0].precio+paquetes[4].precio);
          }else if(select_curso === 3){
            setTotal(paquetes[0].precio+paquetes[5].precio);
          }else if(select_curso === 4){
            setTotal(paquetes[0].precio+paquetes[6].precio);
          }
        }
      }else if (select_entrada === 2) {
        setTotal(paquetes[1].precio);
        if(select_visita !== 0){
          setTotal(paquetes[1].precio+paquetes[2].precio);
          if(select_curso === 1){
            setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
          }else if(select_curso === 2){
            setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
          }else if(select_curso === 3){
            setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
          }else if(select_curso === 4){
            setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
          }
        }else{
          if(select_curso === 1){
            setTotal(paquetes[1].precio+paquetes[3].precio);
          }else if(select_curso === 2){
            setTotal(paquetes[1].precio+paquetes[4].precio);
          }else if(select_curso === 3){
            setTotal(paquetes[1].precio+paquetes[5].precio);
          }else if(select_curso === 4){
            setTotal(paquetes[1].precio+paquetes[6].precio);
          }
        }
      } else {
        if(numero === 0){
          setTotal(0);
        }
        if(select_visita !== 0){
          setTotal(paquetes[2].precio);
          if(select_curso === 1){
            setTotal(paquetes[2].precio+paquetes[3].precio);
          }else if(select_curso === 2){
            setTotal(paquetes[2].precio+paquetes[4].precio);
          }else if(select_curso === 3){
            setTotal(paquetes[2].precio+paquetes[5].precio);
          }else if(select_curso === 4){
            setTotal(paquetes[2].precio+paquetes[6].precio);
          }
        }else{
          if(select_curso === 1){
            setTotal(paquetes[3].precio);
          }else if(select_curso === 2){
            setTotal(paquetes[4].precio);
          }else if(select_curso === 3){
            setTotal(paquetes[5].precio);
          }else if(select_curso === 4){
            setTotal(paquetes[6].precio);
          }
        }
      }
    }else{
      if(select_entrada === 1){
        setTotal(paquetes[0].precio_usd);
        if(select_visita !== 0){
          setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
        }else{
          if(select_curso === 1){
            setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
          }else if(select_curso === 2){
            setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
          }else if(select_curso === 3){
            setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
          }else if(select_curso === 4){
            setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
          }
        }
      }else if (select_entrada === 2) {
        setTotal(paquetes[1].precio_usd);
        if(select_visita !== 0){
          setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
          if(select_curso === 1){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
          }else if(select_curso === 2){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
          }else if(select_curso === 3){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
          }else if(select_curso === 4){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
          }
        }else{
          if(select_curso === 1){
            setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
          }else if(select_curso === 2){
            setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
          }else if(select_curso === 3){
            setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
          }else if(select_curso === 4){
            setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
          }
        }
      } else {
        if(numero === 0){
          setTotal(0);
        }
        if(select_visita !== 0){
          setTotal(paquetes[2].precio_usd);
          if(select_curso === 1){
            setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
          }else if(select_curso === 2){
            setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
          }else if(select_curso === 3){
            setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
          }else if(select_curso === 4){
            setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
          }
        }else{
          if(select_curso === 1){
            setTotal(paquetes[3].precio_usd);
          }else if(select_curso === 2){
            setTotal(paquetes[4].precio_usd);
          }else if(select_curso === 3){
            setTotal(paquetes[5].precio_usd);
          }else if(select_curso === 4){
            setTotal(paquetes[6].precio_usd);
          }
        }
      }
    }
  }, [paquetes, select_curso, select_entrada, select_visita,paquete_elegido]);//paquete_elegido, numero, paquetes, select_curso, select_entrada, select_visita

  useEffect(() => {
    if (idioma === 'es') {
      if (paquete_elegido !== 0 && paquetes.length > 0) {
        setTotal(paquetes[paquete_elegido - 1].precio)
        cambiarHoverImagen(paquete_elegido - 1);
      }
    } else {
      if (paquete_elegido !== 0 && paquetes.length > 0) {
        setTotal(paquetes[paquete_elegido - 1].precio_usd)
        cambiarHoverImagen(paquete_elegido - 1);
      }
    }
  }, [idioma]);

  useEffect(()=>{
    if(idioma === 'es'){
      if(numero === 1){
        setSelectEntrada(1);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[6].precio);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[6].precio);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio);
            }
          }
        }
      }else if (numero === 2) {
        setSelectEntrada(2);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[6].precio);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[6].precio);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio);
            }
          }
        }
      }else if (numero === 3) {
        setSelectVisita(1);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[6].precio);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[6].precio);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio);
            }
          }
        }
      }else if (numero === 4) {
        setSelectCurso(1);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[6].precio);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[6].precio);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio);
            }
          }
        }
      }/*else if (numero === 5) {
        setSelectCurso(2);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[6].precio);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[6].precio);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio);
            }
          }
        }
      }*/else if (numero === 6) {
        setSelectCurso(3);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[6].precio);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[6].precio);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio);
            }
          }
        }
      }/*else if (numero === 7) {
        setSelectCurso(4);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio+paquetes[6].precio);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio+paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio+paquetes[6].precio);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio);
            if(select_curso === 1){
              setTotal(paquetes[2].precio+paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio+paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio+paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio+paquetes[6].precio);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio);
            }
          }
        }
      }*/
    }else{
      if(numero === 1){
        setSelectEntrada(1);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio_usd);
            }
          }
        }
      }else if (numero === 2) {
        setSelectEntrada(2);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio_usd);
            }
          }
        }
      }else if (numero === 3) {
        setSelectVisita(1);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio_usd);
            }
          }
        }
      }else if (numero === 4) {
        setSelectCurso(1);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio_usd);
            }
          }
        }
      }/*else if (numero === 5) {
        setSelectCurso(2);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio_usd);
            }
          }
        }
      }*/else if (numero === 6) {
        setSelectCurso(3);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio_usd);
            }
          }
        }
      }/*else if (numero === 7) {
        setSelectCurso(4);
        if(select_entrada === 1){
          setTotal(paquetes[0].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[0].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[0].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[0].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[0].precio_usd+paquetes[6].precio_usd);
            }
          }
        }else if (select_entrada === 2) {
          setTotal(paquetes[1].precio_usd);
          if(select_visita !== 0){
            setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[1].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[1].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[1].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[1].precio_usd+paquetes[6].precio_usd);
            }
          }
        } else {
          if(numero === 0){
            setTotal(0);
          }
          if(select_visita !== 0){
            setTotal(paquetes[2].precio_usd);
            if(select_curso === 1){
              setTotal(paquetes[2].precio_usd+paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[2].precio_usd+paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[2].precio_usd+paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[2].precio_usd+paquetes[6].precio_usd);
            }
          }else{
            if(select_curso === 1){
              setTotal(paquetes[3].precio_usd);
            }else if(select_curso === 2){
              setTotal(paquetes[4].precio_usd);
            }else if(select_curso === 3){
              setTotal(paquetes[5].precio_usd);
            }else if(select_curso === 4){
              setTotal(paquetes[6].precio_usd);
            }
          }
        }
      }*/
    }
    //console.log('select_entrada',select_entrada);
    //console.log('select_curso',select_curso);
    //console.log('select_visita',select_visita);
  },[paquetes, select_curso, select_entrada, select_visita])
  useEffect(() => {
      
    if (params?.paquete && parseInt(params?.paquete) > 0 && parseInt(params?.paquete) <= 7) {
      //setPaqueteElegido(parseInt(params?.paquete)); 
      //setNumero(parseInt(params?.paquete));
      if(params?.paquete && parseInt(params?.paquete)=== 1){
        setNumero(1);
        setPaqueteElegido(1);
        setSelectMiembro(2);
        //setSelectEntrada(1);
      }else if(params?.paquete && parseInt(params?.paquete)=== 2){
        setNumero(2);
        setPaqueteElegido(2);
        setSelectMiembro(1);
        //setSelectEntrada(1);
      }else if(params?.paquete && parseInt(params?.paquete)=== 3){
        setNumero(3);
        setPaqueteElegido(3);
        setSelectMiembro(2);
        //setSelectEntrada(1);
      }else if(params?.paquete && parseInt(params?.paquete)=== 4){
        setNumero(4);
        setPaqueteElegido(4);
        setSelectMiembro(2);
        //setSelectEntrada(1);
      }/*else if(params?.paquete && parseInt(params?.paquete)=== 5){
        setNumero(5);
        setPaqueteElegido(5);
        setSelectMiembro(2);
        //setSelectEntrada(1);
      }*/else if(params?.paquete && parseInt(params?.paquete)=== 6){
        setNumero(6);
        setPaqueteElegido(6);
        setSelectMiembro(1);
        //setSelectEntrada(1);
      }/*else if(params?.paquete && parseInt(params?.paquete)=== 7){
        setNumero(7);
        setPaqueteElegido(7);
        setSelectMiembro(1);
        //setSelectEntrada(1);
      }*/
    }else{
      setPaqueteElegido(0);//1
    }
    if(idioma === 'es'){
      if(numero === 1){
        setTotal(paquetes[0].precio);
      }else if(numero === 2){
        setTotal(paquetes[1].precio);
      }else if(numero === 3){
        setTotal(paquetes[2].precio);
      }else if(numero === 4){
        setTotal(paquetes[3].precio);
      }/*else if(numero === 5){
        setTotal(paquetes[4].precio);
      }*/else if(numero === 6){
        setTotal(paquetes[5].precio);
      }/*else if(numero === 7){
        setTotal(paquetes[6].precio);
      }*/
    }else{
      if(numero === 1){
        setTotal(paquetes[0].precio_usd);
      }else if(numero === 2){
        setTotal(paquetes[1].precio_usd);
      }else if(numero === 3){
        setTotal(paquetes[2].precio_usd);
      }else if(numero === 4){
        setTotal(paquetes[3].precio_usd);
      }/*else if(numero === 5){
        setTotal(paquetes[4].precio_usd);
      }*/else if(numero === 6){
        setTotal(paquetes[5].precio_usd);
      }/*else if(numero === 7){
        setTotal(paquetes[6].precio_usd);
      }*/
    }
    console.log(params);
  }, []);
  const buscarCodigo = (tamano) => {
    setCargarPantalla(true);
    let valor = "";
    switch (tamano) {
      case 'chico':
        valor = ref_inputxs.current.value;
        break;
      default:
        valor = ref_inputmd.current.value;
        break;
    }
    if (valor !== '' && valor !== ' ') {
      codigo(valor).then(({ data, status }) => {
        switch (status) {
          case 0:
            setValorCodigo(data.id_codigo);
            setPorcentajeCodigo(data.porcentaje);
            mensaje('success', idioma === 'es' ? 'Código aceptado' : 'Accepted code', '');
            break;
          default:
            mensaje('error', idioma === 'es' ? 'Código invalido' : 'Warning', '');
            break;
        }
        setCargarPantalla(false);
      });
    } else {
      mensaje('warning', 'Registra un código', 'Register a code');
      setCargarPantalla(false);
    }

  }

  const irDelante = (adelante) => {
    if(adelante){
      if(paquete_elegido === 1){
        setTotal(paquetes[1].precio)
        cambiarHoverImagen(1);
        setPaqueteElegido(2);
      }else if(paquete_elegido === 2){
        setTotal(paquetes[2].precio)
        cambiarHoverImagen(2);
        setPaqueteElegido(3);
      }else if(paquete_elegido === 3){
        setTotal(paquetes[3].precio)
        cambiarHoverImagen(3);
        setPaqueteElegido(4);
      }else {
        setPaqueteElegido(1);
        setTotal(paquetes[0].precio)
        cambiarHoverImagen(0);
      }
    }else{
      if(paquete_elegido === 1){
        setTotal(paquetes[3].precio)
        cambiarHoverImagen(3);
        setPaqueteElegido(4);
      }else if(paquete_elegido === 2){
        setTotal(paquetes[0].precio)
        cambiarHoverImagen(0);
        setPaqueteElegido(1);
      }else if(paquete_elegido === 3){
        setTotal(paquetes[1].precio)
        cambiarHoverImagen(1);
        setPaqueteElegido(2);
      }else if(paquete_elegido === 4){
        setTotal(paquetes[2].precio)
        cambiarHoverImagen(2);
        setPaqueteElegido(3);
      }else {
        setPaqueteElegido(4);
        setTotal(paquetes[3].precio)
        cambiarHoverImagen(3);
      }
    }
  }

  let ver_paquetes = paquetes.map((objeto, index) => {

    return (
      <Col xs={20} md={10}>
        <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>
      </Col>
    )
  });

  let ver_paquetes_movil = paquetes.map((objeto, index) => {

    return (
      <Col span={10}>
        {idioma === 'es' ?
          <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>
          :
          <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover_en : objeto.url_imagen_en} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>}
      </Col>
    )
  });

  let ver_paquetes_2022 = paquetes.map((objeto, index) => {

    return (
      <Col span={24}>
         {/*<Whatsapp idioma={idioma} />*/}
        {idioma === 'es' ?
          <>
          {select_imagen[index] &&
            <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>
          }
          </>
          :
          <>
          {select_imagen[index] &&
            <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
              <img src={select_imagen[index] ? objeto.url_imagen_hover_en : objeto.url_imagen_en} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
            </p>
        }
        </>}
      </Col>
    )
  });

  
  const elegirMedio = value => {
    console.log(value);

    if (value === 'Otro') {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }

  }

  useEffect(() => {
    if (datos_registro.length > 0) {
      setConfirmacion(true);
    }
  }, [datos_registro]);

  useEffect(() => {
    if (confirmacion) {
      if (datos_registro[2] > 0) {
        mensaje('success', 'Realiza tu pago', '');
      }
    }
  }, [confirmacion]);



  const onFailed = async values =>{
    
    switch(values.errorFields[0].name[0]){
      case 'correo': mensaje('warning',idioma === 'es' ? 'Completa el campo correo electrónico' : 'Fill in the email field',''); break;
      case 'nombre': mensaje('warning',idioma === 'es' ? 'Completa el campo nombre' : 'Fill in the name field',''); break;
      case 'ap_paterno': mensaje('warning',idioma === 'es' ? 'Completa el campo apellido paterno' : 'Complete the paternal surname field',''); break;
      case 'ap_materno': mensaje('warning',idioma === 'es' ? 'Completa el campo apellido materno' : 'Complete the maternal surname field',''); break;
      case 'telefono': mensaje('warning',idioma === 'es' ? 'Completa el campo telefono' : 'Complete the telephone field',''); break;
      //case 'confirm': mensaje('warning',idioma === 'es' ? 'Completa el campo contraseña' : 'Fill in the password field',''); break;
      case 'direccion': mensaje('warning',idioma === 'es' ? 'Completa el campo dirección' : 'Fill in the address field',''); break;
      case 'cp': mensaje('warning',idioma === 'es' ? 'Completa el campo codigo postal' : 'Fill in the zip code field',''); break;
      case 'ciudad': mensaje('warning',idioma === 'es' ? 'Completa el campo ciudad' : 'Complete the city field',''); break;
      case 'estado': mensaje('warning',idioma === 'es' ? 'Completa el campo estado' : 'Fill in the state field',''); break;
      case 'pais': mensaje('warning',idioma === 'es' ? 'Completa el campo país' : 'Complete the country field',''); break;
      case 'medio_enterado': mensaje('warning',idioma === 'es' ? 'Completa el campo medio enterado' : 'Fill in the half-aware field',''); break;
      default: mensaje('warning',idioma === 'es' ? 'Faltan campos' : 'Fields are missing',idioma === 'es' ? 'Por favor rellena todos los campos' : 'Please fill in all the fields'); break;

    }
  }

  const onFinish = async values => {

    if (ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' ') {
      mensaje('warning', idioma === 'es' ? 'Recaptcha inválido' : 'Invalid recaptcha', idioma === 'es' ? 'Por favor verifica tu identidad' : 'Please verify your identity');
      return;
    }

    values['file'] = url_file;

    //values.paquete_id = paquete_elegido;

    if(numero === 0){
      values.paquete_id = select_entrada;
      values.extra1 = select_visita;
      values.extra2 = select_curso;
    }else if(numero === 1){
      values.paquete_id = 1;
      values.extra1 = select_visita;
      values.extra2 = select_curso;
    }else if(numero === 2){
      values.paquete_id = 2;
      values.extra1 = select_visita;
      values.extra2 = select_curso;
    }else if(numero === 3){
      values.paquete_id = select_entrada;
      values.extra1 = 1;
      values.extra2 = select_curso;
    }else if(numero === 4){
      values.paquete_id = select_entrada;
      values.extra1 = select_visita;
      values.extra2 = 1;
    }else if(numero === 5){
      values.paquete_id = select_entrada;
      values.extra1 = select_visita;
      values.extra2 = 2;
    }else{
      values.paquete_id = select_entrada;
      values.extra1 = select_visita;
      values.extra2 = select_curso;
    }
    values.subtotal = ver_total;
    values["g-recaptcha-response"] = ref_recaptcha.current.getValue();

    if (select_visita === 0 && select_entrada === 0 && select_curso === 0 && numero === 0 ) {//paquete_elegido
      mensaje('warning', idioma === 'es' ? 'Elige un paquete' : 'Choose a package', '');
      return;
    }

    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
    //values.password = values.password.split('').map(letra => acentos[letra] || letra).join('').toString().toLowerCase();

    if (valor_codigo !== 0) {
      values.codigo_id = valor_codigo;
    }

    if (values.medio_enterado === 'Otro') {
      values.medio_enterado = values.otro_medio;
      delete values.otro_medio;
    }

    values.idioma = idioma;
    values.nombre_curso = nom_evento;

    console.log(values);

    setCargarPantalla(true);

    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Te enviamos un correo de confirmación de registro al correo' : 'We send you a registration confirmation email to the email' + data[3]);
          setDatosRegistro(data);
          break;
        case 1:
          mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
          setDatosRegistro(data);
          break;
        case 2:
          mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
          break;
        default:
          mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
          break;
      }
      setCargarPantalla(false);
    });

  };

  return (

    <>
      <Spin spinning={cargar_pantalla} delay={0}>
        {confirmacion ?
          <RegistroCompleto idioma={idioma} datos={datos_registro} />
          :

          <Row className="contenedor_registro_">
            <Col span={24} className="">
            <Row justify="center" align="middle">
        <Col xs={0} lg={24} className="contenedor_registro">
          <Row>
            <Col span={6} offset={9} style={{ display: 'non' }}><br /><br />
              <a href="https://maps.app.goo.gl/VgMAbWAAQg1ihp9K8" target='_blank'><img src={idioma === 'es' ? info_banner : info_banner_en} width="100%" /></a>
            </Col>
            <Col xs={{span:8, offset:8}} md={{span:6, offset:9}} lg={{span:8, offset:8}}>
              <img src={logo_banner} width="100%" />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={0} className="principal_movil">
          <Row>
            <Col span={10} offset={7}><br />
              <img src={idioma === 'es' ? info_banner : info_banner_en} width="100%" />
            </Col>
            <Col span={12} offset={6}>
              <img src={logo_banner} width="100%" /><br /><br />
            </Col>
            <Col span={20} offset={2}>
              <a href="/registro" className='btn_registrate_home fuente'>{idioma === 'es' ? '¡REGÍSTRATE!' : ' SIGN UP'}</a>
            </Col>
          </Row>
        </Col>
      </Row>
              
            </Col>
            
            <Col span={24}>
              <Form className="login-form"
                layout="vertical"
                name="basic"
                initialValues={{
                  factura: facturar,

                  /*aceptar_terminos: true,
                  nombre: 'Martin',
                  ap_paterno: 'Rodríguez',
                  ap_materno: 'Ayala',
                  correo:'martin@thethree.com.mx',
                  password: '1234567890',
                  confirm: '1234567890',
                  empresa: 'The Three',
                  telefono: '1234567890',
                  miembro_ies: 1,
                  direccion: 'mi direccion',
                  cp: '09090',
                  ciudad: 'CDMX',
                  estado: 'CDMX',
                  pais: 'MEX',
                  medio_enterado: 'Facebook',
        
                  razon_social:'THE THREE',
                  rfc: '12345',
                  cp_factura:'5678',
                  correo_factura:'martin@thethree.com.mx',
                  cfdi:'Por definir (P01)',
                  direccion_factura: 'toda mi direccion',
        
                  numero_miembro: '12345',
                  carrera: 'Ingeniería',
                  semestre: '5to'*/

                }}
                onFinish={onFinish}
                onFinishFailed={onFailed}
                ref={ref_form}
              >

                <Row>

                  <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }}>

                    <Row style={{ marginLeft: '20px', marginRight: '20px' }}>

                      <Col xs={{ span: 24, offset: 0 }} className="">
                        <br />
                            <Row justify={'space-around'}>
                              <Col span={24} className="">
                              <p className="titulo_registro fuente">{idioma === 'es' ? 'Bienvenid@ al registro' : 'Welcome'}</p>
                              <p className="desc_registro fuente">{idioma === 'es' ? 'Agrega las entradas y actividades extras deseadas' : 'Add the desired tickets and extra activities'}</p>
                              <p className="nota_registro color_azul fuente"><i>{idioma === 'es' ? '*Nota: Sólo se podrá seleccionar un curso' : '*Note: Only one course can be selected'}</i></p>
                              </Col>
                              <Col span={22}>
                                <Row justify={'space-around'}>
                                  <Col xs={20} md={10}>
                                    <p onClick={()=>(setSelectMiembro(1),setSelectEntrada(0), setSelectVisita(0), setSelectCurso(0), setNumero(0), ref_form.current.resetFields(["empresa"]))} className={select_miembro === 1 ? 'boton_miembro_select' : 'boton_miembro_no_select'}>MIEMBRO SUME</p>
                                    
                                  </Col>
                                  <Col xs={20} md={0}>
                                    <br />
                                  </Col>
                                  <Col xs={20} md={10}>
                                    <p onClick={()=>(setSelectMiembro(2),setSelectEntrada(0), setSelectVisita(0), setSelectCurso(0), setNumero(0), ref_form.current.resetFields(["empresa"]))} className={select_miembro === 2 ? 'boton_miembro_select' : 'boton_miembro_no_select'}>NO MIEMBRO SUME</p>
                                  </Col>
                                </Row>
                                <br /><br />
                              </Col>
                              {select_miembro === 1 ?
                              <>
                              <Col xs={10} md={4}>
                                {idioma === 'es' ? 
                              <>
                              <img src={select_entrada === 2 || numero === 2 ? img_paquete_2_hover : img_paquete_2} style={{width:'100%'}} />
                                <img src={select_entrada === 2 || numero === 2 ? quitar : agregar} onClick={()=>((select_entrada === 2 ? setSelectEntrada(0) : setSelectEntrada(2)),(numero === 2 && (setNumero(0),setSelectEntrada(0))),(numero === 1 && (setNumero(0),setSelectEntrada(2))))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_entrada === 2 || numero === 2 ? img_paquete_2_hover_en : img_paquete_2_en} style={{width:'100%'}} />
                                <img src={select_entrada === 2 || numero === 2 ? quitar_en : agregar_en} onClick={()=>((select_entrada === 2 ? setSelectEntrada(0) : setSelectEntrada(2)),(numero === 2 && (setNumero(0),setSelectEntrada(0))),(numero === 1 && (setNumero(0),setSelectEntrada(2))))}  style={{width:'100%'}} alt="" />
                                </>}
                              </Col>
                              <Col xs={10} md={4}>
                              {idioma === 'es' ? 
                              <>
                              <img src={select_visita === 1 || numero === 3 ? img_visita_hover : img_visita} style={{width:'100%'}} />
                                <img src={select_visita === 1 || numero === 3 ? quitar : agregar} onClick={()=>((select_visita === 1 ? setSelectVisita(0) : setSelectVisita(1)),(numero === 3 && (setNumero(0),setSelectVisita(0))),(select_curso !== 0 && (setSelectCurso(0),setSelectVisita(1))))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_visita === 1 || numero === 3 ? img_visita_hover_en : img_visita_en} style={{width:'100%'}} />
                                <img src={select_visita === 1 || numero === 3 ? quitar_en : agregar_en} onClick={()=>((select_visita === 1 ? setSelectVisita(0) : setSelectVisita(1)),(numero === 3 && (setNumero(0),setSelectVisita(0))),(select_curso !== 0 && (setSelectCurso(0),setSelectVisita(1))))}  style={{width:'100%'}} alt="" />
                                </>}
                                
                              </Col>
                              <Col xs={10} md={4}>
                              {idioma === 'es' ? 
                              <>
                              <img src={select_curso === 3 || numero === 6 ? img_curso_1_hover_sume : img_curso_1_sume} style={{width:'100%'}} />
                                <img src={select_curso === 3 || numero === 6 ? quitar : agregar} onClick={()=>((select_curso === 3 ? setSelectCurso(0) : setSelectCurso(3)),(numero === 4 && (setNumero(0),setSelectCurso(3))),(numero === 5 && (setNumero(0),setSelectCurso(3))),(numero === 6 && (setNumero(0),setSelectCurso(0))),(numero === 7 && (setNumero(0),setSelectCurso(3))),(select_visita === 1 && setSelectVisita(0)))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_curso === 3 || numero === 6 ? img_curso_1_hover_sume_en : img_curso_1_sume_en} style={{width:'100%'}} />
                                <img src={select_curso === 3 || numero === 6 ? quitar_en : agregar_en} onClick={()=>((select_curso === 3 ? setSelectCurso(0) : setSelectCurso(3)),(numero === 4 && (setNumero(0),setSelectCurso(3))),(numero === 5 && (setNumero(0),setSelectCurso(3))),(numero === 6 && (setNumero(0),setSelectCurso(0))),(numero === 7 && (setNumero(0),setSelectCurso(3))),(select_visita === 1 && setSelectVisita(0)))}  style={{width:'100%'}} alt="" />
                                </>}
                                
                              </Col>
                              {/*<Col xs={10} md={4}>
                              {idioma === 'es' ? 
                              <>
                              <img src={select_curso === 4 || numero === 7 ? img_curso_2_hover_sume : img_curso_2_sume} style={{width:'100%'}} />
                                <img src={select_curso === 4 || numero === 7 ? quitar : agregar} onClick={()=>((select_curso === 4 ? setSelectCurso(0) : setSelectCurso(4)),(numero === 4 && (setNumero(0),setSelectCurso(4))),(numero === 5 && (setNumero(0),setSelectCurso(4))),(numero === 6 && (setNumero(0),setSelectCurso(4))),(numero === 7 && (setNumero(0),setSelectCurso(0))),(select_visita === 1 && setSelectVisita(0)))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_curso === 4 || numero === 7 ? img_curso_2_hover_sume_en : img_curso_2_sume_en} style={{width:'100%'}} />
                                <img src={select_curso === 4 || numero === 7 ? quitar_en : agregar_en} onClick={()=>((select_curso === 4 ? setSelectCurso(0) : setSelectCurso(4)),(numero === 4 && (setNumero(0),setSelectCurso(4))),(numero === 5 && (setNumero(0),setSelectCurso(4))),(numero === 6 && (setNumero(0),setSelectCurso(4))),(numero === 7 && (setNumero(0),setSelectCurso(0))),(select_visita === 1 && setSelectVisita(0)))}  style={{width:'100%'}} alt="" />
                                </>}
                                
                              </Col>*/}
                              </>
                              :
                              (select_miembro === 2 &&
                              
                              <>
                              <Col xs={10} md={4}>
                              {idioma === 'es' ? 
                              <>
                              <img src={select_entrada === 1 || numero === 1 ? img_paquete_1_hover : img_paquete_1} style={{width:'100%'}} />
                                <img src={select_entrada === 1 || numero === 1 ? quitar : agregar} onClick={()=>((select_entrada === 1 ? setSelectEntrada(0) : setSelectEntrada(1)),(numero === 1 && (setNumero(0),setSelectEntrada(0))),(numero === 2 && (setNumero(0),setSelectEntrada(1))))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_entrada === 1 || numero === 1 ? img_paquete_1_hover_en : img_paquete_1_en} style={{width:'100%'}} />
                                <img src={select_entrada === 1 || numero === 1 ? quitar_en : agregar_en} onClick={()=>((select_entrada === 1 ? setSelectEntrada(0) : setSelectEntrada(1)),(numero === 1 && (setNumero(0),setSelectEntrada(0))),(numero === 2 && (setNumero(0),setSelectEntrada(1))))}  style={{width:'100%'}} alt="" />
                                </>}
                                
                              </Col>
                              
                              <Col xs={10} md={4}>
                              {idioma === 'es' ? 
                              <>
                              <img src={select_visita === 1 || numero === 3 ? img_visita_hover : img_visita} style={{width:'100%'}} />
                                <img src={select_visita === 1 || numero === 3 ? quitar : agregar} onClick={()=>((select_visita === 1 ? setSelectVisita(0) : setSelectVisita(1)),(numero === 3 && (setNumero(0),setSelectVisita(0))),(select_curso !== 0 && (setSelectCurso(0),setSelectVisita(1))))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_visita === 1 || numero === 3 ? img_visita_hover_en : img_visita_en} style={{width:'100%'}} />
                                <img src={select_visita === 1 || numero === 3 ? quitar_en : agregar_en} onClick={()=>((select_visita === 1 ? setSelectVisita(0) : setSelectVisita(1)),(numero === 3 && (setNumero(0),setSelectVisita(0))),(select_curso !== 0 && (setSelectCurso(0),setSelectVisita(1))))}  style={{width:'100%'}} alt="" />
                                </>}
                                
                              </Col>
                              <Col xs={10} md={4}>
                              {idioma === 'es' ? 
                              <>
                              <img src={select_curso === 1 || numero === 4 ? img_curso_1_hover : img_curso_1} style={{width:'100%'}} />
                                <img src={select_curso === 1 || numero === 4 ? quitar : agregar} onClick={()=>((select_curso === 1 ? setSelectCurso(0) : setSelectCurso(1)),(numero === 4 && (setNumero(0),setSelectCurso(0))),(numero === 5 && (setNumero(0),setSelectCurso(1))),(numero === 6 && (setNumero(0),setSelectCurso(1))),(numero === 7 && (setNumero(0),setSelectCurso(1)),(select_visita === 1 && setSelectVisita(0))))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_curso === 1 || numero === 4 ? img_curso_1_hover_en : img_curso_1_en} style={{width:'100%'}} />
                                <img src={select_curso === 1 || numero === 4 ? quitar_en : agregar_en} onClick={()=>((select_curso === 1 ? setSelectCurso(0) : setSelectCurso(1)),(numero === 4 && (setNumero(0),setSelectCurso(0))),(numero === 5 && (setNumero(0),setSelectCurso(1))),(numero === 6 && (setNumero(0),setSelectCurso(1))),(numero === 7 && (setNumero(0),setSelectCurso(1)),(select_visita === 1 && setSelectVisita(0))))}  style={{width:'100%'}} alt="" />
                                </>}
                                
                              </Col>
                              
                              {/*<Col xs={10} md={4}>
                              {idioma === 'es' ? 
                              <>
                              <img src={select_curso === 2 || numero === 5 ? img_curso_2_hover : img_curso_2} style={{width:'100%'}} />
                                <img src={select_curso === 2 || numero === 5 ? quitar : agregar} onClick={()=>((select_curso === 2 ? setSelectCurso(0) : setSelectCurso(2)),(numero === 4 && (setNumero(0),setSelectCurso(2))),(numero === 5 && (setNumero(0),setSelectCurso(0))),(numero === 6 && (setNumero(0),setSelectCurso(2))),(numero === 7 && (setNumero(0),setSelectCurso(2)),(select_visita === 1 && setSelectVisita(0))))}  style={{width:'100%'}} alt="" />
                              </>
                                :
                                <>
                                <img src={select_curso === 2 || numero === 5 ? img_curso_2_hover_en : img_curso_2_en} style={{width:'100%'}} />
                                <img src={select_curso === 2 || numero === 5 ? quitar_en : agregar_en} onClick={()=>((select_curso === 2 ? setSelectCurso(0) : setSelectCurso(2)),(numero === 4 && (setNumero(0),setSelectCurso(2))),(numero === 5 && (setNumero(0),setSelectCurso(0))),(numero === 6 && (setNumero(0),setSelectCurso(2))),(numero === 7 && (setNumero(0),setSelectCurso(2)),(select_visita === 1 && setSelectVisita(0))))}  style={{width:'100%'}} alt="" />
                                </>}
                                
                              </Col>*/}
                              </>)}
                              
                              
                            </Row>
                      </Col>
                      <Col span={24}>
                        <p className="subtitulo_registro">
                            <strong>{idioma === 'es' ? 'Información de contacto' : 'Contact information'}</strong>
                        </p>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="correo"
                          rules={idioma === 'es' ?
                            [
                              {
                                type: 'email',
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu correo electrónico !</span>,
                              },
                            ] :
                            [
                              {
                                type: 'email',
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your email!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Correo electrónico" : "Email"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="nombre"
                          rules={idioma === 'es' ? [

                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa tu nombre!</span>,
                            },
                          ] :
                            [{
                              required: true,
                              message: <span className="label_requerido_registro">Enter your name!</span>,
                            },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Nombre(s)" : "Name"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 11 }}>
                        <Form.Item
                          name="ap_paterno"
                          rules={idioma === 'es' ?
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu apellido paterno!</span>,
                              },
                            ] :
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your paternal last name!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Apellido paterno" : "Last name"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                        <Form.Item
                          name="ap_materno"
                          rules={idioma === 'es' ?
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu apellido materno!</span>,
                              },
                            ] :
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your maternal last name!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Apellido materno" : "Last Name"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="telefono"
                          rules={idioma === 'es' ?
                            [
                              {
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu teléfono!</span>,
                              },
                            ] :
                            [
                              {
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your phone!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Teléfono" : "Phone"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="empresa"
                          rules={
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa el nombre de tu empresa!</span>,
                              },
                            ] 
                            }
                        >
                          {select_miembro === 1 ?
                          <Select className="inputs_registro" placeholder={idioma == 'es' ? 'Selecciona la empresa' : 'Select your company'}>
                          <Option value="3 LOTUS">3 LOTUS </Option>
<Option value="ADI">ADI</Option>
<Option value="ALLANA BUICK AND BERS">ALLANA BUICK AND BERS</Option>
<Option value="AMEFIBRA">AMEFIBRA</Option>
<Option value="AMERIC">AMERIC</Option>
<Option value="AMPIP">AMPIP</Option>
<Option value="ASESORES VERDES">ASESORES VERDES</Option>
<Option value="ASHRAE">ASHRAE</Option>
<Option value="ASOCIACION DE BANCOS DE MEXICO">ASOCIACION DE BANCOS DE MEXICO</Option>
<Option value="BELLIMO">BELLIMO</Option>
<Option value="BEYOND ENERGY">BEYOND ENERGY</Option>
<Option value="BIOCONSTRUCCIÓN">BIOCONSTRUCCIÓN</Option>
<Option value="BIOPIPE">BIOPIPE </Option>
<Option value="BMV">BMV</Option>
<Option value="BOVIS">BOVIS</Option>
<Option value="BREET">BREET</Option>
<Option value="BUSTAMANTE Y FREYRE ">BUSTAMANTE Y FREYRE </Option>
<Option value="CADIS">CADIS</Option>
<Option value="CAMSAM">CAMSAM</Option>
<Option value="CANADEVI">CANADEVI</Option>
<Option value="CARRIER">CARRIER</Option>
<Option value="CASA ABIERTA">CASA ABIERTA</Option>
<Option value="CBRE">CBRE</Option>
<Option value="CEMEX">CEMEX</Option>
<Option value="CHAAC SIMULACIONES INC">CHAAC SIMULACIONES INC </Option>
<Option value="CIMESA">CIMESA</Option>
<Option value="CIPRES">CIPRES</Option>
<Option value="CMIC">CMIC</Option>
<Option value="COLECTIVO CMX">COLECTIVO CMX</Option>
<Option value="COREV-PROCOBRE">COREV-PROCOBRE</Option>
<Option value="CRDC GLOBAL">CRDC GLOBAL</Option>
<Option value="CSMX">CSMX</Option>
<Option value="CUSHMAN & WAKEFIELD">CUSHMAN & WAKEFIELD</Option>
<Option value="DAL-TILE/MOHAWK">DAL-TILE/MOHAWK</Option>
<Option value="DANFOS ">DANFOS </Option>
<Option value="DARIO IBARGUENGOITIA ">DARIO IBARGUENGOITIA </Option>
<Option value="EARTH LAB">EARTH LAB</Option>
<Option value="ECOSTUDIO XV">ECOSTUDIO XV</Option>
<Option value="ENCA">ENCA</Option>
<Option value="FILA SOLUTIONS ">FILA SOLUTIONS </Option>
<Option value="FINSA">FINSA</Option>
<Option value="GENSLER">GENSLER</Option>
<Option value="GRUNDFOS">GRUNDFOS</Option>
<Option value="HERMOSILLO Y ASOCIADOS">HERMOSILLO Y ASOCIADOS</Option>
<Option value="HOLCIM">HOLCIM</Option>
<Option value="IBALCA">IBALCA</Option>
<Option value="IBERO">IBERO</Option>
<Option value="IES">IES</Option>
<Option value="IMEI">IMEI</Option>
<Option value="INDUSTRIAS MARVES">INDUSTRIAS MARVES</Option>
<Option value="INMOBILIARE">INMOBILIARE</Option>
<Option value="ITACA">ITACA</Option>
<Option value="JOHNSON CONTROLS">JOHNSON CONTROLS</Option>
<Option value="JWA">JWA</Option>
<Option value="KILTIK">KILTIK</Option>
<Option value="LEAF SAC">LEAF SAC </Option>
<Option value="MARYNES ALCALA">MARYNES ALCALA</Option>
<Option value="MAX LIVING & DESIGN">MAX LIVING & DESIGN</Option>
<Option value="METECNO">METECNO</Option>
<Option value="MIRNA DE LA PAZ GAMALLO">MIRNA DE LA PAZ GAMALLO</Option>
<Option value="MÓNICA SAMANO">MÓNICA SAMANO</Option>
<Option value="NET ZERO COMMUNITY">NET ZERO COMMUNITY</Option>
<Option value="NETZERO">NETZERO</Option>
<Option value="NOVIDESA">NOVIDESA</Option>
<Option value="ONNCCE">ONNCCE</Option>
<Option value="PAGE SOUTHERLAND">PAGE SOUTHERLAND</Option>
<Option value="PATRICIA FORT">PATRICIA FORT</Option>
<Option value="PIIMA">PIIMA</Option>
<Option value="RAIN BIRD">RAIN BIRD</Option>
<Option value="RECURSOV">RECURSOV</Option>
<Option value="RETEX">RETEX</Option>
<Option value="REVITALIZA">REVITALIZA</Option>
<Option value="RICARDO AGUAYO GONZÁLEZ">RICARDO AGUAYO GONZÁLEZ</Option>
<Option value="SAINT GOBAIN">SAINT GOBAIN</Option>
<Option value="SECURITY CONTROL AND DESIGNS SCD">SECURITY CONTROL AND DESIGNS SCD</Option>
<Option value="SHELTER">SHELTER</Option>
<Option value="SIEMENS">SIEMENS</Option>
<Option value="SIFSA">SIFSA</Option>
<Option value="SIGEA">SIGEA</Option>
<Option value="SIGNIFY">SIGNIFY</Option>
<Option value="SIN HUELLA">SIN HUELLA</Option>
<Option value="SINERGI">SINERGI</Option>
<Option value="SOLER Y PALAU">SOLER Y PALAU</Option>
<Option value="SOMFY">SOMFY</Option>
<Option value="SUSTAINABLE LIVING ">SUSTAINABLE LIVING </Option>
<Option value="TAAG BUILD">TAAG BUILD</Option>
<Option value="TAHM ARCHITECTURE">TAHM ARCHITECTURE</Option>
<Option value="TANMAX">TANMAX</Option>
<Option value="TECO ARQUITECTOS">TECO ARQUITECTOS</Option>
<Option value="TELEOPTICA">TELEOPTICA</Option>
<Option value="THREE CONSULTORÍA MEDIO AMB">THREE CONSULTORÍA MEDIO AMB</Option>
<Option value="TROX">TROX</Option>
<Option value="U.S. COMMERCIAL SERVICE">U.S. COMMERCIAL SERVICE</Option>
<Option value="UDEM">UDEM</Option>
<Option value="ULI">ULI</Option>
<Option value="UP">UP </Option>
<Option value="UPAEP">UPAEP</Option>
<Option value="USG">USG </Option>
<Option value="VERONICA CALDERON BRAVO">VERONICA CALDERON BRAVO</Option>
<Option value="VESTA">VESTA</Option>
<Option value="WARE MALCOMB">WARE MALCOMB</Option>
<Option value="WASTE CERO">WASTE CERO</Option>
<Option value="WAVE GROUP">WAVE GROUP</Option>
<Option value="WRI">WRI</Option>
                        </Select>
                        :
                            <Input className="inputs_registro" placeholder={idioma === 'es' ? 'Empresa' : 'Company'} />}
                        </Form.Item>
                      </Col>
                      
                      {/*
                      <Col span={24}>
                        <Form.Item
                          name="password"
                          rules={idioma === 'es' ?
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa una contraseña!</span>,
                              },
                            ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Please enter a password!</span>,
                              },
                            ]}
                          hasFeedback
                        >
                          <Input.Password className="inputs_registro" placeholder={idioma === 'es' ? "Elige una contraseña" : "Password"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="confirm"
                          dependencies={['password']}
                          hasFeedback
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Confirma tu contraseña!</span>,
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(<span className="label_requerido_registro">Asegúrate que las contraseñas coincidan!</span>);
                              },
                            }),
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Confirm your password!</span>,
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(<span className="label_requerido_registro">Make sure the passwords match!</span>);
                                },
                              }),
                            ]}
                        >
                          <Input.Password className="inputs_registro" placeholder={idioma === 'es' ? "Confirma tu contraseña" : "Confirm your password"} />
                        </Form.Item>
                          </Col>*/}
                      <Col span={24}>
                        <Form.Item
                          name="direccion"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa una dirección</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter an address</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Dirección" : "Address"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }}>
                        <Form.Item
                          name="cp"
                          rules={idioma === 'es' ?
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa un código postal</span>,
                              },
                            ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Please enter a Postal Code</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Código Postal" : "Postal Code"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 2 }}>
                        <Form.Item
                          name="ciudad"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa una ciudad</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter a city</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Ciudad" : "City"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="estado"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa un estado</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter a state</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Estado" : "State"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="pais"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa un país</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter a country</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "País" : "Country"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="medio_enterado"
                          label={idioma === 'es' ? <span className="texto_label_form">¿Por qué medio te enteraste?</span>
                            : <span className="texto_label_form">How did you find out?</span>}
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Elige el medio por el que te enteraste!</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Choose the means by which you found out!</span>,
                              },
                            ]}
                        >
                          <Select onChange={elegirMedio} >
                            <Option value="Facebook">Facebook</Option>
                            <Option value="Twitter">Twitter</Option>
                            <Option value="Instagram">Instagram</Option>
                            {idioma === 'es' ?
                              <Option value="Correo electrónico">Correo electrónico</Option>
                              :
                              <Option value="Correo electrónico">Email</Option>}
                            <Option value="Whatsapp">Whatsapp</Option>
                            {idioma === 'es' ?
                              <Option value="Otro">Otro</Option>
                              :
                              <Option value="Otro">Other</Option>}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {otro_medio &&
                          (
                            <Form.Item
                              name="otro_medio"
                              label={<span className="texto_label_form">¿Cuál?</span>}
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa el medio por el que te enteraste!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter the means by which you found out!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder="" />
                            </Form.Item>
                          )
                        }
                      </Col>
                      <Col span={24}>
                        <hr style={{border:'none', backgroundColor:'#97B623', height:'6px'}} />
                        <p className="texto_label_form fuente color_azul" style={{textAlign:'left'}}>
                          <strong>{idioma === 'es' ? 'RESUMEN DE ENTRADAS Y ACTIVIDADES EXTRAS' : 'SUMMARY OF TICKETS AND EXTRA ACTIVITIES'}</strong><br />
                          {select_entrada === 1 || numero === 1 ?
                          <> {idioma === 'es' ? 
                          '- Entrada general ($9,500 MXN)' : '- General adminission ($560 USD)'
                          }<br /></>
                        :
                        (select_entrada === 2 || numero === 2 ?
                          <>{idioma === 'es' ? '- Miembros SUMe y aliados ($8,000 MXN)' : '- Member of SUMe an allies ($471 USD)'}<br /></>
                          :
                          <></>)}
                          {select_visita === 1 || numero === 3 ? 
                          <>{idioma === 'es' ? '- Visita a edificions sustentables ($2,000 MXN)' : '- Visit to sustentable building ($118 USD)'}<br /></>
                        :<></>}
                        {select_curso === 1 || numero === 4 ?
                          <> {idioma === 'es' ? '- Taller True Zero Waste ($5,500 MXN)' : '- True Zero Waster Workshop ($324 USD)'}</>
                        :
                        (select_curso === 2 || numero === 5 ?
                          <>{idioma === 'es' ? '- Taller LEED FOR CITIES AND COMMUNITIES ($5,500 MXN)' : '- LEED FOR CITIES AND COMMUNITIES workshop ($324 USD)'}</>
                          :
                          (select_curso === 3 || numero === 6 ?
                            <>{idioma === 'es' ? '- Taller True Zero Waste - Miembro SUMe ($5,000 MXN)' : '- True Zero Waste workshop - Miembro SUMe ($294 USD)'}</>
                            :
                            (select_curso === 4 || numero === 7 ?
                              <>{idioma  === 'es' ? '- Taller LEED FOR CITIES AND COMMUNITIES - Miembro SUMe ($5,000 MXN)' : '- LEED FOR CITIES AND COMMUNITIES workshop - Miembro SUMe ($294 USD)'}</>
                              :
                              <></>)))}
                        </p>
                      </Col>
                      <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item
                          name="factura"
                          valuePropName="checked"
                        >
                          <Checkbox onChange={(e) => { setFacturar(e.target.checked); }}>
                            {idioma === 'es' ?
                              <span className="texto_label_form">¿Deseas facturar?</span>
                              :
                              <span className="texto_label_form">To wish billing?</span>}

                          </Checkbox>
                        </Form.Item>
                      </Col>

                      {facturar &&
                        <>

                          <Col span={24}>
                            {idioma === 'es' ?
                              <p className="subtitulo_registro">
                                Datos de facturación
                        </p>
                              :
                              <p className="subtitulo_registro">
                                Billing information
                        </p>}
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name="razon_social"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu razón social!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your social reason!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Razón social" : 'Social reason'} />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                            <Form.Item
                              name="rfc"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu RFC!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your RFC!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder="RFC" />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name="cp_factura"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu código postal!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your postal code!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Código postal" : "Postal code"} />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                            <Form.Item
                              name="correo_factura"
                              rules={idioma === 'es' ?
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Ingresa tu correo para envío de factura!</span>,
                                  },
                                ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your email to send the invoice!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Correo electrónico para envío de factura" : "Email to send billing"} />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name="regimen_fiscal"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu regímen social!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your tax regime!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Régimen fiscal" : 'Tax Regime'} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={idioma === 'es' ? <span>Dirección (<small>Calle número exterior, numero interior, colonia, municipio o alcaldía, estado</small>)</span> : <span>Direction (<small>Street outside number, inside number, neighborhood, municipality or mayor's office, state</small>)</span>}
                              name="direccion_factura"
                              rules={idioma === 'es'
                                ? [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Ingresa la dirección!</span>,
                                  },
                                ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter the address!</span>,
                                  },
                                ]}
                            >
                              <Input.TextArea className="inputs_registro" placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="cfdi"
                              label={idioma === 'es' ? <span className="texto_label_form">Uso de CFDI</span> : <span className="texto_label_form">Use of CFDI</span>}
                              rules={idioma === 'es' ? [
                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Seleccione su uso de CFDI!</span>,
                                },
                              ] :
                                [
                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Select your CFDI usage!</span>,
                                  },
                                ]}
                            >
                              {idioma === 'es' ?
                                <Select>
                                  <Option value="ninguna" selected>Elige una opcion</Option>
                                  <Option value="Devoluciones, descuentos o bonificaciones (G02)">Devoluciones, descuentos o bonificaciones (G02)</Option>
                                  <Option value="Dados, troqueles, moldes, matrices y herramental (I05)">Dados, troqueles, moldes, matrices y herramental (I05)</Option>
                                  <Option value="Honorarios médicos, dentales y gastos hospitalarios (D01)">Honorarios médicos, dentales y gastos hospitalarios (D01)</Option>
                                  <Option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</Option>
                                  <Option value="Adquisición de mercancias (G01)">Adquisición de mercancias (G01)</Option>
                                  <Option value="Gastos en general (G03)">Gastos en general (G03)</Option>
                                  <Option value="Construcciones (I01)">Construcciones (I01)</Option>
                                  <Option value="Mobiliario y equipo de oficina por inversiones (I02)">Mobiliario y equipo de oficina por inversiones (I02)</Option>
                                  <Option value="Equipo de transporte (I03)">Equipo de transporte (I03)</Option>
                                  <Option value="Equipo de computo y accesorios (I04)">Equipo de computo y accesorios (I04)</Option>
                                  <Option value="Comunicaciones telefónicas (I06)">Comunicaciones telefónicas (I06)</Option>
                                  <Option value="Comunicaciones satelitales (I07)">Comunicaciones satelitales (I07)</Option>
                                  <Option value="Otra maquinaria y equipo (I08)">Otra maquinaria y equipo (I08)</Option>
                                  <Option value="Gastos médicos por incapacidad o discapacidad (D02)">Gastos médicos por incapacidad o discapacidad (D02)</Option>
                                  <Option value="Gastos funerales (D03)">Gastos funerales (D03)</Option>
                                  <Option value="Donativos (D04)">Donativos (D04)</Option>
                                  <Option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación) (D05)">Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación) (D05)</Option>
                                  <Option value="Aportaciones voluntarias al SAR (D06)">Aportaciones voluntarias al SAR (D06)</Option>
                                  <Option value="Primas por seguros de gastos médicos (D07)">Primas por seguros de gastos médicos (D07)</Option>
                                  <Option value="Gastos de transportación escolar obligatoria (D08)">Gastos de transportación escolar obligatoria (D08)</Option>
                                  <Option value="Pagos por servicios educativos (colegiaturas) (D10)">Pagos por servicios educativos (colegiaturas) (D10)</Option>
                                  <Option value="Por definir (P01)">Por definir (P01)</Option>
                                </Select>
                                :
                                <Select>
                                  <Option value="ninguna" selected>Choose an option</Option>
                                  <Option value="Devoluciones, descuentos o bonificaciones (G02)">Returns, discounts or bonuses (G02)</Option>
                                  <Option value="Dados, troqueles, moldes, matrices y herramental (I05)">Dices, troqueles, molds, dies and tooling (I05)</Option>
                                  <Option value="Honorarios médicos, dentales y gastos hospitalarios (D01)">Medical, dental and hospital fees (D01)</Option>
                                  <Option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Deposits in savings accounts, premiums based on pension plans</Option>
                                  <Option value="Adquisición de mercancias (G01)">Acquisition of merchandise (G01)</Option>
                                  <Option value="Gastos en general (G03)">General expenses (G03)</Option>
                                  <Option value="Construcciones (I01)">Construcciones (I01)</Option>
                                  <Option value="Mobiliario y equipo de oficina por inversiones (I02)">Office furniture and equipment for investments (I02)</Option>
                                  <Option value="Equipo de transporte (I03)">Transport equipment (I03)</Option>
                                  <Option value="Equipo de computo y accesorios (I04)">Computer equipment and accessories (I04)</Option>
                                  <Option value="Comunicaciones telefónicas (I06)">Telephone communications (I06)</Option>
                                  <Option value="Comunicaciones satelitales (I07)">Satellite communications (I07)</Option>
                                  <Option value="Otra maquinaria y equipo (I08)">Other machinery and equipment (I08)</Option>
                                  <Option value="Gastos médicos por incapacidad o discapacidad (D02)">Medical expenses for disability or disability (D02)</Option>
                                  <Option value="Gastos funerales (D03)">Funeral expenses (D03)</Option>
                                  <Option value="Donativos (D04)">Donations (D04)</Option>
                                  <Option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación) (D05)">Real interest actually paid for mortgage loans (casa habitación) (D05)</Option>
                                  <Option value="Aportaciones voluntarias al SAR (D06)">Voluntary contributions to the SAR (D06)</Option>
                                  <Option value="Primas por seguros de gastos médicos (D07)">Premiums for health insurance (D07)</Option>
                                  <Option value="Gastos de transportación escolar obligatoria (D08)">Mandatory school transportation expenses (D08)</Option>
                                  <Option value="Pagos por servicios educativos (colegiaturas) (D10)">Payments for educational services (colegiaturas) (D10)</Option>
                                  <Option value="Por definir (P01)">To define (P01)</Option>
                                </Select>
                              }
                            </Form.Item>
                          </Col>

                        </>
                      }

                      <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item
                          name="aceptar_terminos"
                          valuePropName="checked"
                          rules={idioma === 'es' ?
                            [
                              {
                                validator: (_, value) =>
                                  value ? Promise.resolve() : Promise.reject(<span className="label_requerido_registro">Acepta el aviso de privacidad</span>),
                              },
                            ] :
                            [
                              {
                                validator: (_, value) =>
                                  value ? Promise.resolve() : Promise.reject(<span className="label_requerido_registro">Accept the privacy notice</span>),
                              },
                            ]}
                        >
                          <Checkbox>
                            {idioma === 'es' ?
                              <span className="texto_label_form">He leido y acepto el <strong><a style={{color:'#0055cd'}} target="_blank" href="https://www.sume.org.mx/about-6">aviso de privacidad.</a></strong></span>
                              :
                              <span className="texto_label_form">I have read and accept the <strong><a style={{color:'#0055cd'}} target="_blank" href="https://www.sume.org.mx/about-6">aotice of privacy.</a></strong></span>}

                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ display: 'non' }}>
                        <ReCAPTCHA ref={ref_recaptcha}
                          sitekey="6LfOy2InAAAAAOUGqZsPxwyFM6f_5IWMmcdpPc8-"
                        />
                      </Col>
                      <Col xs={24} md={0}>
                        <br />
                      </Col>

                      <Col span={24}>
                        <br /><br />
                      </Col>
                    </Row>
                    </Col>
                    <Col xs={{ span: 0, offset: 0 }} lg={{ span: 24, offset: 0 }} className="">

                        <Row justify="center" align="middle">
                          <Col span={16} offset={4} style={{ textAlign: 'left',position:'absolute',bottom:'5%',left:'10px',width:'100%' }}>
                          <p style={{color:'#000000'}}>{idioma === 'es' ? 'En caso de tener un código de cortesía, por favor ingresalo en el campo de abajo:' : 'If you have a courtesy code, please enter it in the field below:'}</p>
                          <input ref={ref_inputmd} className="inputs_codigo" placeholder={idioma === 'es' ? "Código de cortesía" : "Courtesy code"} name="descuento" data="descuento" />

<button className="btn_codigo" type="button" onClick={() => { buscarCodigo('grande') }} ><strong>{idioma === 'es' ? 'USAR' :' USE' }</strong></button>
                         
                        </Col>
                        </Row>
                        <br /><br />
                    </Col>
                    <Col span={24}>
                      
                    <br /><br />
                      <Row justify="center" align="middle" className="contenedor_totales-">
                        
                        <Col span={15}>
                          <p className="total_registro">{idioma === 'es' ? 'Total (Impuestos incluidos)' : 'Total (Tax included)'}: {formatter.format(ver_total - ((ver_total * porcentaje_codigo) / 100))}</p>
                        </Col>
                        <Col span={24}>
                          <Form.Item>

                            <p style={{ textAlign: 'center', display:'non' }}>
                              <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType="submit">
                              <p className="btn_registrate_home"><strong>{idioma === 'es' ? 'REGÍSTRATE' : 'SING UP'}</strong></p>
                              </button>
                             {/* <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20IES%20México.">
                                <button type="button" style={{ border: 'none', backgroundColor:'#ffffff', cursor:'pointer' }}>
                                    Tengo problemas con mi registro
                                </button>
                              </a>*/}
                            </p>

                          </Form.Item>

                        </Col>
                      </Row>
                    </Col>
                  
                
                </Row>

              </Form>
            </Col>
          </Row>
        }
      </Spin>
    </>

  );
};

export default withRouter(Registro);

import axios from 'axios';
//export const SERVER_URL = "https://ies.admin.thethree.com.mx";
export const SERVER_URL = "https://admin.encuentrosinergia.thethree.com.mx";
//export const SERVER_URL = "http://localhost:8000";

const fallo_token = {
    success: false,
    data:{
        status: 5
    }
}

const omitir_token = {
    success: true,
    data:{
        status: 10
    }
}

export const mail_registro_confirmacion = async (data) => {

        const ENDPOINT = `${SERVER_URL}/api/registro-futuro`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const registro_votacion = async (data) => {

    const ENDPOINT = `${SERVER_URL}/api/registro-votos`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const pago_registro = async (data) => {

        const ENDPOINT = `${SERVER_URL}/api/pago-futuro`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const confirmar_Asistencia_mesa_directiva = async (data) => {
    const ENDPOINT = `${SERVER_URL}/api/mesas-directivas`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const status_registro = async (campo) => {

        const ENDPOINT = `${SERVER_URL}/api/registro-futuro-status/${campo}`;
        try {
            let response = await axios.get(ENDPOINT, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}


export const revisar_status_registro = async (data) => {

    const ENDPOINT = `${SERVER_URL}/api/permitir-entrada`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const codigo = async (valor) => {

    const ENDPOINT = `${SERVER_URL}/api/codigo/${valor}`;
    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const paquete = async () => {

    const ENDPOINT = `${SERVER_URL}/api/paquetefuturo`;
    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const revisar_registro_datos = async (correo) => {

    const ENDPOINT = `${SERVER_URL}/acceso-congreso-2020/${correo}`;
    try {
        let response = await axios.get(ENDPOINT, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}


export const get_token_resgistro = async (data) => {
    const ENDPOINT = `${SERVER_URL}/webinar/buscar-usuario`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}

export const responder_encuesta = async (nombre_evento, token, folio, data) => {
    const ENDPOINT = `${SERVER_URL}/api/webinar/diploma/${nombre_evento}/${token}/${folio}`;
        try {
            let response = await axios.post(ENDPOINT,data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if(response.data.success){
                return response.data;
            }else{
                return fallo_token;
            }
            
        } catch(e){
            console.log(e);
            return fallo_token.data;
        }
}
export const networking = async () => {

    const ENDPOINT = `${SERVER_URL}/api/networking`;
    try {
        let response = await axios.get(ENDPOINT,{
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }

}

export const guardar_datos = async (data) => {

    const ENDPOINT = `${SERVER_URL}/api/profile/update`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const guardar_votos = async (data) => {

    const ENDPOINT = `${SERVER_URL}/api/voto/update`;
    try {
        let response = await axios.post(ENDPOINT,data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}